import React, { useContext, useEffect } from "react";
import CheckoutHeader from "../layouts/header/CheckoutHeader";
import { AuthUserContext } from "../context/AuthUserContext";
import CheckoutMain from "../components/checkout/CheckoutMain";
import { useNavigation } from "../utils/navigation";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const { fetchUserData, authTokens, userProfile, logoutUser } =
    useContext(AuthUserContext);
  const { goToLogin } = useNavigation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authTokens) {
      navigate(goToLogin());
      return;
    } else if (!userProfile) {
      fetchUserData();
    }
  }, []);
  return (
    <div>
      <CheckoutHeader />
      {userProfile && <CheckoutMain />}
    </div>
  );
};

export default Checkout;
