import React, { useContext, useEffect, useState } from "react";
import Spinner from "../animations/Spinner";
import APIService from "../../services/APIService";
import { AuthUserContext } from "../../context/AuthUserContext";
import styled from "styled-components";
import CreditCard from "../stripe/CreditCard";
import { useTranslation } from "react-i18next";

const SavedCards = () => {
  const { t } = useTranslation();
  const { authTokens } = useContext(AuthUserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [cards, setCards] = useState([]);
  useEffect(() => {
    const fetchSavedCards = async () => {
      try {
        const data = await APIService.getSavedPaymentMethods(authTokens.access);
        setCards(data);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSavedCards();
  }, [authTokens]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }
  return (
    <div>
      {cards.length === 0 ? (
        <WarningContainer>
          <WarningIcon>⚠️</WarningIcon> {t("STRIPE.NO_CARDS")}
        </WarningContainer>
      ) : (
        <CardList>
          {cards.map((card) => (
            <CreditCard card={card} setCards={setCards} key={card.id} />
          ))}
        </CardList>
      )}
    </div>
  );
};

export default SavedCards;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const CardList = styled.div`
  margin-top: 10px;
  @media (min-width: 1025px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    max-width: 810px;
    gap: 10px;
  }
`;
const WarningContainer = styled.div`
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
  padding: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 20px;
`;
const WarningIcon = styled.span`
  margin-right: 10px;
  font-size: 24px;
  font-weight: bold;
`;
