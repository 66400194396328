import styled from "styled-components";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 42px;
`;

const Wrapper = styled.div`
  margin: var(--spacing-xxl) 0;
  width: 100%;
  min-height: 60vh;

  background-image: url(${(props) => props.mobileImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  display: flex;
  align-items: flex-end;
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
  @media (min-width: 765px) {
    background-image: url(${(props) => props.desktopImage});
    min-height: 340px;
    border: 1px solid rgb(171, 201, 223);
    background-position: center;
    align-items: center;
  }
  border-radius: 12px;
  margin: 0 auto;
`;
const TextContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 40px;
  height: 90%;

  flex-direction: column;
  text-align: center;
  @media (min-width: 768px) and (max-width: 1040px) {
    height: 90%;
    align-items: center;
  }
  @media (min-width: 765px) {
    flex-direction: row;
    height: 100%;
    text-align: left;
    width: 50%;
  }
`;
const Title = styled.h2`
  font-size: var(--font-size-xlarge);
  font-weight: 400;
  margin: var(--spacing-lg) 0 var(--spacing-md) 0;
  text-transform: uppercase;
  max-width: 100%;
  @media (min-width: 765px) {
    font-size: var(--font-size-xxlarge);
  }
  color: black;
`;
const Text = styled.p`
  font-size: var(--font-size-base);
  margin-bottom: 14px;

  @media (min-width: 765px) {
    font-size: var(--font-size-base);
    max-width: 85%;
  }
`;
const Subtitle = styled.h4`
  font-weight: 700;
  font-size: var(--font-size-base);
  opacity: 0.5;
  color: black;
`;
const Button = styled.button`
  white-space: nowrap;
  margin: 0 auto;
  padding: 14px 20px;
  text-transform: uppercase;
  @media (min-width: 765px) {
    margin: 0;
  }
`;
const BannerHorizontal = ({
  desktopImage,
  mobileImage,
  subtitle,
  title,
  text,
  buttonText,
  onClickAction,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <Wrapper desktopImage={desktopImage} mobileImage={mobileImage}>
        <TextContainer>
          <div style={{ padding: "14px 0", width: "100%" }}>
            <Subtitle>{subtitle}</Subtitle>
            <Title>{title}</Title>
            <Text>{text}</Text>
            {buttonText && (
              <Button className="button-transparent" onClick={onClickAction}>
                {buttonText}
              </Button>
            )}
          </div>
        </TextContainer>
      </Wrapper>
    </Container>
  );
};

export default BannerHorizontal;
