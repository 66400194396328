import styled from "styled-components";
import { AuthUserContext } from "../../context/AuthUserContext";
import { useContext, useState } from "react";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  position: relative;
`;

const MainHeaderWrapper = styled.div`
  width: 100%;

  max-width: 100vw;
  box-shadow: var(--shadow-default);
  z-index: var(--zindex-top);
  position: fixed;
  top: ${(props) => (props.$isScrolled ? "0" : "initial")};
  left: 0;
  //
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--bg-200);

  box-shadow: ${(props) =>
    props.$isScrolled ? "var(--shadow-medium)" : "none"};

  transition: ${(props) =>
    props.$isScrolled ? "all 0.2s ease-out" : "all 0.1s ease-in"};

  @media (min-width: 768px) {
    //min-height: var(--navbar-height-desktop);
  }
  @media (min-width: 768px) and (max-width: 1040px) {
  }
`;
const GridContainer = styled.header`
  height: var(--navbar-height);
  width: 100%;
  max-width: 90%;

  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 768px) {
    height: var(--navbar-height-desktop);
    grid-template-columns: 1fr 1fr;
    max-width: var(--max-width-container);
    padding: 0;
  }
  @media (min-width: 768px) and (max-width: 1040px) {
    max-width: 90%;
  }
`;
const GridItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-md);

  &:nth-child(2) {
    justify-content: flex-end; /* Drugi element postaje prvi */
  }
`;

const LogoImg = styled.div`
  color: var(--primary-200);
  font-size: var(--font-size-xxlarge);
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.438px;
  display: flex;
  gap: var(--spacing-xs);

  align-items: center;
`;

const HeaderTitle = styled.h2`
  font-size: var(--font-size-large);
  @media (min-width: 768px) {
    font-size: var(--font-size-xxlarge);
  }
`;
const HeaderSubtitle = styled.h4`
  font-size: var(--font-size-base);
  @media (min-width: 768px) {
    font-size: var(--font-size-base);
  }
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-xs);
`;

const CheckoutHeader = () => {
  const { t } = useTranslation();
  const [logoHovered, setLogoHovered] = useState(false);
  const { authTokens } = useContext(AuthUserContext);
  const { goToHome } = useNavigation();
  const navigate = useNavigate();
  return (
    <Container>
      <MainHeaderWrapper>
        <GridContainer>
          <GridItem
            style={{ cursor: "pointer" }}
            onClick={() => navigate(goToHome())}
          >
            <LogoImg>
              <svg
                width="50"
                height="50"
                viewBox="0 0 298 298"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onMouseEnter={() => setLogoHovered(true)}
                onMouseLeave={() => setLogoHovered(false)}
              >
                <path
                  d="M148.707 0C66.7245 0 0 66.7245 0 148.707C0 230.689 66.7245 297.414 148.707 297.414C230.689 297.414 297.414 230.689 297.414 148.707C297.414 66.7245 230.689 0 148.707 0ZM294.707 148.707C294.707 188.275 278.843 224.204 253.18 250.567L244.688 242.074C268.158 217.904 282.669 184.962 282.669 148.707C282.669 112.452 267.878 78.8562 243.988 54.6394L252.48 46.1472C278.563 72.557 294.661 108.766 294.661 148.707H294.707ZM17.3576 148.707C17.3576 76.2899 76.2432 17.4043 148.66 17.4043C221.077 17.4043 279.963 76.2899 279.963 148.707C279.963 221.124 221.077 280.009 148.66 280.009C76.2432 280.009 17.3576 221.124 17.3576 148.707ZM250.52 44.2808L242.028 52.7731C217.858 29.2562 184.915 14.7447 148.614 14.7447C112.312 14.7447 79.416 29.2562 55.2458 52.7731L46.7536 44.2808C73.0701 18.6176 108.999 2.75295 148.567 2.75295C188.135 2.75295 224.11 18.6175 250.427 44.3275L250.52 44.2808ZM44.7939 46.1939L53.2862 54.6862C29.3494 78.9029 14.558 112.172 14.558 148.8C14.558 185.429 29.0693 218.044 52.5862 242.214L44.1406 250.707C18.4307 224.39 2.56627 188.415 2.56627 148.847C2.56627 109.279 18.7107 72.6504 44.7939 46.2872V46.1939ZM46.0539 252.667L54.5461 244.174C78.7629 268.064 111.985 282.856 148.614 282.856C185.242 282.856 218.511 268.064 242.728 244.128L251.22 252.62C224.857 278.703 188.602 294.848 148.66 294.848C108.719 294.848 72.5102 278.703 46.147 252.667"
                  fill="var(--text-200)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M207.722 57.7351C202.622 66.9627 200.922 78.8759 205.416 87.3302C214.54 104.491 230.158 114.314 239.894 131.851C273.15 191.781 209.011 201.72 174.784 209.915C155.428 214.537 143.812 230.506 158.492 258.688C214.436 253.98 258.39 207.07 258.39 149.903C258.39 111.144 238.177 77.1018 207.722 57.7266"
                  fill={
                    !logoHovered ? "var(--primary-200)" : "var(--primary-100)"
                  }
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M191.927 105.506C177.373 95.2564 162.525 82.7355 160.972 63.8935C160.307 55.8558 164.875 48.4915 171.632 43.0459C157.459 40.0907 142.84 39.9776 128.623 42.713C114.406 45.4483 100.873 50.9779 88.8086 58.9812C96.9207 91.2874 127.561 94.6763 155.629 103.143C195.722 115.235 214.322 153.656 158.871 159.818C81.4401 168.423 72.2954 187.545 127.861 256.987C133.447 258.095 139.112 258.763 144.803 258.984C114.222 212.039 131.18 198.9 188.95 180.965C245.166 163.485 224.646 128.565 191.925 105.51"
                  fill="var(--accent-100)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M123.936 118.509C100.162 114.213 67.312 112.348 58.2119 97.5595C57.3549 96.166 56.5939 94.7157 55.9342 93.2188C45.5264 110.296 40.0342 129.915 40.0626 149.915C40.0626 197.952 71.1131 238.761 114.236 253.341C95.0693 233.567 63.4812 210.21 71.1047 172.043C76.204 146.547 105.038 150.269 127.675 148.404C160.298 145.722 156.82 124.463 123.927 118.509"
                  fill={
                    !logoHovered ? "var(--primary-200)" : "var(--primary-100)"
                  }
                />
              </svg>
            </LogoImg>
            <HeaderTitle>SnusCo</HeaderTitle>
          </GridItem>
          <GridItem
            style={{
              cursor: "text",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "0",
            }}
          >
            <FlexDiv>
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.581 19.4041C14.6645 19.9894 14.2606 20.5326 13.676 20.6211L12 20.9841C11.6307 21.1187 11.2169 21.0265 10.9398 20.7477C10.6627 20.469 10.5729 20.0546 10.7097 19.6861C10.8466 19.3176 11.1851 19.0623 11.577 19.0321L13.252 18.6701C13.8213 18.509 14.4142 18.8364 14.581 19.4041V19.4041Z"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 15.9919C5.89543 15.9919 5 15.0965 5 13.9919V10.9919C5 9.88737 5.89543 8.99194 7 8.99194C8.10457 8.99194 9 9.88737 9 10.9919V13.9919C9 15.0965 8.10457 15.9919 7 15.9919Z"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17 15.9919C15.8954 15.9919 15 15.0965 15 13.9919V10.9919C15 9.88737 15.8954 8.99194 17 8.99194C18.1046 8.99194 19 9.88737 19 10.9919V13.9919C19 15.0965 18.1046 15.9919 17 15.9919Z"
                  stroke="#000000"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.25 11C18.25 11.4142 18.5858 11.75 19 11.75C19.4142 11.75 19.75 11.4142 19.75 11H18.25ZM4.25 11C4.25 11.4142 4.58579 11.75 5 11.75C5.41421 11.75 5.75 11.4142 5.75 11H4.25ZM19.75 14.0023C19.7513 13.5881 19.4165 13.2513 19.0023 13.25C18.5881 13.2487 18.2513 13.5835 18.25 13.9977L19.75 14.0023ZM13.5113 19.8951C13.1071 19.9855 12.8527 20.3865 12.9431 20.7907C13.0335 21.1949 13.4345 21.4493 13.8387 21.3589L13.5113 19.8951ZM19.75 11V9H18.25V11H19.75ZM19.75 9C19.75 4.71979 16.2802 1.25 12 1.25V2.75C15.4518 2.75 18.25 5.54822 18.25 9H19.75ZM12 1.25C7.71979 1.25 4.25 4.71979 4.25 9H5.75C5.75 5.54822 8.54822 2.75 12 2.75V1.25ZM4.25 9V11H5.75V9H4.25ZM18.25 13.9977C18.2414 16.8289 16.2742 19.2771 13.5113 19.8951L13.8387 21.3589C17.2853 20.588 19.7393 17.534 19.75 14.0023L18.25 13.9977Z"
                  fill="#000000"
                />
              </svg>
              <HeaderSubtitle> +43 667 7628134</HeaderSubtitle>
            </FlexDiv>
            <p style={{ fontSize: "12px" }}>10AM-6PM EDT (Mon-Fri) </p>
          </GridItem>
        </GridContainer>
      </MainHeaderWrapper>
    </Container>
  );
};

export default CheckoutHeader;
