import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ProductContext } from "../../context/ProductContext";
import { PRODUCT_CHUNK } from "../../utils/global_const";
import { useTranslation } from "react-i18next";
import ProductCard from "../../components/product/ProductCard";
import Filter from "../../components/product/Filter";
import ProductCardHorizontal from "../../components/product/ProductCardHorizontal";
import ShopSort from "../../components/product/ShopSort";
import CategoryHero from "../../components/hero/CategoryHero";
import Breadcrumbs from "../../components/section/BreadCrumbs";

const Container = styled.article`
  color: ${(props) => props.theme.textColor};
`;
const Section = styled.section`
  width: 100%;
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
`;
const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }

  @media (min-width: 1025px) {
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
  }
`;
const FlexDiv = styled.div`
  height: var(--navbar-height);
  //position: sticky;
  //left: 0;
  //top: calc(var(--navbar-height) + var(--navbar-mini));
  //flex-direction: row-reverse;

  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-large);

  @media (min-width: 768px) {
    //max-width: 90%;
    box-shadow: none;
    flex-direction: row;
    position: static;
    margin-bottom: 20px;
  }
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
`;
const MarginDiv = styled.div`
  width: 96%;
  margin: 0 auto;
`;
const TopWrapper = styled.div`
  width: 96%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  @media (min-width: 768px) {
    width: 20%;
    margin-left: auto;
  }
`;
const FlexContainer = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const FilterContainer = styled.div`
  width: 100%;
`;

const PaginationContainer = styled(FlexContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  @media (min-width: 768px) {
    justify-content: flex-end;
  }
`;

const PageButton = styled.button`
  padding: 8px 12px;
  border: 1px solid ${({ isActive }) => (isActive ? "var(--primary)" : "#ccc")};
  background-color: ${({ isActive }) =>
    isActive ? "var(--primary-100)" : "var(--bg-100)"};
  color: ${({ isActive }) => (isActive ? "#fff" : "#333")};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? "var(--primary-200)" : "var(--bg-200)"};
  }
`;

const NavButton = styled.button`
  padding: 8px 16px;
  border: none;
  background-color: var(--bg-200);
  border: 1px solid var(--bg-300);
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  &:hover {
    background-color: var(--primary-200);
  }
`;
const ShopMain = () => {
  const { t } = useTranslation();
  const { filteredProducts, products, category, setFilteredProducts } =
    useContext(ProductContext);

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(filteredProducts.length / PRODUCT_CHUNK);

  // Funkcija za dobijanje proizvoda za trenutnu stranicu
  const currentProducts = filteredProducts.slice(
    (currentPage - 1) * PRODUCT_CHUNK,
    currentPage * PRODUCT_CHUNK
  );

  const breadcrumbItems = [
    { name: "SnusCo", url: "/" },
    { name: "Nicotine Pouches", url: "/snus-verkauf" },
  ];

  // Resetovanje na prvu stranicu kada se promeni filtrirana lista proizvoda
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredProducts]);

  // Funkcije za navigaciju između stranica
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
    window.scrollTo({
      top: 80,
      behavior: "smooth",
    });
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
    window.scrollTo({
      top: 80,
      behavior: "smooth",
    });
  };

  const renderPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PageButton
          key={i}
          onClick={() => {
            setCurrentPage(i);
            window.scrollTo({
              top: 80,
              behavior: "smooth",
            });
          }}
          isActive={i === currentPage}
        >
          {i}
        </PageButton>
      );
    }
    return pages;
  };

  useEffect(() => {
    if (products && products.length) {
      setFilteredProducts(products);
    }
  }, [products]);

  return (
    <Container>
      {category && <CategoryHero />}
      <Section className={!category && "header-top-margin"}>
        <MarginDiv>
          <Breadcrumbs breadcrumbs={breadcrumbItems} />
          <p>{t("DESCRIPTION.SHOP.TEXT")}</p>
        </MarginDiv>
        <FlexDiv>
          <TopWrapper>
            <FilterContainer>
              <Filter />
            </FilterContainer>
            <FilterContainer>
              <ShopSort />
            </FilterContainer>
          </TopWrapper>
        </FlexDiv>
        <ProductGrid>
          {currentProducts.map((product, index) => (
            <ProductCard key={index} product={product} />
          ))}
        </ProductGrid>
        {totalPages !== 1 && (
          <PaginationContainer>
            <NavButton onClick={handlePrevPage} disabled={currentPage === 1}>
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z"
                  fill="var(--text-100)"
                />
              </svg>
            </NavButton>
            {renderPageNumbers()}
            <NavButton
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                  fill="var(--text-100)"
                />
              </svg>
            </NavButton>
          </PaginationContainer>
        )}
      </Section>
    </Container>
  );
};

export default ShopMain;
