import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ProductContext } from "../../../context/ProductContext";
import { useNavigation } from "../../../utils/navigation";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: none;
  background-color: var(--primary-100);
  width: 100%;
  max-width: 100vw;
  height: var(--navbar-mini);

  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    display: flex;
  }
`;
const Wrapper = styled.header`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    max-width: var(--max-width-container);
    padding: 0;
    display: grid; /* Prikazuje sve elemente odjednom na širim ekranima */
    grid-template-columns: repeat(5, 1fr);
    gap: 2px;
  }
`;
const GridItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: var(--bg-100);
  background-color: var(--primary-200);
  height: 100%;
  position: relative;
  cursor: pointer;
`;

const SubHeader = styled.div`
  position: absolute;
  background-color: var(--primary-200);
  color: var(--bg-100);

  top: 100%;
  left: 0;
  border-radius: 0 0 20px 20px;
  box-shadow: var(--shadow-large);
  padding: 12px;
  z-index: 1;

  overflow-y: auto;
  max-height: 70vh;
  min-width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const SubHeaderItem = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  min-width: 120px;
  font-weight: 100;
  font-size: var(--font-size-base);
  &:hover {
    background-color: var(--primary-100);
  }
`;
const DesktopHeader = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { categories } = useContext(ProductContext);
  const { goToCategory } = useNavigation();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const sliderItems = [
    {
      id: 1,
      text: t("HEADER.LINKS.NEW_YEAR_NEW_ME"),
      url: `/${i18n.language}/featured`,
    },
    {
      id: 2,
      text: t("HEADER.LINKS.NICOTINE_POUCHES"),
      url: `/${i18n.language}/nicotine-pouches`,
    },
    {
      id: 3,
      text: t("HEADER.LINKS.BEST_SELLERS"),
      url: `/${i18n.language}/best-seller`,
    },
    {
      id: 4,
      text: t("HEADER.LINKS.NEW_ARRIVALS"),
      url: `/${i18n.language}/new-arrivals`,
    },
    { id: 5, text: t("HEADER.LINKS.BLOG"), url: `/` },
  ];

  // Provera da li je ekran mobilni ili desktop
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container>
      <Wrapper>
        {sliderItems.map((item) => (
          <GridItem
            key={item.id}
            onMouseEnter={() => item.id === 2 && setIsVisible(true)}
            onMouseLeave={() => item.id === 2 && setIsVisible(false)}
            onClick={() => navigate(item.url)}
          >
            {item.text}
            {item.id === 2 && (
              <svg
                fill="var(--bg-100)"
                width="20px"
                height="20px"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M759.2 419.8L697.4 358 512 543.4 326.6 358l-61.8 61.8L512 667z" />
              </svg>
            )}
            {item.id === 2 && isVisible && (
              <SubHeader
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
              >
                {categories.map((category, index) => (
                  <SubHeaderItem
                    key={index}
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate(goToCategory(category.name));
                    }}
                  >
                    {category.name}
                  </SubHeaderItem>
                ))}
              </SubHeader>
            )}
          </GridItem>
        ))}
      </Wrapper>
    </Container>
  );
};

export default DesktopHeader;
