import styled from "styled-components";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { ProductContext } from "../../context/ProductContext";
import AddToCartAnim from "../animations/AddToCartAnim";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import { calculatePrice } from "../../utils/discount";
import { useTranslation } from "react-i18next";

const ContainerX = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  box-shadow: var(--shadow-medium);
  background-color: var(--bg-200);
  padding: 0;
  margin: 0;
  padding: var(--spacing-md) 0;
  border-radius: 12px;
`;
const Container = styled.div`
  position: relative;
  width: 96%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: var(--spacing-sm);
`;
const BottomContainer = styled.div`
  width: 96%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const Picture = styled.picture`
  display: flex; // Omogućavanje flex layout-a za centriranje slike
  align-items: center;
  justify-content: center;
  //height: 240px;
  cursor: pointer;
  //width: 100%;
  height: 100%;
  max-height: 140px;
  min-height: 140px;
  position: relative;

  &:hover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .primary-image {
      display: none;
      display: ${(props) => (props.$secondary_image ? "none" : "block")};
    }

    .secondary-image {
      display: block;
      display: ${(props) => (props.$secondary_image ? "block" : "none")};
    }
  }
  @media (min-width: 768px) {
    max-height: 160px;
    min-height: 160px;
  }
`;

const Image = styled.img`
  height: 100%;
  max-height: 100%;
  //max-width: 200px;
  width: 200px;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  &.secondary-image {
    display: none;
  }
  @media (min-width: 768px) {
    width: 200px;
  }
`;
const Bottom = styled.div`
  width: 90%;
`;

const ProductTitle = styled.h3`
  //margin: 12px 0;
  color: var(--text-100);
  //height: calc(var(--font-size-large) * 2);
  cursor: pointer;
  &:hover {
    color: var(--primary-200);
  }
`;

const ProductFlavor = styled.h5`
  border: 3px solid var(--bg-100);
  border-radius: 20px;
  padding: 2px 12px;
  display: inline-block;
  margin: 12px 0 0 0;
  font-size: var(--font-size-small);
`;

const GridQuantity = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;
const GridQuantityItem = styled.div`
  font-family: "Gudea-Regural";
  font-size: var(--font-size-base);
  //padding: 8px;

  color: ${({ $isActive }) =>
    $isActive ? "var(--accent-100)" : "var(--text-200)"};
  border: 1px solid;
  border-color: ${({ $isActive }) =>
    $isActive ? "var(--accent-100)" : "var(--text-200)"};
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.5;
  }
  box-shadow: var(--shadow-large);
  background-color: var(--bg-100);

  border-radius: 8px;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 12px;
`;
const ButtonWrapper = styled.div`
  position: relative; /* Roditeljski kontejner koji omogućava apsolutno pozicioniranje za decu */
  display: inline-block; /* Omogućava kontejneru da se prilagodi veličini sadržaja */
  width: 100%;
  text-align: center;
  margin-top: 14px;
  border-radius: 8px;
`;
const Button = styled.button`
  width: 100%;
  text-transform: capitalize;
  z-index: 1;
`;

const ProductCardHorizontal = ({ product }) => {
  const { t } = useTranslation();
  const [selectedQuantity, setSelectedQuantity] = useState(10);
  const dispatch = useDispatch();
  const { setIsCartOpen, maxAttributes, currencyTag } =
    useContext(ProductContext);
  const { goToProduct } = useNavigation();
  const navigate = useNavigate();

  const [isAnimating, setIsAnimating] = useState(false);

  const addToCart = (selectedQuantity) => {
    dispatch(
      cartActions.addToCart({
        product: product,
        quantity: selectedQuantity,
      })
    );
    setIsCartOpen(true);
    setIsAnimating(true);
  };

  const handleAnimationComplete = () => {
    setIsAnimating(false);
  };

  const discountedPrice = calculatePrice(product.price, selectedQuantity);

  // Calculate savings
  const savingsPercentage = Math.round(
    ((product.price - discountedPrice) / product.price) * 100
  );

  const primaryImage = product.images.find((img) => img.is_primary);
  const secondaryImage = product.images.find((img) => !img.is_primary);

  return (
    <ContainerX>
      <Container>
        <Picture
          onClick={() =>
            navigate(goToProduct(product.category_name, product.name))
          }
          $secondary_image={secondaryImage}
        >
          {primaryImage && (
            <Image
              src={primaryImage.thumbnail}
              alt={`${product.category_name} ${product.name}`}
              srcSet={`${primaryImage.thumbnail} 320w, ${primaryImage.large} 480w, ${primaryImage.large} 800w`}
              sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
              loading="lazy"
              className="primary-image"
            />
          )}
          {secondaryImage && (
            <Image
              src={secondaryImage.thumbnail}
              alt={`${product.category_name} ${product.name}`}
              srcSet={`${secondaryImage.thumbnail} 320w, ${secondaryImage.large} 480w, ${secondaryImage.large} 800w`}
              sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
              loading="lazy"
              className="secondary-image"
            />
          )}
        </Picture>
        <Bottom>
          <ProductTitle
            onClick={() =>
              navigate(goToProduct(product.category_name, product.name))
            }
          >
            {product.category_name} {product.name}
          </ProductTitle>
          <FlexDiv>
            <h4>{product.nicotine}MG</h4>
            <ProductFlavor>{product.flavor}</ProductFlavor>
          </FlexDiv>
          <h5 style={{ textTransform: "lowercase", marginTop: "14px" }}>
            {product.pouches_per_can}x {product.format}{" "}
            {t("PRODUCT_CARD.POUCHES_PER_CAN")}
          </h5>
        </Bottom>
      </Container>
      <BottomContainer>
        <FlexDiv style={{ justifyContent: "space-between", width: "100%" }}>
          <div>
            <h4 style={{ margin: "14px 0 4px  0" }}>
              {t("PRODUCT_CARD.CHOOSE_QUANTITY")}
            </h4>
            <GridQuantity>
              {[1, 10, 30, 50].map((item, index) => (
                <GridQuantityItem
                  key={index}
                  $isActive={selectedQuantity === item}
                  onClick={() => setSelectedQuantity(item)}
                >
                  {item}x
                </GridQuantityItem>
              ))}
            </GridQuantity>
          </div>
          <div>
            <h2 style={{ marginTop: "4px" }}>
              {currencyTag}
              {(product.price * selectedQuantity).toFixed(2)}
            </h2>
            <FlexDiv>
              <h4>
                {currencyTag}
                {discountedPrice.toFixed(2)}/{t("PRODUCT_CARD.PIECE")}
              </h4>
              {savingsPercentage > 0 && (
                <h4 style={{ color: "var(--accent-100)" }}>
                  -{savingsPercentage}%
                </h4>
              )}
            </FlexDiv>
          </div>
        </FlexDiv>
        <ButtonWrapper>
          <Button
            onClick={() => addToCart(selectedQuantity)}
            disabled={!product.is_in_stock}
          >
            {product.is_in_stock
              ? t("BUTTONS.ADD_TO_CART")
              : t("BUTTONS.AVAILABLE_SOON") + "!"}
          </Button>

          {isAnimating && (
            <AddToCartAnim
              isAnimating={isAnimating}
              onComplete={handleAnimationComplete}
            />
          )}
        </ButtonWrapper>
      </BottomContainer>
    </ContainerX>
  );
};

export default ProductCardHorizontal;
