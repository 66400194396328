import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--bg-200);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
const WrapperSVG = styled.div`
  background-color: var(--bg-200);
  width: 70px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SVG = styled.svg`
  padding: 8px;
  background-color: var(--bg-200);
  border-radius: 50%;
  border: 6px solid var(--bg-200);
`;
const Text = styled.div`
  padding: 0 12px;
  font-size: var(--font-size-small);
  font-weight: 500;
  color: var(--text-200);
  text-align: center;
`;

const Success = ({ text }) => {
  return (
    <Container>
      <WrapperSVG>
        <SVG
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="check-circle" clipPath="url(#clip0_525_53)">
            <path
              id="Icon"
              d="M18.3332 9.23355V10.0002C18.3321 11.7972 17.7503 13.5458 16.6743 14.9851C15.5983 16.4244 14.0859 17.4773 12.3626 17.9868C10.6394 18.4963 8.79755 18.4351 7.1119 17.8124C5.42624 17.1896 3.98705 16.0386 3.00897 14.5311C2.03089 13.0236 1.56633 11.2403 1.68457 9.44714C1.80281 7.65402 2.49751 5.94715 3.66507 4.58111C4.83263 3.21506 6.41049 2.26303 8.16333 1.867C9.91617 1.47097 11.7501 1.65216 13.3915 2.38355M18.3332 3.33355L9.99984 11.6752L7.49984 9.17521"
              stroke="var(--success-color)"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_525_53">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </SVG>
      </WrapperSVG>
      <div>
        <Text>{text}</Text>
      </div>
    </Container>
  );
};

export default Success;
