import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Footer from "../../layouts/footer/Footer";
import ShopMain from "../../layouts/main/ShopMain";
import PRODUCT_CHUNK, { DEFAULT_LANGUAGE } from "../../utils/global_const";
import { useParams } from "react-router-dom";
import { ProductContext } from "../../context/ProductContext";
import Header from "../../layouts/header/Header";
import BestSellerMain from "../../layouts/main/product/BestSellerMain";

const SEOConfig = {
  en: {
    title: "Best Seller Nicotine Pouches – Top-Rated Flavors | SnusCo",
    description:
      "Explore our best-selling nicotine pouches with top-rated flavors and varying nicotine strengths. Discover what others love and enjoy fast delivery with SnusCo!",
    keywords:
      "best seller nicotine pouches, top nicotine pouches, best nicotine products, best snus, nicotine pouches shop, SnusCo, top rated nicotine pouches, Austria, Germany",
    ogTitle: "Best Seller Nicotine Pouches – Top-Rated Flavors | SnusCo",
    ogDescription:
      "Discover our best-selling nicotine pouches with popular flavors and strengths. Fast delivery in Austria, Germany, and beyond with SnusCo!",
    twitterTitle: "Best Seller Nicotine Pouches – Top-Rated Flavors | SnusCo",
    twitterDescription:
      "Shop the top-rated nicotine pouches at SnusCo. Discover our best sellers and enjoy fast delivery in Austria and Germany.",
    url: "https://www.snusco.com/en/best-seller",
    image: "https://www.snusco.com/assets/best-seller.jpg",
    locale: "en",
  },
  de: {
    title: "Best Seller Nikotinsäcke – Top-Bewertete Aromen | SnusCo",
    description:
      "Entdecken Sie unsere meistverkauften Nikotinsäcke mit hoch bewerteten Aromen und verschiedenen Nikotinstärken. Finden Sie heraus, was andere lieben, und genießen Sie schnelle Lieferung bei SnusCo!",
    keywords:
      "Best Seller Nikotinsäcke, Top Nikotinsäcke, beste Nikotinprodukte, bester Snus, Nikotinsäcke Shop, SnusCo, meistverkaufte Nikotinsäcke, Österreich, Deutschland",
    ogTitle: "Best Seller Nikotinsäcke – Top-Bewertete Aromen | SnusCo",
    ogDescription:
      "Entdecken Sie unsere meistverkauften Nikotinsäcke mit beliebten Aromen und Stärken. Schnelle Lieferung nach Österreich, Deutschland und weltweit mit SnusCo!",
    twitterTitle: "Best Seller Nikotinsäcke – Top-Bewertete Aromen | SnusCo",
    twitterDescription:
      "Shoppen Sie die meistverkauften Nikotinsäcke bei SnusCo. Entdecken Sie unsere Best Seller und genießen Sie schnelle Lieferung in Österreich und Deutschland.",
    url: "https://www.snusco.com/de/best-seller",
    image: "https://www.snusco.com/assets/best-seller.jpg",
    locale: "de",
  },
};

const BestSeller = () => {
  const { t, i18n } = useTranslation();
  const { loadBestSellersBackend, products } = useContext(ProductContext);

  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    if (!initialLoad) {
      // Proverava da li je ovo prvi put da se učitava
      loadBestSellersBackend();
      setInitialLoad(true); // Postavlja da je inicijalno učitavanje završeno
    }
  }, [initialLoad]);

  const [seo, setSeo] = useState(SEOConfig[DEFAULT_LANGUAGE]);

  useEffect(() => {
    setSeo(SEOConfig[i18n.language] || SEOConfig[DEFAULT_LANGUAGE]);
  }, [i18n.language]);

  return (
    <div>
      <Helmet>
        <html lang={i18n.language} />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />

        {/* Open Graph slike - Primarna slika na prvom mestu */}
        {seo.images?.map((image, index) => (
          <meta key={index} property="og:image" content={image} />
        ))}

        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={seo.url} />
      </Helmet>
      <Header />
      <BestSellerMain />
      <Footer />
    </div>
  );
};

export default BestSeller;
