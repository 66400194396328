import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.div`
  background-color: var(--accent-100);
  width: 100%;
  max-width: 100vw;
  height: var(--navbar-mini);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.header`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    max-width: var(--max-width-container);
    padding: 0;
    display: grid; /* Prikazuje sve elemente odjednom na širim ekranima */
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
  }
`;
const GridItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bg-100);
`;

const HeaderSlider = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const sliderItems = [
    { id: 1, text: t("HEADER_SLIDER.DELIVERY_TO_AUSTRIA") },
    { id: 2, text: t("HEADER_SLIDER.BIG_SELECTION") },
    { id: 3, text: t("HEADER_SLIDER.BEST_CUSTOMER_SERVICE") },
  ];

  // Provera da li je ekran mobilni ili desktop
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Menja slajd svakih 2 sekunde samo na mobilnom
  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === sliderItems.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000);
      return () => clearInterval(interval); // Očisti interval na unmount
    }
  }, [isMobile]);

  return (
    <Container>
      <Wrapper>
        {isMobile ? (
          <GridItem>
            <svg
              fill="var(--bg-100)"
              width="30px"
              height="30px"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M760 380.4l-61.6-61.6-263.2 263.1-109.6-109.5L264 534l171.2 171.2L760 380.4z" />
            </svg>
            {sliderItems[currentIndex].text}
          </GridItem>
        ) : (
          sliderItems.map((item) => (
            <GridItem key={item.id}>
              <svg
                fill="var(--bg-100)"
                width="30px"
                height="30px"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M760 380.4l-61.6-61.6-263.2 263.1-109.6-109.5L264 534l171.2 171.2L760 380.4z" />
              </svg>
              {item.text}
            </GridItem>
          ))
        )}
      </Wrapper>
    </Container>
  );
};

export default HeaderSlider;
