import { useContext, useEffect, useState } from "react";
import { AuthUserContext } from "../../../context/AuthUserContext";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Title = styled.h2``;
const OrderHistory = () => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState({});
  const { userProfile, fetchUserData } = useContext(AuthUserContext);
  useEffect(() => {
    if (!userProfile) {
      fetchUserData();
      return;
    }
  }, [userProfile]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    // Dodajte +1 jer getMonth() vraća mesec od 0 do 11
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // Koristite `padStart(2, '0')` da biste osigurali da su dan i mesec uvek prikazani sa dve cifre
    return `${day.toString().padStart(2, "0")}.${month
      .toString()
      .padStart(2, "0")}.${year}`;
  };
  const toggleShowMore = (orderId) => {
    setShowMore((prev) => ({ ...prev, [orderId]: !prev[orderId] }));
  };
  return (
    <div>
      <Title>{t("ORDER_HISTORY.TITLE")}</Title>
      <div>{t("ORDER_HISTORY.SOON")}</div>
    </div>
  );
};

export default OrderHistory;
