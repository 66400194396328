import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { cartActions } from "../../../store/cart-slice";
import { ProductContext } from "../../../context/ProductContext";

const Container = styled.figure`
  min-height: 80px;
  position: relative;
  background-color: var(--bg-200);
  width: 96%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: var(--spacing-sm);
  box-shadow: var(--shadow-medium);
  margin-bottom: 8px;
  border-radius: 8px;
`;
const Picture = styled.picture`
  display: flex; // Omogućavanje flex layout-a za centriranje slike
  align-items: center;
  justify-content: center;
  //height: 240px;
  cursor: pointer;
  width: 140px;
  max-height: 80px;
  min-height: 80px;
  position: relative;
`;

const Image = styled.img`
  height: 100%;
  max-height: 100%;
  //max-width: 200px;
  width: 100px;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  &.secondary-image {
    display: none;
  }
`;

const Right = styled.div`
  display: grid;
  grid-template-columns: repeat(1fr);
  gap: 8px;
  width: 100%;
  padding-top: 4px;
`;

const AddContainer = styled.div`
  background-color: var(--background-color-hover-alt);
  max-width: 130px;
  border-radius: var(--border-radius-large);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xxs);
`;
const Button = styled.button`
  background-color: var(--bg-100);
  border: none;
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius-large);
  color: var(--text-100);
  &:hover {
    background-color: var(--bg-200);
  }
`;
const Input = styled.input`
  width: calc(var(--font-size-small) * 3 - var(--spacing-sm)); // Širina inputa
  height: 30px; // Visina inputa
  padding: 0 var(--spacing-sm);
  margin: 0 var(--spacing-sm);
  background-color: var(--bg-100); // Boja pozadine
  text-align: center; // Centriranje teksta
  font-size: var(--font-size-small);
  border-radius: var(--border-radius-small);
  font-weight: 400;
  // Uklanjanje strelica za input tipa number
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Za Firefox
  &[type="number"] {
    -moz-appearance: textfield;
  }
  border-radius: 8px;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const CartProduct = ({ item }) => {
  const { currencyTag } = useContext(ProductContext);
  const [inputValue, setInputValue] = useState(item.quantity);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const [newValue, setNewValue] = useState("");
  const dispatch = useDispatch();

  const deleteCartItem = (id) => {
    dispatch(cartActions.removeFromCart(id));
  };

  const handleAddOne = () => {
    const newQuantity = inputValue + 1;

    dispatch(
      cartActions.updateCart({
        product: item.product,
        quantity: newQuantity,
      })
    );
  };
  const handleRemoveOne = () => {
    const newQuantity = inputValue - 1;
    if (newQuantity === 0) {
      deleteCartItem(item.product.id);
    }
    dispatch(
      cartActions.updateCart({
        product: item.product,
        quantity: newQuantity,
      })
    );
  };
  useEffect(() => {
    setInputValue(item.quantity);
  }, [item.quantity]);

  useEffect(() => {
    setInputValue(item.quantity);
  }, [item.quantity]);

  const handleChange = (event) => {
    const value = Math.max(1, Math.min(999, Number(event.target.value)));
    setInputValue(value);
    dispatch(
      cartActions.updateCart({
        product: item.product,
        quantity: value,
      })
    );
  };
  // Try to find a non-primary image; if none exist, use the primary image as fallback
  const primaryImage =
    item.product.images.find((img) => !img.is_primary) ||
    item.product.images.find((img) => img.is_primary);

  return (
    <Container>
      <Picture>
        {primaryImage && (
          <Image
            src={primaryImage.thumbnail}
            alt={`${item.product.category_name} ${item.product.name}`}
            srcSet={`${primaryImage.thumbnail} 320w, ${primaryImage.large} 480w, ${primaryImage.large} 800w`}
            sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
            loading="lazy"
            className="primary-image"
          />
        )}
      </Picture>
      <Right>
        <FlexDiv style={{ justifyContent: "space-between" }}>
          <h4>
            {item.product.category_name} {item.product.name}{" "}
            {item.product.nicotine}MG
          </h4>
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              deleteCartItem(item.product.id);
            }}
            style={{ cursor: "pointer" }}
          >
            <path
              fill="var(--text-200)"
              d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
            />
          </svg>
        </FlexDiv>
        <FlexDiv>
          <AddContainer>
            <Button onClick={() => handleRemoveOne()}>
              <svg
                viewBox="0 0 160 161"
                fill="none"
                className="svg-icon"
                style={{ width: "24px", height: "24px" }}
              >
                <path
                  d="M79.516 159.561c-43.324 0-78.57-35.467-78.57-79.061 0-43.594 35.246-79.061 78.57-79.061 43.323 0 78.57 35.467 78.57 79.061 0 43.594-35.247 79.061-78.57 79.061zm0-148.661c-38.14 0-69.168 31.222-69.168 69.6 0 38.378 31.028 69.6 69.168 69.6 38.14 0 69.168-31.222 69.168-69.6 0-38.378-31.028-69.6-69.168-69.6z"
                  fill="none"
                ></path>
                <path
                  d="M108.69 74.379H49.407c-3.359 0-6.083 2.74-6.083 6.12 0 3.382 2.724 6.122 6.084 6.122h59.282c3.36 0 6.084-2.74 6.084-6.121 0-3.38-2.724-6.121-6.084-6.121z"
                  fill="var(--text-200)"
                ></path>
              </svg>
            </Button>
            <div>
              <Input
                type="number"
                max="999"
                min="0"
                value={inputValue}
                onChange={handleChange}
              />
            </div>

            <Button onClick={() => handleAddOne()}>
              <svg
                viewBox="0 0 161 161"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="svg-icon"
                style={{ width: "24px", height: "24px" }}
              >
                <path
                  d="M119.668 74.283H85.76V40.375a6.217 6.217 0 00-12.434 0v33.908H39.418a6.217 6.217 0 000 12.434h33.908v33.908a6.217 6.217 0 1012.434 0V86.717h33.908a6.217 6.217 0 100-12.434z"
                  fill="var(--text-200)"
                ></path>
                <path
                  d="M80.013 159.561C36.419 159.561.952 124.094.952 80.5c0-43.594 35.467-79.061 79.06-79.061 43.595 0 79.062 35.467 79.062 79.061 0 43.594-35.467 79.061-79.061 79.061zm0-148.661c-38.378 0-69.6 31.222-69.6 69.6 0 38.378 31.222 69.6 69.6 69.6 38.378 0 69.6-31.222 69.6-69.6 0-38.378-31.222-69.6-69.6-69.6z"
                  fill="none"
                ></path>
              </svg>
            </Button>
          </AddContainer>

          <h5>
            {currencyTag}
            {item.product.discount_price.toFixed(2)}/piece
          </h5>
        </FlexDiv>
      </Right>
    </Container>
  );
};

export default CartProduct;
