import React, { useContext, useEffect } from "react";
import AccountMain from "../../layouts/main/account/AccountMain";
import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";
import { AuthUserContext } from "../../context/AuthUserContext";

const Account = () => {
  const { fetchUserData, authTokens, userProfile, logoutUser } =
    useContext(AuthUserContext);

  useEffect(() => {
    if (!authTokens) {
      logoutUser();
      return;
    } else if (!userProfile) {
      fetchUserData();
    }
  }, []);
  return (
    <div>
      <Header />
      <AccountMain />
      <Footer />
    </div>
  );
};

export default Account;
