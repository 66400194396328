import styled from "styled-components";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { ProductContext } from "../../context/ProductContext";
import AddToCartAnim from "../animations/AddToCartAnim";
import { useNavigation } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import { calculatePrice } from "../../utils/discount";
import { useTranslation } from "react-i18next";

const Container = styled.figure`
  position: relative;
  background-color: var(--bg-200);
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding: var(--spacing-xl) 0 0 0;
  box-shadow: var(--shadow-medium);
  border-radius: 8px;
`;
const WrapperBottom = styled.div`
  width: 100%;
`;
const Picture = styled.picture`
  display: flex; // Omogućavanje flex layout-a za centriranje slike
  align-items: center;
  justify-content: center;
  //height: 240px;
  cursor: pointer;
  width: 100%;

  max-height: 140px;
  min-height: 140px;
  position: relative;

  &:hover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .primary-image {
      display: none;
      display: ${(props) => (props.$secondary_image ? "none" : "block")};
    }

    .secondary-image {
      display: block;
      display: ${(props) => (props.$secondary_image ? "block" : "none")};
    }
  }
  @media (min-width: 768px) {
    max-height: 200px;
    min-height: 200px;
  }
`;

const Image = styled.img`
  width: 120%;
  height: 120%;

  object-fit: contain;
  display: block;
  margin: 0 auto;
  @media (min-width: 768px) {
    width: 120%;
    height: 120%;
  }
  &.secondary-image {
    display: none;
  }
`;
const Bottom = styled.div`
  width: 90%;
`;

const ProductTitle = styled.h5`
  margin: 12px 0 0 0;
  color: var(--text-100);
  height: calc(var(--font-size-large) * 2 + 6px);
  cursor: pointer;
  &:hover {
    color: var(--primary-200);
  }
  text-align: center;
  width: 100%;
  @media (min-width: 768px) {
    font-size: var(--font-size-large);
  }
`;

const DropdownContainer = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  @media (max-width: 768px) {
    grid-template-rows: 1fr 1fr;
  }
  @media (min-width: 768px) {
    grid-template-columns: 2fr 1fr;
  }
`;

const Select = styled.select`
  font-size: var(--font-size-base);
  //padding: 8px;
  color: var(--text-200);
  border: 1px solid var(--text-200);
  border: none;
  background-color: var(--bg-100);
  cursor: pointer;
  //border-radius: 4px;
  //border-radius: 12px 12px 0 0px;
  &:focus {
    outline: none;
    border-color: var(--accent-100);
  }
  overflow: hidden;
  @media (min-width: 768px) {
    //border-radius: 12px 0 0 12px;
  }
  width: 100%;
  max-width: 100%;
  text-align: center;
`;

const Option = styled.option`
  font-size: var(--font-size-large);
  color: var(--text-200);
  background-color: var(--bg-200);
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 12px;
`;
const ButtonWrapper = styled.div`
  position: relative; /* Roditeljski kontejner koji omogućava apsolutno pozicioniranje za decu */
  display: inline-block; /* Omogućava kontejneru da se prilagodi veličini sadržaja */
  width: 100%;
  text-align: center;
  //margin-top: 14px;
`;
const Button = styled.button`
  width: 100%;
  text-transform: capitalize;
  z-index: 1;
  font-size: var(--font-size-base);

  //border-radius: 0 0 12px 12px;
  @media (min-width: 768px) {
    //border-radius: 0 12px 12px 0;
  }
  border-radius: 0;
`;

const ExtraTextDesktop = styled.span`
  display: none;
  font-weight: 100;
  @media (min-width: 768px) {
    display: inline;
  }
`;

const ProductCard = ({ product }) => {
  const { t } = useTranslation();
  const [selectedQuantity, setSelectedQuantity] = useState(10);
  const dispatch = useDispatch();
  const { setIsCartOpen, maxAttributes, currencyTag } =
    useContext(ProductContext);
  const { goToProduct } = useNavigation();
  const navigate = useNavigate();

  const [isAnimating, setIsAnimating] = useState(false);

  const addToCart = (selectedQuantity) => {
    dispatch(
      cartActions.addToCart({
        product: product,
        quantity: selectedQuantity,
      })
    );
    setIsCartOpen(true);
    setIsAnimating(true);
  };

  const handleAnimationComplete = () => {
    setIsAnimating(false);
  };

  const discountedPrice = calculatePrice(product.price, selectedQuantity);

  // Calculate savings
  const savingsPercentage = Math.round(
    ((product.price - discountedPrice) / product.price) * 100
  );

  const primaryImage = product.images.find((img) => img.is_primary);
  const secondaryImage = product.images.find((img) => !img.is_primary);

  const handleChange = (e) => {
    setSelectedQuantity(Number(e.target.value));
  };
  return (
    <Container>
      <Picture
        onClick={() =>
          navigate(goToProduct(product.category_name, product.name))
        }
        $secondary_image={secondaryImage}
      >
        {primaryImage && (
          <Image
            src={primaryImage.thumbnail}
            alt={`${product.category_name} ${product.name}`}
            srcSet={`${primaryImage.thumbnail} 320w, ${primaryImage.large} 480w, ${primaryImage.large} 800w`}
            sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
            loading="lazy"
            className="primary-image"
          />
        )}
        {secondaryImage && (
          <Image
            src={secondaryImage.thumbnail}
            alt={`${product.category_name} ${product.name}`}
            srcSet={`${secondaryImage.thumbnail} 320w, ${secondaryImage.large} 480w, ${secondaryImage.large} 800w`}
            sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
            loading="lazy"
            className="secondary-image"
          />
        )}
      </Picture>
      <Bottom>
        <ProductTitle
          onClick={() =>
            navigate(goToProduct(product.category_name, product.name))
          }
        >
          {product.category_name} {product.name}
        </ProductTitle>
        <FlexDiv style={{ marginTop: "4px" }}>
          <h3 style={{ color: "var(--accent-200)" }}>
            {currencyTag}
            {discountedPrice.toFixed(2)}/{t("PRODUCT_CARD.PIECE")}
          </h3>
          <h4 style={{ fontWeight: 100 }}>
            {currencyTag}
            {(discountedPrice * selectedQuantity).toFixed(2)}
          </h4>
        </FlexDiv>
        <FlexDiv style={{ marginTop: "4px" }}>
          <h4>
            <span style={{ marginRight: "4px" }}>MSRP</span>
            {currencyTag}
            {product.price}
          </h4>
          <h4 style={{ fontWeight: 100, textDecoration: "line-through" }}>
            {currencyTag}
            {(product.price * selectedQuantity).toFixed(2)}
          </h4>
        </FlexDiv>
      </Bottom>
      <DropdownContainer>
        <Select value={selectedQuantity} onChange={handleChange}>
          {[1, 10, 30, 50].map((item) => (
            <Option key={item} value={item}>
              {selectedQuantity === item ? (
                <strong>{`${item} cans`}</strong>
              ) : (
                <>
                  <strong>{`${item} cans`}</strong>{" "}
                  <ExtraTextDesktop>
                    ( {currencyTag}
                    {calculatePrice(product.price, item)} / can)
                  </ExtraTextDesktop>
                </>
              )}
            </Option>
          ))}
        </Select>
        <ButtonWrapper>
          <Button
            onClick={() => addToCart(selectedQuantity)}
            disabled={!product.is_in_stock}
          >
            Add
          </Button>
          {isAnimating && (
            <AddToCartAnim
              isAnimating={isAnimating}
              onComplete={handleAnimationComplete}
            />
          )}
        </ButtonWrapper>
      </DropdownContainer>
    </Container>
  );
};

export default ProductCard;
