import styled from "styled-components";
import APIService from "../../services/APIService";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Success from "../../components/toast/Success";
import { toast } from "react-toastify";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--bg-200);
`;

const Wrapper = styled.div`
  margin: var(--spacing-xxl) 0;
  width: 94%;
  border-radius: 14px;
  padding: var(--spacing-xxl) 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-xl);

  background-color: var(--bg-100);
  @media (min-width: 1025px) {
  }
  @media (min-width: 765px) {
    width: var(--max-width-container);
    //height: 100px;
    //border: 1px solid rgba(86, 147, 191, 0.35);
    padding: var(--spacing-xxl) 0;
    //flex-direction: row;
    align-items: flex-start;
  }
`;
const LeftContainer = styled.div`
  width: 90%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);

  @media (min-width: 765px) {
    padding: 0 var(--spacing-xl);
    width: 60%;
    max-width: 60%;
    flex-direction: row;
  }
  @media (min-width: 768px) and (max-width: 1040px) {
    //flex-direction: column;
  }
`;
const Title = styled.h3`
  width: 100%;
  text-align: left;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  font-size: var(--font-size-xlarge);
  color: var(--text-200);
  @media (min-width: 765px) {
    font-size: var(--font-size-xxlarge);
  }
`;
const Text = styled.p`
  font-size: var(--font-size-base);
  max-width: 100%;
  color: var(--text-200);
  @media (min-width: 768px) and (max-width: 1040px) {
    max-width: 80%;
  }
`;

const InputContainer = styled.form`
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 4px;
  //gap: var(--spacing-xxs);

  @media (min-width: 768px) and (max-width: 1040px) {
    //width: 160px;
  }
  @media (min-width: 768px) {
    width: 40%;
    max-width: 40%;
    flex-direction: row;
    gap: 0;
    padding: 0 var(--spacing-xl);
  }
`;
const Input = styled.input`
  font-size: var(--font-size-medium);
  font-weight: 400;

  width: calc(90% - var(--spacing-xs) * 2);
  height: 46px;
  min-height: 46px;
  max-height: 46px;
  padding: 0 var(--spacing-xs);
  font-size: var(--font-size-base);
  font-style: normal;
  border: 1px solid var(--primary-200);
  background-color: var(--bg-200);
  &::placeholder {
    color: var(--text-100);

    font-weight: 400;
    line-height: 24px;
  }
  border-radius: 8px;
  @media (min-width: 768px) {
    width: calc(65% - var(--spacing-xs) * 2);
  }
`;
const Button = styled.button`
  width: 90%;
  font-size: var(--font-size-base);
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  padding: 0;
  @media (min-width: 768px) {
    width: 33%;
  }
`;

const showSuccessNotification = (text) => {
  toast(<Success text={text} />, {
    position: "top-left",
    autoClose: 5000, // Obaveštenje će se automatski skloniti nakon 5 sekundi
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "custom-toast-success",
    progressClassName: "custom-progress-bar",
  });
};

const Subscription = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  //const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      return;
    }
    try {
      const response = await APIService.subscribe(email);
      showSuccessNotification(t("NOTIFICATIONS.SUCCESS"));
      setEmail("");
    } catch (error) {
      if (error.response) {
      }
    }
  };

  const validateEmail = (email) => {
    const trimmedEmail = email.trim();
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(trimmedEmail).toLowerCase());
  };
  return (
    <Container>
      <Wrapper>
        <LeftContainer>
          <div>
            <Title>{t("SUBSCRIPTION.TITLE")} </Title>
            <Text>{t("SUBSCRIPTION.DESCRIPTION")}</Text>
          </div>
        </LeftContainer>
        <InputContainer onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder={t("PLACEHOLDERS.EMAIL")}
            aria-label="Subscription"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button type="submit">{t("BUTTONS.SUBMIT")}</Button>
        </InputContainer>
      </Wrapper>
    </Container>
  );
};

export default Subscription;
