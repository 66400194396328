import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../layouts/header/Header";
import { useTranslation } from "react-i18next";
import { ProductContext } from "../../context/ProductContext";
import ProductSlider from "../../components/product/ProductSlider";
import BannerHorizontal from "../../components/hero/BannerHorizontal";
import Footer from "../footer/Footer";
//import Subscription from "../footer/Subscription";
import ProductSliderHorizontal from "../../components/product/ProductSliderHorizontal";
import ProductCard from "../../components/product/ProductCard";
import { Link, useNavigate } from "react-router-dom";
import AboutUsSection from "../../components/section/AboutUsSection";
import { useNavigation } from "../../utils/navigation";

import featuredImage from "../../assets/images/banner/skruf_zyn_cuba.webp";
import featuredImageM from "../../assets/images/banner/skruf_zyn_cuba_m.webp";
import hotImage from "../../assets/images/banner/velo_color_2x.webp";
import hotImageM from "../../assets/images/banner/velo_color_mobile_2x.webp";

const Container = styled.article`
  color: ${(props) => props.theme.textColor};
  padding-top: 72px;
`;
const Section = styled.section`
  //height: 100vh;
  @media (min-width: 765px) {
    margin-top: 80px;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  @media (min-width: 765px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;
const PromoTitle = styled.h2`
  font-size: var(--font-size-xxlarge);
  margin: 40px 0 14px 0;
  text-align: center;
`;
const GridCategories = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 8px;
  align-items: center;
  justify-content: center;
  @media (min-width: 765px) {
    grid-template-columns: repeat(6, auto);
    gap: 14px;
  }
`;
const CategoryItem = styled.div`
  background-color: var(--bg-200);
  padding: 8px 14px;
  border-radius: 14px;
  font-size: var(--font-size-xxlarge);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--bg-300);
  text-transform: uppercase;
  box-sizing: border-box;
  &:hover {
    background-color: var(--bg-100);
    cursor: pointer;
  }
`;
const CustomLink = styled(Link)`
  text-align: center;
  width: 100%;
  display: block;
  color: var(--text-color);
  font-size: var(--font-size-base);
  margin: 14px 0;
`;
const HomeMain = () => {
  const { t } = useTranslation();
  const { goToCategory, goToFeatured } = useNavigation();
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const navigate = useNavigate();
  const {
    products,
    bestSellers,
    newArrivals,
    loadNewArrivals,
    loadProducts,
    loadBestSellers,
    setCategory,
    category,
    categories,
    fetchFeaturedProducts,
  } = useContext(ProductContext);

  useEffect(() => {
    if (category) {
      loadProducts();
      setCategory(null);
    } else if (products.length) {
      loadBestSellers();
      loadNewArrivals();
    } else {
      loadProducts();
    }
  }, [products]);

  useEffect(() => {
    const loadFeaturedProducts = async () => {
      const products = await fetchFeaturedProducts("featuredat");
      setFeaturedProducts(products); // Postavljamo proizvode nakon što se Promise razreši
    };

    loadFeaturedProducts();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Section>
          <BannerHorizontal
            desktopImage={featuredImage}
            mobileImage={featuredImageM}
            subtitle={t("BANNER.FEATURED.SUBTITLE")}
            title={t("BANNER.FEATURED.TITLE")}
            text={t("BANNER.FEATURED.TEXT")}
          />
          <PromoTitle>{t("HOME.PROMO_TITLES.FEATURED")}</PromoTitle>
          <Grid>
            {featuredProducts?.slice(0, 12).map((product, index) => (
              <ProductCard key={index} product={product} />
            ))}
          </Grid>
          <BannerHorizontal
            desktopImage={hotImage}
            mobileImage={hotImageM}
            subtitle={t("BANNER.HOT_DEALS.SUBTITLE")}
            title={t("BANNER.HOT_DEALS.TITLE")}
            text={t("BANNER.HOT_DEALS.TEXT")}
          />
          <PromoTitle>{t("HOME.PROMO_TITLES.HOT_DEALS")}</PromoTitle>
          <Grid>
            {newArrivals.slice(0, 5).map((product, index) => (
              <ProductCard key={index} product={product} />
            ))}
          </Grid>
          <CustomLink>{t("HOME.LINKS.SEE_HOT_DEALS")}</CustomLink>
          <PromoTitle>{t("HOME.PROMO_TITLES.BRANDS")}</PromoTitle>
          <GridCategories>
            {categories.slice(0, 6).map((category, index) => (
              <CategoryItem
                key={index}
                onClick={() => navigate(goToCategory(category.name))}
              >
                {category.name}
              </CategoryItem>
            ))}
          </GridCategories>
          <AboutUsSection />
        </Section>
      </Container>

      <Footer />
    </>
  );
};

export default HomeMain;
