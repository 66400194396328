import { useContext, useState } from "react";
import FilterSection from "./FilterSection";
import styled from "styled-components";
import { ProductContext } from "../../context/ProductContext";
import ToggleSwitch from "../animations/ToggleSwitch";
import { useTranslation } from "react-i18next";

const FilterContainer = styled.div`
  padding: 20px 0;
  width: 94%;
  margin: 0 auto;
`;
const Button = styled.button`
  background-color: transparent;
  color: var(--text-100);
  border: none;
  border-radius: 0;
  width: 100%;
  justify-content: space-between;
  padding: 12px 0;
  &:hover {
    background-color: transparent;
    svg {
      transform: translateX(10px);
      transition: transform 0.2s ease;
    }
  }
  svg {
    transition: transform 0.2s ease;
  }
`;

const FilterData = ({ toggleMenu }) => {
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState(false);
  const { category } = useContext(ProductContext);

  return (
    <div>
      <FilterContainer>
        {!category && (
          <Button onClick={() => setShowFilters("brand")}>
            {t("FILTER.BRAND")}
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 1024 1024"
              class="icon"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
                fill="var(--text-100)"
              />
            </svg>
          </Button>
        )}
        <Button onClick={() => setShowFilters("format")}>
          {t("FILTER.FORMAT")}
          <svg
            width="20px"
            height="20px"
            viewBox="0 0 1024 1024"
            class="icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
              fill="var(--text-100)"
            />
          </svg>
        </Button>
        <Button onClick={() => setShowFilters("nicotine")}>
          {t("FILTER.STRENGTH")}
          <svg
            width="20px"
            height="20px"
            viewBox="0 0 1024 1024"
            class="icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
              fill="var(--text-100)"
            />
          </svg>
        </Button>
      </FilterContainer>

      <FilterSection
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        toggleMenu={toggleMenu}
      />
    </div>
  );
};

export default FilterData;
