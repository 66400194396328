import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { ThemeProvider } from "styled-components";
import darkTheme from "./utils/theme";
import { useContext, useEffect } from "react";
import { ProductContext } from "./context/ProductContext";
import { AuthUserProvider } from "./context/AuthUserContext";
import { HelmetProvider } from "react-helmet-async";
import Product from "./pages/Product";
import Shop from "./pages/Shop";
import Login from "./pages/account/Login";
import Register from "./pages/account/Register";
import Verification from "./pages/account/Verification";
import Account from "./pages/account/Account";
import ProtectedRoute, { ProtectedRouteSignUpIn } from "./ProtectedRoute";
import Checkout from "./pages/Checkout";
import BestSeller from "./pages/product/BestSeller";
import ThankYou from "./pages/ThankYou";
import ForgotPassword from "./layouts/main/account/ForgotPassword";
import PasswordReset from "./layouts/main/account/ResetPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ScrollToTop from "./utils/ScrollToTop";
import FeaturedHome from "./pages/FeatureHome";
import NewArrivals from "./pages/product/NewArrivals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubscriptionPopup from "./components/popup/SubscriptionPopup";

function App() {
  const { loadCategories, categories } = useContext(ProductContext);
  useEffect(() => {
    if (!categories || !categories.length) {
      loadCategories();
    }
  }, []);

  return (
    <>
      <HelmetProvider>
        <ThemeProvider theme={darkTheme}>
          <BrowserRouter>
            <AuthUserProvider>
              <SubscriptionPopup />
              <ScrollToTop />
              <ToastContainer style={{ zIndex: "999999" }} />
              <Routes>
                <Route path="/:lang?" element={<Home />}></Route>
                <Route
                  path="/:lang/featured"
                  element={<FeaturedHome />}
                ></Route>
                <Route path="/:lang/:slug" element={<Product />} />
                <Route
                  path="/:lang/nicotine-pouches/:slug?"
                  element={<Shop />}
                ></Route>
                <Route path="/:lang/best-seller" element={<BestSeller />} />
                <Route path="/:lang/new-arrivals" element={<NewArrivals />} />

                <Route
                  path="/:lang/signup"
                  element={
                    <ProtectedRouteSignUpIn>
                      <Register />
                    </ProtectedRouteSignUpIn>
                  }
                />
                <Route
                  path="/:lang/password"
                  element={
                    <ProtectedRouteSignUpIn>
                      <ForgotPassword />
                    </ProtectedRouteSignUpIn>
                  }
                />
                <Route
                  path="/:lang/reset-password/:uid/:token/"
                  element={
                    <ProtectedRouteSignUpIn>
                      <PasswordReset />
                    </ProtectedRouteSignUpIn>
                  }
                />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/:lang/signin"
                  element={
                    <ProtectedRouteSignUpIn>
                      <Login />
                    </ProtectedRouteSignUpIn>
                  }
                />
                <Route
                  path="/:lang/verification"
                  element={
                    <ProtectedRoute>
                      <Verification />
                    </ProtectedRoute>
                  }
                />
                <Route path="/:lang/checkout" element={<Checkout />} />
                <Route path="/:lang/thank-you" element={<ThankYou />} />

                {/* Account */}
                <Route
                  path="/:lang/account"
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:lang/account/profile"
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:lang/account/address-book"
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:lang/account/orders"
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:lang/account/saved-cards"
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:lang/account/address-book/new"
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:lang/account/address-book/edit/:id"
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </AuthUserProvider>
          </BrowserRouter>
        </ThemeProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
