import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ButtonLoading from "../../../components/animations/ButtonLoading";
import Header from "../../header/Header";
import APIService from "../../../services/APIService";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  background-color: #1a2c38;
  background-color: var(--bg-200);
  position: relative;

  padding: 40px 0;
  @media (min-width: 768px) {
    width: 700px;
  }
`;
const FormWrapper = styled.div`
  min-width: 90%;
  max-width: 90%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const FormContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 500px;
  }
`;
const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //gap: 8px;
  margin: 10px 0;
`;

const Title = styled.h1`
  font-size: var(--font-size-xxlarge);
  text-align: center;
  font-weight: 400;
  padding: 0;
  margin: 0;
  color: var(--text-200);
  text-transform: uppercase;
  margin-bottom: var(--spacing-sm);
`;
const Subtitle = styled.div`
  font-size: var(--font-size-small);
  color: var(--text-100);
  text-align: center;

  margin-bottom: var(--spacing-xl);
`;

const Input = styled.input`
  outline: none;
  padding: var(--spacing-sm) 0 var(--spacing-sm) var(--spacing-xs);
  border: 1px solid #ccc;
  width: calc(100% - 8px);
  font-size: var(--font-size-small);
  color: var(--text-200);
  background-color: var(--bg-100);
  margin: var(--spacing-xxs) 0 var(--spacing-md) 0;
  ::placeholder {
    font-size: var(--font-size-small);
    color: var(--text-200);
  }
`;
const Label = styled.label`
  font-size: var(--font-size-base);
  text-transform: capitalize;
  color: var(--text-200);
  text-align: left;
  min-width: 100%;
`;

const ButtonWrapper = styled.div`
  position: relative; /* Roditeljski kontejner koji omogućava apsolutno pozicioniranje za decu */
  display: inline-block; /* Omogućava kontejneru da se prilagodi veličini sadržaja */
  width: 90%;
  text-align: center;
  margin: var(--spacing-md) 0;
`;
const Button = styled.button`
  width: 100%;
  height: 40px;
  text-transform: capitalize;
  border-radius: 0;
`;

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const [isAnimating, setIsAnimating] = useState(false);

  const [errorEmailText, setErrorEmailText] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    APIService.ResetPasswordRequest(email);
  };
  const handleAnimationComplete = () => {
    setIsAnimating(false);
  };

  return (
    <>
      <Header />

      <Container className="header-top-padding">
        <Wrapper>
          <FormContainer>
            <Title>{t("LOGIN.FORGOT_PASSWORD_TITLE")}</Title>
            <Subtitle>{t("LOGIN.FORGOT_PASSWORD_SUBTITLE")}</Subtitle>

            <Form onSubmit={handleSubmit}>
              <FormWrapper>
                <Label htmlFor="email">{t("LABELS.EMAIL")}</Label>
                <Input
                  type="email"
                  id="email"
                  value={email}
                  placeholder={t("PLACEHOLDERS.EMAIL")}
                  onChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
                  required
                />
              </FormWrapper>

              <ButtonWrapper>
                <Button type="submit">{t("BUTTONS.RECOVER_PASSWORD")}</Button>
                {isAnimating && (
                  <ButtonLoading
                    isAnimating={isAnimating}
                    onComplete={handleAnimationComplete}
                  />
                )}
              </ButtonWrapper>
            </Form>
          </FormContainer>
        </Wrapper>
      </Container>
    </>
  );
};

export default ForgotPassword;
