import { useState, useContext, useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../../../context/AuthUserContext";
import APIService from "../../../services/APIService";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  background-color: #1a2c38;
  background-color: var(--bg-200);
  position: relative;

  padding: 40px 0;
  @media (min-width: 768px) {
    width: 700px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 500px;
  }
`;

const ExitContainer = styled.div`
  display: block;
  position: absolute;
  top: 60px;
  right: 20px;
  //display: none;

  @media (min-width: 768px) {
    display: block;
    position: absolute;
    top: 20px;
    cursor: pointer;
    right: 10%;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-base);
  color: var(--primary-200);
  font-weight: 100;
  text-transform: capitalize;
  gap: var(--spacing-xs);
  margin-bottom: var(--spacing-lg);
`;

const Title = styled.h1`
  font-size: var(--font-size-xxlarge);
  text-align: center;
  font-weight: 400;
  padding: 0;
  margin: 0;
  color: var(--text-200);
  text-transform: uppercase;
  margin-bottom: var(--spacing-sm);
`;
const Subtitle = styled.div`
  font-size: var(--font-size-small);
  color: var(--text-100);
  text-align: center;

  margin-bottom: var(--spacing-xl);
`;
const InputGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: var(--spacing-md) 0 var(--spacing-xl) 0;
`;

const InputBox = styled.input`
  width: 50px;
  height: 50px;
  font-size: 24px;
  text-align: center;
  margin: 0 5px;
  border: 2px solid #dcdcdc;

  &:focus {
    border-color: var(--primary-200);
    outline: none;
  }
`;

const ResendLink = styled.span`
  color: var(--primary-200);
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: var(--primary-200);
  }
`;

const VerificationMain = () => {
  const { t } = useTranslation();
  const inputRefs = useRef([]);
  const [code, setCode] = useState(["", "", "", ""]); // State to track the 4 digits
  const { authTokens, setUserProfile, userProfile, setAuthTokens } =
    useContext(AuthUserContext);
  const [canResend, setCanResend] = useState(true); // Da li je moguće ponovo poslati kod
  const [timer, setTimer] = useState(0); // Brojač za ponovno slanje
  const navigate = useNavigate();

  // useEffect za inicijalno slanje koda sa zakašnjenjem od 1 sekunde
  useEffect(() => {
    const lastResendTime = localStorage.getItem("lastResendTime");

    if (lastResendTime) {
      const elapsedTime = (new Date().getTime() - lastResendTime) / 1000; // Razlika u sekundama
      if (elapsedTime < 40) {
        // Ako je prošlo manje od 40 sekundi, pokreni odbrojavanje
        setCanResend(false);
        setTimer(40 - Math.floor(elapsedTime)); // Postavi preostalo vreme u sekunde
      } else {
        // Ako je prošlo više od 40 sekundi, dozvoli ponovno slanje
        setCanResend(true);
      }
    } else {
      // Ako nikada nije poslat kod, pokreni handleResendCode nakon 1 sekunde
      const timeout = setTimeout(() => {
        handleResendCode(); // Prvo slanje koda
      }, 500); // Zakašnjenje od 1 sekunde

      return () => clearTimeout(timeout); // Očisti timeout ako se komponenta demontira pre isteka vremena
    }
  }, []); // Praćenje pri prvom učitavanju stranice
  // useEffect za praćenje vremena i onemogućavanje ponovnog slanja
  useEffect(() => {
    // Provera da li postoji zapis o poslednjem slanju koda u localStorage
    const lastResendTime = localStorage.getItem("lastResendTime");
    if (lastResendTime) {
      const elapsedTime = (new Date().getTime() - lastResendTime) / 1000; // Razlika u sekundama
      if (elapsedTime < 40) {
        setCanResend(false);
        setTimer(40 - Math.floor(elapsedTime)); // Postavi preostalo vreme u sekunde
      }
    }

    // Postavljanje intervala za odbrojavanje
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        setCanResend(true); // Kada istekne vreme, dozvoli ponovno slanje
        localStorage.removeItem("lastResendTime");
      }
    }, 1000);

    return () => clearInterval(interval); // Očisti interval kada komponenta bude demontirana
  }, [timer]);

  // Function to handle input changes
  const handleInputChange = (e, index) => {
    const value = e.target.value;

    if (/^[0-9]$/.test(value) || value === "") {
      // Allow only single digit
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Automatically move to the next input
      if (value.length === 1 && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }

      // Handle backspace to move focus back
      if (value === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      }

      // Automatically submit if all fields are filled
      if (newCode.every((digit) => digit !== "")) {
        handleSubmit(newCode.join("")); // Submit the concatenated code
      }
    }
  };

  // Function to handle form submission
  const handleSubmit = async (fullCode) => {
    try {
      const response = await APIService.VerifyCode(fullCode, authTokens.access);
      console.log("Verification response:", response);
      // Ažurirajte lokalni userProfile
      if (userProfile) {
        const updatedUserProfile = { ...userProfile, is_email_verified: true };
        setUserProfile(updatedUserProfile);

        // Čuvanje ažuriranog profila u localStorage
        localStorage.setItem(
          "userProfileData",
          JSON.stringify(updatedUserProfile)
        );
      }
      setAuthTokens({ access: response.access, refresh: response.refresh });
      localStorage.setItem(
        "authTokens",
        JSON.stringify({ access: response.access, refresh: response.refresh })
      );
      const decoded = jwtDecode(response.access);
      console.log("Decoded", decoded);
      navigate(-1);
    } catch (error) {
      // Reset polja za unos nakon slanja
      setCode(["", "", "", ""]);
    }
  };

  // Function to handle resend code logic
  const handleResendCode = async () => {
    if (!canResend) return; // Ako nije dozvoljeno slanje, prekini funkciju

    try {
      const decodedToken = jwtDecode(authTokens.access);
      const email = decodedToken.email;
      console.log(email);
      // Poziv API-ja za ponovno slanje koda
      const response = await APIService.ResendVerification(email);

      // Sačuvaj vreme kada je kod poslat
      const currentTime = new Date().getTime();
      localStorage.setItem("lastResendTime", currentTime);

      // Pokreni odbrojavanje za 40 sekundi
      setCanResend(false);
      setTimer(40); // Postavi timer na 40 sekundi
    } catch (error) {
      console.error("Resend error:", error);
    }
  };
  useEffect(() => {
    handleResendCode();
  }, []);

  const decodedToken = jwtDecode(authTokens.access);
  const email = decodedToken.email;
  return (
    <Container className="header-top-padding">
      <Wrapper
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <ExitContainer>
          <svg
            aria-hidden="true"
            focusable="false"
            role="presentation"
            viewBox="0 0 64 64"
            className="svg-icon"
            style={{
              width: "calc(var(--font-size-xxlarge) + 5px)",
              height: "calc(var(--font-size-xxlarge) + 5px)",
            }}
          >
            <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
          </svg>
        </ExitContainer>
        <FormContainer>
          <Logo>
            <svg
              width="24"
              height="24"
              viewBox="0 0 298 298"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M148.707 0C66.7245 0 0 66.7245 0 148.707C0 230.689 66.7245 297.414 148.707 297.414C230.689 297.414 297.414 230.689 297.414 148.707C297.414 66.7245 230.689 0 148.707 0ZM294.707 148.707C294.707 188.275 278.843 224.204 253.18 250.567L244.688 242.074C268.158 217.904 282.669 184.962 282.669 148.707C282.669 112.452 267.878 78.8562 243.988 54.6394L252.48 46.1472C278.563 72.557 294.661 108.766 294.661 148.707H294.707ZM17.3576 148.707C17.3576 76.2899 76.2432 17.4043 148.66 17.4043C221.077 17.4043 279.963 76.2899 279.963 148.707C279.963 221.124 221.077 280.009 148.66 280.009C76.2432 280.009 17.3576 221.124 17.3576 148.707ZM250.52 44.2808L242.028 52.7731C217.858 29.2562 184.915 14.7447 148.614 14.7447C112.312 14.7447 79.416 29.2562 55.2458 52.7731L46.7536 44.2808C73.0701 18.6176 108.999 2.75295 148.567 2.75295C188.135 2.75295 224.11 18.6175 250.427 44.3275L250.52 44.2808ZM44.7939 46.1939L53.2862 54.6862C29.3494 78.9029 14.558 112.172 14.558 148.8C14.558 185.429 29.0693 218.044 52.5862 242.214L44.1406 250.707C18.4307 224.39 2.56627 188.415 2.56627 148.847C2.56627 109.279 18.7107 72.6504 44.7939 46.2872V46.1939ZM46.0539 252.667L54.5461 244.174C78.7629 268.064 111.985 282.856 148.614 282.856C185.242 282.856 218.511 268.064 242.728 244.128L251.22 252.62C224.857 278.703 188.602 294.848 148.66 294.848C108.719 294.848 72.5102 278.703 46.147 252.667"
                fill="var(--primary-200)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M207.722 57.7351C202.622 66.9627 200.922 78.8759 205.416 87.3302C214.54 104.491 230.158 114.314 239.894 131.851C273.15 191.781 209.011 201.72 174.784 209.915C155.428 214.537 143.812 230.506 158.492 258.688C214.436 253.98 258.39 207.07 258.39 149.903C258.39 111.144 238.177 77.1018 207.722 57.7266"
                fill="var(--primary-200)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M191.927 105.506C177.373 95.2564 162.525 82.7355 160.972 63.8935C160.307 55.8558 164.875 48.4915 171.632 43.0459C157.459 40.0907 142.84 39.9776 128.623 42.713C114.406 45.4483 100.873 50.9779 88.8086 58.9812C96.9207 91.2874 127.561 94.6763 155.629 103.143C195.722 115.235 214.322 153.656 158.871 159.818C81.4401 168.423 72.2954 187.545 127.861 256.987C133.447 258.095 139.112 258.763 144.803 258.984C114.222 212.039 131.18 198.9 188.95 180.965C245.166 163.485 224.646 128.565 191.925 105.51"
                fill="var(--primary-200)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M123.936 118.509C100.162 114.213 67.312 112.348 58.2119 97.5595C57.3549 96.166 56.5939 94.7157 55.9342 93.2188C45.5264 110.296 40.0342 129.915 40.0626 149.915C40.0626 197.952 71.1131 238.761 114.236 253.341C95.0693 233.567 63.4812 210.21 71.1047 172.043C76.204 146.547 105.038 150.269 127.675 148.404C160.298 145.722 156.82 124.463 123.927 118.509"
                fill="var(--primary-200)"
              />
            </svg>
            SnusCo
          </Logo>
          <Title>{t("VERIFICATION.TITLE")}</Title>
          <Subtitle>{t("VERIFICATION.SUBTITLE", { email })}</Subtitle>
          <InputGroup>
            {Array(4)
              .fill("")
              .map((_, index) => (
                <InputBox
                  key={index}
                  type="text"
                  maxLength="1"
                  value={code[index]} // Controlled input
                  ref={(el) => (inputRefs.current[index] = el)} // Assign refs to each input
                  onChange={(e) => handleInputChange(e, index)}
                />
              ))}
          </InputGroup>
          <Subtitle>
            {t("VERIFICATION.RESEND_INFO")}{" "}
            {canResend ? (
              <ResendLink onClick={handleResendCode}>
                {t("VERIFICATION.RESEND_LINK")}
              </ResendLink>
            ) : (
              <span>{t("VERIFICATION.TIMER_INFO", { timer })}</span>
            )}
          </Subtitle>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default VerificationMain;
