import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { ProductContext } from "../../context/ProductContext";

const Container = styled.figure`
  min-height: 120px;
  position: relative;
  background-color: var(--bg-200);
  width: 90%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: var(--spacing-sm);
  border-bottom: 1px solid var(--bg-300);
  margin: 0 auto;
`;
const Picture = styled.picture`
  display: flex; // Omogućavanje flex layout-a za centriranje slike
  align-items: center;
  justify-content: center;
  width: 140px;
  max-height: 120px;
  min-height: 120px;
  position: relative;
`;

const Image = styled.img`
  height: 100%;
  max-height: 100%;
  //max-width: 200px;
  width: 120px;
  max-width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  &.secondary-image {
    display: none;
  }
`;

const Right = styled.div`
  display: grid;
  grid-template-columns: repeat(1fr);
  gap: 8px;
  width: 100%;
  padding-top: 4px;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;
const P = styled.p`
  padding: 0;
  margin: 0;
  font-size: var(--font-size-base);
`;

const InvoiceProduct = ({ item }) => {
  const { currencyTag } = useContext(ProductContext);

  // Try to find a non-primary image; if none exist, use the primary image as fallback
  const primaryImage =
    item.product.images.find((img) => !img.is_primary) ||
    item.product.images.find((img) => img.is_primary);

  return (
    <Container>
      <Picture>
        {primaryImage && (
          <Image
            src={primaryImage.thumbnail}
            alt={`${item.product.category_name} ${item.product.name}`}
            srcSet={`${primaryImage.thumbnail} 320w, ${primaryImage.large} 480w, ${primaryImage.large} 800w`}
            sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
            loading="lazy"
            className="primary-image"
          />
        )}
      </Picture>
      <Right>
        <FlexDiv style={{ justifyContent: "space-between", marginTop: "24px" }}>
          <h4>
            {item.product.category_name} {item.product.name}{" "}
            {item.product.nicotine}MG
          </h4>
        </FlexDiv>
        <FlexDiv style={{ justifyContent: "space-between", width: "94%" }}>
          <P>{item.quantity} Cans</P>
          <h4>
            {currencyTag}
            {item.product.discount_price.toFixed(2)}
          </h4>
        </FlexDiv>
      </Right>
    </Container>
  );
};

export default InvoiceProduct;
