import styled, { keyframes } from "styled-components";

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  width: 20px;
  height: 20px;
  border: 4px solid #ccc;
  border-top-color: var(--accent-100);
  border-radius: 50%;
  animation: ${spinnerAnimation} 1s linear infinite;
  display: inline-block;
  position: relative;
`;

const Spinner = () => {
  return <Container />;
};

export default Spinner;
