import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../../../context/AuthUserContext";

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-md);
  position: relative;
  background-color: var(--bg-200);
  padding: 14px;
`;
const InputFlex = styled(FlexDiv)`
  flex-direction: column;
  gap: 0;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: var(--spacing-md);
  }
`;
const Text = styled.h4`
  font-weight: 300;
  display: none;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  font-size: var(--font-size-small);
  color: var(--text-200);
  @media (min-width: 768px) {
    font-size: var(--font-size-base);
    color: var(--text-100);
    display: flex;
  }
`;

const CancelText = styled(Text)`
  color: var(--primary-200);
  cursor: pointer;
`;
const Label = styled.label`
  width: calc(100% - var(--spacing-md));
  font-size: var(--font-size-small);
  color: var(--text-200);
  font-weight: 300;
  margin-top: 14px;
  @media (min-width: 768px) {
    display: none;
  }
`;
const Input = styled.input`
  padding: 14px 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: var(--font-size-small);
  font-weight: 700;
  &::placeholder {
    text-transform: capitalize;
  }
  &:disabled {
    background-color: var(--bg-100);
    cursor: not-allowed;
  }
  width: calc(100% - var(--spacing-md));
  @media (min-width: 768px) {
    width: 300px;
    margin: var(--spacing-md) 0;
    padding: var(--spacing-md) 8px;
    border: 1px solid #ccc;
  }
`;

const BrT = styled.div`
  height: 1px;
  background-color: var(--text-200);
  width: 100%;
  margin: var(--spacing-lg) 0;
  opacity: 0.1;
`;
const SaveButton = styled.button`
  color: var(--bg-100);
  background-color: var(--primary-200);
  &:hover {
    background-color: var(--bg-100);
    color: var(--primary-200);
  }
  width: 100%;
  margin-top: var(--spacing-md);
  padding: var(--spacing-xs) var(--spacing-md);
  @media (min-width: 768px) {
    width: auto;
    margin-top: 0;
    padding: var(--spacing-sm) var(--spacing-xxl);
    display: ${(props) => (props.$edit ? "flex" : "none")};
  }
`;
const SaveButtonDesktop = styled.button`
  background-color: transparent;
  border: none;
  color: var(--primary-200);
  padding: 0;
  position: absolute;
  right: 10%;
  bottom: 16px;
  width: auto;
  margin: 0;

  @media (min-width: 768px) {
    position: static;
    color: var(--bg-100);
    background-color: var(--primary-200);
    &:hover {
      background-color: var(--bg-100);
      color: var(--primary-200);
    }
    width: auto;
    margin-top: 0;
    padding: var(--spacing-sm) var(--spacing-xxl);
    display: ${(props) => (props.$edit ? "flex" : "none")};
  }
`;

const PersonalInformation = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { userProfile, updateUserInfo } = useContext(AuthUserContext);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const [editName, setEditName] = useState(false);
  const [editMobile, setEditMobile] = useState(false);

  useEffect(() => {
    if (!userProfile) {
      return;
    }
    setName(userProfile.first_name || "");
    setLastName(userProfile.last_name || "");
    setEmail(userProfile.email || "");
    setMobile(userProfile.phone_number || "");
  }, [userProfile]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = {
      first_name: name,
      last_name: lastName,
    };
    try {
      const createdAddress = await updateUserInfo(data);
    } catch (error) {
      console.error("Greška prilikom ažuriranja", error); // Obradite grešku
    }
    setEditName(false);
  };
  const handleUpdateMobile = async (e) => {
    e.preventDefault();
    const data = {
      phone_number: mobile,
    };
    try {
      const createdAddress = await updateUserInfo(data);
    } catch (error) {
      console.error("Greška prilikom ažuriranja", error); // Obradite grešku
    }
    setEditMobile(false);
  };

  return (
    <div>
      <FlexDiv>
        <Text>{t("PROFILE.PERSONAL_INFORMATION")}</Text>

        {!editName ? (
          <Text onClick={() => setEditName(true)} style={{ cursor: "pointer" }}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_303_6674)">
                <path
                  d="M5.83301 5.8335H4.99967C4.55765 5.8335 4.13372 6.00909 3.82116 6.32165C3.5086 6.63421 3.33301 7.05814 3.33301 7.50016V15.0002C3.33301 15.4422 3.5086 15.8661 3.82116 16.1787C4.13372 16.4912 4.55765 16.6668 4.99967 16.6668H12.4997C12.9417 16.6668 13.3656 16.4912 13.6782 16.1787C13.9907 15.8661 14.1663 15.4422 14.1663 15.0002V14.1668"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.9875 5.48759C17.3157 5.15938 17.5001 4.71424 17.5001 4.25009C17.5001 3.78594 17.3157 3.34079 16.9875 3.01259C16.6593 2.68438 16.2142 2.5 15.75 2.5C15.2858 2.5 14.8407 2.68438 14.5125 3.01259L7.5 10.0001V12.5001H10L16.9875 5.48759Z"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.333 4.1665L15.833 6.6665"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_303_6674">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {t("BUTTONS.EDIT")}
          </Text>
        ) : (
          <CancelText onClick={() => setEditName(false)}>
            {t("BUTTONS.CANCEL")}
          </CancelText>
        )}
      </FlexDiv>
      <InputFlex>
        <Label>{t("LABELS.NAME")}</Label>
        <Input
          type="text"
          placeholder={t("PLACEHOLDERS.NAME")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={!editName && !isMobile}
        />
        <Label>{t("LABELS.SURNAME")}</Label>
        <Input
          type="text"
          placeholder={t("PLACEHOLDERS.SURNAME")}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          disabled={!editName && !isMobile}
        />

        <SaveButton
          $edit={editName}
          className="button-transparent"
          onClick={(e) => {
            handleUpdate(e);
          }}
        >
          {t("BUTTONS.SAVE")}
        </SaveButton>
      </InputFlex>
      <BrT />
      <Label>{t("LABELS.EMAIL")}</Label>
      <Text>{t("LABELS.EMAIL")}</Text>
      <Input
        type="email"
        placeholder={t("PLACEHOLDERS.EMAIL")}
        value={email}
        disabled
      />
      <BrT />
      <FlexDiv>
        <Text>{t("LABELS.PHONE_NUMBER")}</Text>
        <Label>{t("LABELS.PHONE_NUMBER")}</Label>
        {!editMobile ? (
          <Text
            onClick={() => setEditMobile(true)}
            style={{ cursor: "pointer" }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_303_6674)">
                <path
                  d="M5.83301 5.8335H4.99967C4.55765 5.8335 4.13372 6.00909 3.82116 6.32165C3.5086 6.63421 3.33301 7.05814 3.33301 7.50016V15.0002C3.33301 15.4422 3.5086 15.8661 3.82116 16.1787C4.13372 16.4912 4.55765 16.6668 4.99967 16.6668H12.4997C12.9417 16.6668 13.3656 16.4912 13.6782 16.1787C13.9907 15.8661 14.1663 15.4422 14.1663 15.0002V14.1668"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.9875 5.48759C17.3157 5.15938 17.5001 4.71424 17.5001 4.25009C17.5001 3.78594 17.3157 3.34079 16.9875 3.01259C16.6593 2.68438 16.2142 2.5 15.75 2.5C15.2858 2.5 14.8407 2.68438 14.5125 3.01259L7.5 10.0001V12.5001H10L16.9875 5.48759Z"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.333 4.1665L15.833 6.6665"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_303_6674">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {t("BUTTONS.EDIT")}
          </Text>
        ) : (
          <CancelText onClick={() => setEditMobile(false)}>
            {t("BUTTONS.CANCEL")}
          </CancelText>
        )}
      </FlexDiv>
      <FlexDiv>
        <Input
          type="text"
          placeholder={t("PLACEHOLDERS.PHONE_NUMBER")}
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          disabled={!editMobile && !isMobile}
        />
        <SaveButtonDesktop
          $edit={editMobile}
          className="button-transparent"
          onClick={(e) => {
            handleUpdateMobile(e);
          }}
        >
          {isMobile ? t("BUTTONS.UPDATE") : t("BUTTONS.SAVE")}
        </SaveButtonDesktop>
      </FlexDiv>
    </div>
  );
};

export default PersonalInformation;
