import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthUserContext } from "../../context/AuthUserContext";
import { useNavigation } from "../../utils/navigation";
import { useTranslation } from "react-i18next";

const Container = styled.figure`
  position: relative;
  background-color: var(--bg-200);
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding: var(--spacing-md) 0;
  box-shadow: var(--shadow-medium);
  border-radius: 4px;
  cursor: pointer;
  border-bottom: 1px solid var(--bg-300);
  border-right: 1px solid var(--bg-300);

  @media (min-width: 768px) {
    width: 400px;
  }
`;
const WrapperTop = styled.div`
  width: 96%;
  font-size: var(--font-size-large);
  border-bottom: 1px solid var(--bg-300);
  padding-bottom: var(--spacing-xs);
`;
const WrapperMid = styled.div`
  width: 96%;
`;
const WrapperBottom = styled.div`
  width: 96%;
  font-size: var(--font-size-large);
  border-top: 1px solid var(--bg-300);
  padding-top: var(--spacing-xs);
  display: flex;
  justify-content: space-between;
`;

const PersonalInfoCard = () => {
  const { t } = useTranslation();
  const { userProfile } = useContext(AuthUserContext);
  const navigate = useNavigate();
  const { goToProfile } = useNavigation();

  return (
    <>
      <h2 style={{ color: "var(--text-100)" }}>
        {t("PERSONAL_INFO_CARD.TITLE")}
      </h2>
      <Container>
        <WrapperTop>{t("PERSONAL_INFO_CARD.CONTACT_INFO")}</WrapperTop>
        <WrapperMid>
          <p>
            {userProfile.first_name} {userProfile.last_name}
          </p>
          <p>{userProfile.email}</p>
          <p>{userProfile.phone_number}</p>
        </WrapperMid>
        <WrapperBottom>
          <Link to={goToProfile()}>{t("BUTTONS.EDIT")}</Link>
        </WrapperBottom>
      </Container>
    </>
  );
};

export default PersonalInfoCard;
