import Footer from "../../layouts/footer/Footer";
import Header from "../../layouts/header/Header";
import RegisterMain from "../../layouts/main/account/RegisterMain";

const Login = () => {
  return (
    <div>
      <Header />
      <RegisterMain />
      {/* <Footer /> */}
    </div>
  );
};

export default Login;
