export const DOMAIN = "snusco.com";
export const DEFAULT_LANGUAGE = "en";

export const convertCurrency = (price, fromCurrency, toCurrency) => {
  if (fromCurrency === toCurrency) {
    // Nema potrebe za konverzijom ako su valute iste
    return price;
  }

  let exchangeRate = 1; // Postavite početni kurs razmene

  // Direktan kurs razmene iz EUR u USD i obrnuto
  const rates = {
    "EUR->USD": 1.1,
    "USD->EUR": 1 / 1.1,
  };

  // Formiranje ključa za pristup odgovarajućem kursu razmene
  const rateKey = `${fromCurrency}->${toCurrency}`;

  // Ako postoji definisan kurs razmene za dati par valuta, koristite ga
  if (rates[rateKey]) {
    exchangeRate = rates[rateKey];
  }

  // Vršite konverziju cene koristeći odabrani kurs razmene
  let convertedPrice = price * exchangeRate;

  // Vraćanje konvertovane cene bez dodatnog zaokruživanja
  // Ovo može pomoći u smanjenju akumulacije grešaka prilikom višestrukih konverzija
  return convertedPrice;
};
export const currencyTags = {
  USD: "$",
  EUR: "€",
};
export const DEFAULT_CURRENCY = "EUR";

export const PRODUCT_CHUNK = 30; // koliko se prozivoda prikazuje odjednom

//CHECKOUT
export const paymentMethods = [
  { name: "Cash on Delivery" },
  { name: "Credit Card" },
  //{ name: "Paypal" },
];

export const transportMethods = [
  { name: "DHL Standard", price: 8.9, days: 3 },
  { name: "Post - AG", price: 8.9, days: 3 },
  { name: "DHL Express Saver", price: 19.9, days: 1 },
];

export const freeShippingThreshold = 200; // Prag za besplatnu dostavu

export const getShippingCostPrice = (fromCurrency, toCurrency) => {
  const price = convertCurrency(20, fromCurrency, toCurrency);
  return price;
};

export const languages = [
  {
    code: "de",
    label: "Deutsch",
    flag: require("../assets/icons/austria.svg").default,
  },
  {
    code: "en",
    label: "English",
    flag: require("../assets/icons/united-kingdom.svg").default,
  },
];

export const featured_categories = [
  { name: "Pablo" },
  { name: "Zyn" },
  { name: "Velo" },
];
export const adminEmailList = [
  "djuricm13111@gmail.com",
  "djuricm888@gmail.com",
];
