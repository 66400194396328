import React, { useState, useEffect, useContext } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import styled from "styled-components";

// Importuj ikonice
import visaIcon from "../../assets/icons/visa.svg";
import masterCardIcon from "../../assets/icons/mastercard.svg";
import amexIcon from "../../assets/icons/amex.svg";
import defaultCardIcon from "../../assets/icons/default-card.svg";
import { useTranslation } from "react-i18next";

const CreditCardPaymentForm = () => {
  const { t } = useTranslation();
  const [cardType, setCardType] = useState("");

  const handleCardNumberChange = (event) => {
    setCardType(event.brand || "");
  };

  const getCardIcon = () => {
    switch (cardType) {
      case "visa":
        return visaIcon;
      case "mastercard":
        return masterCardIcon;
      case "amex":
        return amexIcon;
      default:
        return defaultCardIcon;
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#333",
        fontSize: "16px",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#aaa",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <PaymentFormContainer>
      <PaymentFormWrapper>
        <form>
          <FieldContainer>
            <Label htmlFor="card-number">{t("STRIPE.CARD_NUMBER_LABEL")}</Label>
            <CardInputContainer>
              <StyledCardNumberElement
                id="card-number"
                options={cardElementOptions}
                onChange={handleCardNumberChange}
              />
              <IconContainer>
                <CardIcon src={getCardIcon()} alt="Card Icon" />
              </IconContainer>
            </CardInputContainer>
          </FieldContainer>

          <Row>
            <FieldContainer style={{ flex: 1 }}>
              <Label htmlFor="card-expiry">
                {t("STRIPE.CARD_EXPIRY_LABEL")}
              </Label>
              <StyledCardExpiryElement
                id="card-expiry"
                options={cardElementOptions}
              />
            </FieldContainer>

            <FieldContainer style={{ flex: 1 }}>
              <Label htmlFor="card-cvc">{t("STRIPE.CARD_CVC_LABEL")}</Label>
              <StyledCardCvcElement
                id="card-cvc"
                options={cardElementOptions}
              />
            </FieldContainer>
          </Row>
        </form>
      </PaymentFormWrapper>
    </PaymentFormContainer>
  );
};

export default CreditCardPaymentForm;

// Styled Components
const PaymentFormContainer = styled.div`
  width: 100%;
  align-self: flex-start;
  border: 1px solid var(--primary-200);
  //background-color: var(--bg-200);
  //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @media (min-width: 768px) {
    width: 50%;
    max-width: 400px;
  }

  margin: 10px 0;
  border-radius: 8px;
  background-color: var(--bg-200);
`;
const PaymentFormWrapper = styled.div`
  padding: 20px;
  @media (min-width: 768px) {
    padding: 20px 30px;
  }
`;

const FieldContainer = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
  color: #555;
`;

const CardInputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 10px;
  background-color: var(--bg-100);
  border-radius: 8px;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const CardIcon = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledCardNumberElement = styled(CardNumberElement)`
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledCardExpiryElement = styled(CardExpiryElement)`
  padding: 12px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: var(--bg-100);
  border-radius: 8px;
`;

const StyledCardCvcElement = styled(CardCvcElement)`
  padding: 12px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: var(--bg-100);
  border-radius: 8px;
`;

const PayButton = styled.button`
  width: 100%;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const PaymentMessage = styled.div`
  margin-top: 15px;
  text-align: center;
  color: #333;
  font-size: 14px;
`;
