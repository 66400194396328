import React from "react";
import styled from "styled-components";

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
`;

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const StepIndicator = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ isActive }) =>
    isActive ? "var(--primary-200)" : "gray"};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
  pointer-events: ${({ isClickable }) => (isClickable ? "auto" : "none")};
  z-index: 1; /* Ensure it stays above the line */
`;

const Line = styled.div`
  flex: 1;
  height: 1px;
  width: 60px;
  margin: 0 10px;
  background-color: gray;
`;

const ProgressBar = ({ currentStep, totalSteps, goToStep }) => {
  return (
    <ProgressBarContainer>
      {Array.from({ length: totalSteps }, (_, i) => (
        <StepWrapper key={i}>
          <StepIndicator
            isActive={i + 1 <= currentStep}
            isClickable={i + 1 < currentStep} // Only allow navigation to previous steps
            onClick={() => i + 1 < currentStep && goToStep(i + 1)}
          >
            {i === 0 && (
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10 11.5C11.933 11.5 13.5 9.933 13.5 8C13.5 6.067 11.933 4.5 10 4.5C8.067 4.5 6.5 6.067 6.5 8C6.5 9.933 8.067 11.5 10 11.5ZM10 6.5C10.8284 6.5 11.5 7.17157 11.5 8C11.5 8.82843 10.8284 9.5 10 9.5C9.17157 9.5 8.5 8.82843 8.5 8C8.5 7.17157 9.17157 6.5 10 6.5Z"
                  fill="var(--bg-100)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.5 8.12313C2.5 12.3656 6.88183 19.5 10 19.5C13.1182 19.5 17.5 12.3656 17.5 8.12313C17.5 3.91715 14.1464 0.5 10 0.5C5.85362 0.5 2.5 3.91715 2.5 8.12313ZM15.5 8.12313C15.5 11.4027 11.7551 17.5 10 17.5C8.24487 17.5 4.5 11.4027 4.5 8.12313C4.5 5.0134 6.96668 2.5 10 2.5C13.0333 2.5 15.5 5.0134 15.5 8.12313Z"
                  fill="var(--bg-100)"
                />
              </svg>
            )}
            {i == 1 && (
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 3.66333L20.25 7.7398V17.01L12 21.0865L3.75 17.01V7.7398L12 3.66333ZM5.25 9.41292V16.078L11.25 19.0427V12.3776L5.25 9.41292ZM12.75 12.3776V19.0427L18.75 16.078V9.41292L16.5 10.5247V13.4999L15 14.2499V11.2659L12.75 12.3776ZM17.807 8.20577L15.8527 9.17139C15.8099 9.13606 15.7624 9.10498 15.7106 9.07908L10.1015 6.27454L12 5.33645L17.807 8.20577ZM8.41452 7.1081L14.1871 9.9944L12 11.0751L6.19304 8.20577L8.41452 7.1081Z"
                  fill="var(--bg-100)"
                />
              </svg>
            )}
            {i == 2 && (
              <svg
                fill="var(--bg-100)"
                width="26px"
                height="26px"
                viewBox="0 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>credit-card</title>
                <path d="M28 5.25h-24c-1.518 0.002-2.748 1.232-2.75 2.75v16c0.002 1.518 1.232 2.748 2.75 2.75h24c1.518-0.002 2.748-1.232 2.75-2.75v-16c-0.002-1.518-1.232-2.748-2.75-2.75h-0zM2.75 10.75h26.5v2.5h-26.5zM4 6.75h24c0.69 0.001 1.249 0.56 1.25 1.25v1.25h-26.5v-1.25c0.001-0.69 0.56-1.249 1.25-1.25h0zM28 25.25h-24c-0.69-0.001-1.249-0.56-1.25-1.25v-9.25h26.5v9.25c-0.001 0.69-0.56 1.249-1.25 1.25h-0zM10 21.25h-4c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h4c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0zM20 21.25h-6c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h6c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0z"></path>
              </svg>
            )}
          </StepIndicator>
          {i < totalSteps - 1 && <Line />}
        </StepWrapper>
      ))}
    </ProgressBarContainer>
  );
};

export default ProgressBar;
