import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import ProductMain from "../layouts/main/ProductMain";
import { ProductContext } from "../context/ProductContext";
import Footer from "../layouts/footer/Footer";
import Header from "../layouts/header/Header";
import ProductSlider from "../components/product/ProductSlider";
import { DEFAULT_CURRENCY, transportMethods } from "../utils/global_const";
import ProductSliderHorizontal from "../components/product/ProductSliderHorizontal";
const Container = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
`;
const RecommendedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const RecommendedWrapper = styled.div`
  margin-bottom: 20px;
  width: 94%;
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
`;

const Product = () => {
  const { t, i18n } = useTranslation();
  const { recommendedProducts, product, currencyTag } =
    useContext(ProductContext);
  const [currencyCode, setCurrencyCode] = useState(
    localStorage.getItem("currency") || DEFAULT_CURRENCY
  );
  const generateProductUrl = () => {
    if (!product) return "https://www.snusco.com";
    const categorySlug = product?.category_name
      ?.toLowerCase()
      .replace(/ /g, "-");
    const productSlug = product?.name?.toLowerCase().replace(/ /g, "-");
    return `https://www.snusco.com/${i18n.language}/${categorySlug}-${productSlug}`;
  };
  const productUrl = generateProductUrl();
  return (
    <Container>
      <Helmet>
        <html lang={i18n.language} />
        <title>
          {product?.seo_data?.title ||
            product?.category_name + " " + product?.name}
        </title>
        <meta name="description" content={product?.seo_data.meta_description} />
        <meta name="keywords" content={product?.seo_data.keywords} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={product?.seo_data.title} />
        <meta
          property="og:description"
          content={product?.seo_data.og_description}
        />
        <meta property="og:type" content="product" />

        {/* Open Graph slike - Primarna slika na prvom mestu */}
        <meta
          property="og:image"
          content={product?.seo_data?.og_image || product?.images?.[0]?.large}
        />

        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="Snus Österreich" />
        <link rel="canonical" href={productUrl} />
        {/* JSON-LD (Structured Data) */}
        {product && (
          <script type="application/ld+json">
            {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "${
              product?.seo_data?.title ||
              product?.category_name + " " + product?.name
            }",
            "description": "${product?.seo_data.og_description}",
            "sku": "${product?.sku}",
            "brand": {
              "@type": "Brand",
              "name": "${product?.category_name}"
            },
            "offers": {
              "@type": "Offer",
              "priceCurrency": "${currencyCode}",
              "price": "${product?.price}",
              "itemCondition": "https://schema.org/NewCondition",
              "availability": "https://schema.org/InStock",
              "url": "${productUrl}",
              "shippingDetails": {
                "@type": "OfferShippingDetails",
                "shippingRate": {
                  "@type": "MonetaryAmount",
                  "value": "${transportMethods[0].price}",
                  "currency": "${currencyCode}"
                },
                "shippingDestination": {
                  "@type": "DefinedRegion",
                  "addressCountry": "AT"
                },
                "shippingMethod": "${transportMethods[0].name}"
              }
            },
            "image": "${
              product?.seo_data?.og_image || product?.images?.[0]?.large
            }"
          }
          `}
          </script>
        )}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={product?.seo_data?.title} />
        <meta
          name="twitter:description"
          content={product?.seo_data?.og_description}
        />
        <meta
          name="twitter:image"
          content={product?.seo_data?.og_image || product?.images?.[0]?.large}
        />
        <link rel="alternate" hreflang={i18n.language} href={productUrl} />
        <link rel="alternate" hreflang="x-default" href={productUrl} />
      </Helmet>
      <Header />
      <ProductMain />
      <RecommendedContainer>
        <RecommendedWrapper>
          <h2 style={{ margin: "20px 0 0 0", textTransform: "uppercase" }}>
            {t("PRODUCT.RECOMMENDED_PRODUCTS")}
          </h2>
          <ProductSliderHorizontal products={recommendedProducts} />
        </RecommendedWrapper>
      </RecommendedContainer>

      <Footer />
    </Container>
  );
};

export default Product;
