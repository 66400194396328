import React, { useContext, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import styled from "styled-components";
import Spinner from "../animations/Spinner"; // Pretpostavimo da već imaš Spinner komponentu
import APIService from "../../services/APIService";
import { AuthUserContext } from "../../context/AuthUserContext";
import { useTranslation } from "react-i18next";

const CreditCardButton = ({ clientSecret, handleOrderSubmission }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState("");
  const { authTokens, selectedCard } = useContext(AuthUserContext);

  const handleClick = async () => {
    if (!stripe || !elements || !clientSecret) {
      setMessage("Stripe is not loaded or client secret is missing.");
      return;
    }

    setIsProcessing(true);
    setMessage("");

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      selectedCard
        ? { payment_method: selectedCard }
        : {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
          }
    );

    if (error) {
      setMessage(`Payment failed: ${error.message}`);
    } else if (paymentIntent.status === "succeeded") {
      setMessage("Payment succeeded!");

      const paymentMethodId = paymentIntent.payment_method;

      if (paymentMethodId) {
        console.log("Saving Payment Method ID:", paymentMethodId);
        await APIService.SavePaymentDetails(paymentMethodId, authTokens.access);
      } else {
        console.log("Payment Method ID not available.");
      }
      await handleOrderSubmission();
    }

    setIsProcessing(false);
  };

  return (
    <Container>
      <StyledPayButton onClick={handleClick} disabled={isProcessing || !stripe}>
        {isProcessing ? <Spinner /> : t("BUTTONS.PAY_NOW")}
      </StyledPayButton>
      {message && <PaymentMessage>{message}</PaymentMessage>}
    </Container>
  );
};

export default CreditCardButton;

// Styled Components
const Container = styled.div`
  width: 50%;
  align-self: flex-start;
`;
const StyledPayButton = styled.button`
  width: 100%;
  margin: 14px 0;

  opacity: ${(props) =>
    props.disabled ? 0.5 : 1}; /* Stilizacija za disabled stanje */

  &:disabled {
    cursor: not-allowed; /* Prikazuje da je dugme onemogućeno */
  }
`;

const PaymentMessage = styled.div`
  margin-top: 15px;
  color: #555;
  text-align: center;
  font-size: 14px;
`;
