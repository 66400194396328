import Lottie from "react-lottie";
import animationData from "../../assets/animations/page-loading.json";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 300px;
  overflow: hidden;
`;
const Wrapper = styled.div`
  width: 100%;
  @media (min-width: 767px) {
    max-width: 1000px;
  }
`;

const PageLoading = () => {
  const defaultOptions = {
    loop: true, // Promenjeno na false kako bi se animacija izvršila samo jednom
    autoplay: true, // Počinje automatski
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Container>
      <Wrapper>
        <Lottie options={defaultOptions} />
      </Wrapper>
    </Container>
  );
};

export default PageLoading;
