import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { useSelector } from "react-redux";
import CartProduct from "./CartProduct";
import {
  DEFAULT_CURRENCY,
  freeShippingThreshold,
  getShippingCostPrice,
} from "../../../utils/global_const";
import { ProductContext } from "../../../context/ProductContext";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "../../../utils/navigation";
import { useTranslation } from "react-i18next";

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
`;
const slideInD = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
`;

const slideOutD = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;
const ContainerX = styled.div`
  position: relative;
`;
const Container = styled.div`
  margin-top: var(--spacing-xxs);
  background-color: transparent;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--zindex-default);
  min-width: 100%;
  height: 100vh;

  box-sizing: border-box;
  overflow-x: hidden;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  transition: opacity 400ms ease-in-out;
  visibility: ${(props) => (props.$shouldBeVisible ? "visible" : "hidden")};
`;
const Wrapper = styled.div`
  z-index: calc(var(--zindex-default) + 1);
  background-color: var(--bg-100);

  width: 100%;
  height: 100%;
  min-height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  visibility: ${(props) => (props.$shouldBeVisible ? "visible" : "hidden")};
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${slideIn} 0.4s ease-out forwards; // Duže trajanje za slide-in
        `
      : css`
          animation: ${slideOut} 0.1s ease-in forwards; // Kraće trajanje za slide-out
        `}
  @media (min-width: 768px) {
    position: absolute;
    right: 0%;
    top: calc(100% + 10px);
    width: 400px;
    height: auto;
    min-height: auto;
    //min-height: 200px;
    box-shadow: var(--shadow-large);
    ${({ $isOpen }) =>
      $isOpen
        ? css`
            animation: ${slideInD} 0.4s ease-out forwards; // Duže trajanje za slide-in
          `
        : css`
            animation: ${slideOutD} 0.1s ease-in forwards; // Kraće trajanje za slide-out
          `};
  }
`;

const CartContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  //width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  @media (min-width: 768px) {
    //width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
  svg {
    fill: var(--text-200);
    transition: fill 0.4s ease; /* Dodaj tranziciju za glatki prelaz boje */
  }

  &:hover svg {
    fill: var(--primary-100); /* Boja pri hoveru */
  }
  &:hover h4 {
    color: var(--primary-100);
  }
`;
const CartText = styled.h4`
  display: none;
  cursor: pointer;
  @media (min-width: 768px) {
    display: inline-block;
  }

  font-weight: 200;
`;
const SumQuantity = styled.div`
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -4px;

  background-color: var(--primary-200);
  color: var(--bg-100);
  border-radius: var(--border-radius-large);
  padding: 4px 2px;
  margin: 0;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-small);
  font-weight: 200;
`;
const Span = styled.span`
  font-weight: 500;
  font-size: var(--font-size-small);
`;
const XDiv = styled.div`
  padding: 0 var(--spacing-md);
  cursor: pointer;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-md);
  background-color: var(--bg-200);
  color: var(--text-200);
`;
const MiddleDiv = styled.div`
  max-height: calc(100vh - var(--navbar-height) * 3 - 30px);
  @media (min-width: 768px) {
    max-height: 400px;
  }
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--text-200);
    border-radius: 5px;
  }
`;

const Logo = styled.div`
  color: var(--text-color);

  font-size: var(--font-size-xlarge);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.438px;
  display: flex;
  gap: var(--spacing-xs);

  align-items: center;
  cursor: pointer;
`;
const IconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--navbar-height) * 2);
  border-top: 1px solid var(--bg-300);
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.15);
  background-color: var(--bg-200);
  @media (min-width: 768px) {
    position: static;
  }
`;

const BottomWrapper = styled.div`
  width: 96%;
  height: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;
const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Button = styled.button`
  text-transform: uppercase;
  height: 100%;
`;

const CartMenu = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [shouldBeVisible, setShouldBeVisible] = useState(false);
  const [paddingTop, setPaddingTop] = useState(20);
  const { currencyTag } = useContext(ProductContext);
  const navigate = useNavigate();
  const { goToCheckout } = useNavigation();

  useEffect(() => {
    if (isOpen) {
      setShouldBeVisible(true);
    } else {
      const timer = setTimeout(() => {
        setShouldBeVisible(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const cartItems = useSelector((state) => state.cart.itemsList);
  let totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const subtotal = cartItems.reduce(
    (total, item) => total + item.product.discount_price * item.quantity,
    0
  );
  const subtotalRounded = parseFloat(subtotal.toFixed(2));
  const savings = cartItems.reduce(
    (totalSavings, item) =>
      totalSavings +
      (item.product.price - item.product.discount_price) * item.quantity,
    0
  );
  const savingsRounded = parseFloat(savings.toFixed(2));
  const [shippingCost, setShippingCost] = useState(0.0);
  const currency = localStorage.getItem("currency") || DEFAULT_CURRENCY;
  useEffect(() => {
    if (freeShippingThreshold > subtotalRounded) {
      setShippingCost(getShippingCostPrice(currency, DEFAULT_CURRENCY));
    } else {
      setShippingCost(0);
    }
  }, [subtotalRounded]);

  const grandTotal = subtotalRounded + shippingCost;
  const grandTotalRounded = parseFloat(grandTotal.toFixed(2));

  return (
    <ContainerX>
      <CartContainer onClick={toggleMenu}>
        <svg
          fill="var(--text-100)"
          width="30px"
          height="30px"
          viewBox="0 0 32 32"
          id="Outlined"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title />

          <g id="Fill">
            <path d="M23.78,11,22.35,5.27A3,3,0,0,0,19.44,3H12.56A3,3,0,0,0,9.65,5.27L8.22,11H2.16L6.09,26.73A3,3,0,0,0,9,29H23a3,3,0,0,0,2.91-2.27L29.84,11ZM15,21v6H12.83L11.7,21Zm-3.67-2L10.2,13H15v6ZM17,21h3.3l-1.13,6H17Zm0-2V13h4.8l-1.13,6Zm6.83-6h3.45l-1.5,6H22.7ZM11.59,5.76a1,1,0,0,1,1-.76h6.88a1,1,0,0,1,1,.76L21.72,11H10.28ZM8.17,13,9.3,19H6.22l-1.5-6ZM8,26.24,6.72,21h3l1.12,6H9A1,1,0,0,1,8,26.24Zm15.94,0A1,1,0,0,1,23,27H21.21l1.12-6h3Z" />
          </g>
        </svg>
        {totalQuantity !== 0 && <SumQuantity>{totalQuantity}</SumQuantity>}
        <CartText>{t("CART.TITLE")}</CartText>
      </CartContainer>

      <Container
        onClick={toggleMenu}
        $isOpen={isOpen}
        $shouldBeVisible={shouldBeVisible}
      />
      <Wrapper
        $isOpen={isOpen}
        $shouldBeVisible={shouldBeVisible}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <XDiv>
          <Logo>
            {totalQuantity} {t("CART.ITEMS")}
          </Logo>
          <IconDiv>
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
              onClick={toggleMenu}
            >
              <path
                fill="var(--text-200)"
                d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
              />
            </svg>
          </IconDiv>
        </XDiv>
        <MiddleDiv $paddingTop={`${paddingTop}px`}>
          {cartItems.map((item, index) => (
            <CartProduct item={item} key={index} />
          ))}
        </MiddleDiv>
        <Bottom>
          <BottomWrapper>
            <FlexDiv>
              <h3>{t("CART.SHOPPING_BASKET")}</h3>
              <h3>
                {currencyTag}
                {grandTotalRounded}
              </h3>
            </FlexDiv>
            <FlexDiv>
              <p style={{ padding: "0", margin: "0" }}>{t("CART.VAT")}</p>
              <p style={{ padding: "0", margin: "0" }}>{t("CART.INCLUDED")}</p>
            </FlexDiv>
            <Button
              onClick={() => navigate(goToCheckout())}
              disabled={totalQuantity == 0}
            >
              {t("CART.CHECKOUT")}
            </Button>
          </BottomWrapper>
        </Bottom>
      </Wrapper>
    </ContainerX>
  );
};

export default CartMenu;
