import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import HamburgerMenuData from "./HamburgerMenuData";
import Language from "../../../components/account/Language";

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
`;

const slideOutLeft = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
`;
const ContainerX = styled.div``;
const Container = styled.div`
  margin-top: var(--spacing-xxs);
  background-color: transparent;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--zindex-default);
  min-width: 100%;
  height: 100vh;

  box-sizing: border-box;
  overflow-x: hidden;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  visibility: ${(props) => (props.$shouldBeVisible ? "visible" : "hidden")};
`;
const Wrapper = styled.div`
  background-color: var(--bg-100);

  width: 90%;
  height: 100%;
  min-height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${slideInFromLeft} 0.5s ease-out forwards; // Duže trajanje za slide-in
        `
      : css`
          animation: ${slideOutLeft} 0.2s ease-in forwards; // Kraće trajanje za slide-out
        `}
  @media (min-width: 768px) and (max-width: 1040px) {
    width: 60%;
  }
`;
// Styled komponenta za hamburger linije
const HamburgerIcon = styled.div`
  width: var(--font-size-xlarge);
  height: 3px;
  background-color: var(--text-200);
  transition: all 0.5s ease-in-out;
  transform-origin: 1px;

  &:nth-child(1) {
    transform: ${({ $isOpen }) => ($isOpen ? "rotate(45deg)" : "rotate(0)")};
    width: 25px;
  }

  &:nth-child(2) {
    transform: ${({ $isOpen }) =>
      $isOpen ? "translateX(100%)" : "translateX(0)"};
    opacity: ${({ $isOpen }) => ($isOpen ? 0 : 1)};
  }

  &:nth-child(3) {
    transform: ${({ $isOpen }) => ($isOpen ? "rotate(-45deg)" : "rotate(0)")};
    width: 25px;
  }
`;
const HamburgerMidIcon = styled(HamburgerIcon)`
  width: calc(var(--font-size-xlarge) - 5px);
  height: 2.8px;
`;

// Styled komponenta za kontejner koji drži hamburger ikonu
const HamburgerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 25px;
  cursor: pointer;
`;
const Span = styled.span`
  font-weight: 500;
  font-size: var(--font-size-small);
`;
const XDiv = styled.div`
  padding: 0 var(--spacing-md);
  cursor: pointer;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-md);
`;
const MiddleDiv = styled.div``;

const Logo = styled.div`
  color: var(--text-color);

  font-size: var(--font-size-xlarge);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.438px;
  display: flex;
  gap: var(--spacing-md);

  align-items: center;
  cursor: pointer;
`;
const IconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HamburgerMenuLeft = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldBeVisible, setShouldBeVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShouldBeVisible(true);
    } else {
      const timer = setTimeout(() => {
        setShouldBeVisible(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ContainerX>
      <HamburgerContainer onClick={toggleMenu}>
        <HamburgerIcon key="1" $isOpen={isOpen} />
        <HamburgerMidIcon key="2" $isOpen={isOpen} />
        <HamburgerIcon key="3" $isOpen={isOpen} />
      </HamburgerContainer>

      <Container
        onClick={toggleMenu}
        $isOpen={isOpen}
        $shouldBeVisible={shouldBeVisible}
      >
        <Wrapper
          $isOpen={isOpen}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <XDiv>
            <Language />
            <IconDiv>
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                onClick={toggleMenu}
              >
                <path
                  fill="var(--text-200)"
                  d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                />
              </svg>
            </IconDiv>
          </XDiv>
          <MiddleDiv>
            <HamburgerMenuData setIsOpen={setIsOpen} />
          </MiddleDiv>
        </Wrapper>
      </Container>
    </ContainerX>
  );
};

export default HamburgerMenuLeft;
