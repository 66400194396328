import { useContext, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ProductContext } from "../context/ProductContext";
import HomeMain from "../layouts/main/HomeMain";

import { Helmet } from "react-helmet-async";

const Container = styled.article`
  color: ${(props) => props.theme.textColor};
`;
const Section = styled.section`
  height: 100vh;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const Home = () => {
  const { t, i18n } = useTranslation();
  const { loadProducts } = useContext(ProductContext);

  useEffect(() => {
    loadProducts();
  }, [i18n.language]);

  const seoData = {
    en: {
      title: "Buy Nicotine Pouches | SnusCo",
      description:
        "Explore a wide selection of nicotine pouches snus with bold flavors and varying nicotine strengths at SnusCo. Fast delivery, premium quality, and great prices!",
      keywords:
        "nicotine pouches, snus, buy nicotine pouches, strong snus, nicotine snus, fresh flavors, SnusCo shop, nicotine pouches delivery",
      ogTitle: "Buy Nicotine Pouches | SnusCo",
      ogDescription:
        "Buy premium nicotine pouches snus with intense flavors and multiple nicotine strengths. Fast and reliable delivery in Austria, Germany, and worldwide!",
      twitterTitle: "Buy Nicotine Pouches | SnusCo",
      twitterDescription:
        "Discover strong and refreshing nicotine pouches snus at SnusCo. Fast delivery to Austria, Germany, and beyond. Order now for top-quality nicotine pouches!",
      url: "https://www.snusco.com/en/",
      image: "https://www.snusco.com/assets/snuspouch.jpg",
      locale: "en",
    },
    de: {
      title: "Nikotinsäcke Snus kaufen | SnusCo",
      description:
        "Entdecken Sie eine große Auswahl an Nikotinsäcken Snus mit kräftigen Aromen und verschiedenen Nikotinstärken bei SnusCo. Schnelle Lieferung, hochwertige Qualität und faire Preise!",
      keywords:
        "Nikotinsäcke, Snus, Nikotinbeutel kaufen, starker Snus, Nikotinsnus, frische Aromen, SnusCo Shop, Nikotinbeutel Lieferung",
      ogTitle: "Nikotinsäcke Snus kaufen | SnusCo",
      ogDescription:
        "Kaufen Sie erstklassige Nikotinsäcke Snus mit intensiven Aromen und verschiedenen Nikotinstärken. Schnelle und zuverlässige Lieferung nach Deutschland, Österreich und weltweit!",
      twitterTitle: "Nikotinsäcke Snus kaufen | SnusCo",
      twitterDescription:
        "Entdecken Sie starke und erfrischende Nikotinsäcke Snus bei SnusCo. Schnelle Lieferung nach Deutschland, Österreich und darüber hinaus. Bestellen Sie jetzt für hochwertige Nikotinsäcke!",
      url: "https://www.snusco.com/de/",
      image: "https://www.snusco.com/assets/snuspouch.jpg",
      locale: "de",
    },
  };

  const currentSeo = seoData[i18n.language] || seoData.en;

  return (
    <>
      <Helmet>
        {/* Meta Title */}
        <title>{currentSeo.title}</title>

        {/* Meta Description */}
        <meta name="description" content={currentSeo.description} />

        {/* Meta Keywords */}
        <meta name="keywords" content={currentSeo.keywords} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={currentSeo.ogTitle} />
        <meta property="og:description" content={currentSeo.ogDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentSeo.url} />
        <meta property="og:image" content={currentSeo.image} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={currentSeo.twitterTitle} />
        <meta
          name="twitter:description"
          content={currentSeo.twitterDescription}
        />
        <meta name="twitter:image" content={currentSeo.image} />

        {/* Canonical Link */}
        <link rel="canonical" href={currentSeo.url} />

        {/* Additional Meta Tags */}
        <meta property="og:locale" content={currentSeo.locale} />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <HomeMain />
    </>
  );
};

export default Home;
