import Footer from "../../layouts/footer/Footer";
import Header from "../../layouts/header/Header";
import LoginMain from "../../layouts/main/account/LoginMain";

const Login = () => {
  return (
    <div>
      <Header />
      <LoginMain />
      {/* <Footer /> */}
    </div>
  );
};

export default Login;
