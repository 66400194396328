import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProductContext } from "../../context/ProductContext";

const Component = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const SelectedSort = styled.h2`
  border: none;

  font-size: var(--font-size-base);
  font-weight: 100;
  text-transform: capitalize;
  color: var(--text-200);
  display: flex;
  gap: 14px;
  cursor: pointer;
  white-space: nowrap;
  background-color: var(--bg-200);
  border: 1px solid var(--bg-300);
  //padding: var(--spacing-sm) var(--spacing-md);
  @media (min-width: 768px) {
    background-color: var(--bg-200);
    //border: none;
  }
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OptionsContainer = styled.div`
  background-color: var(--bg-100);
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  display: ${(props) => (props.$isOpen ? "block" : "none")};
  @media (min-width: 768px) {
    //z-index: 1;
    position: absolute;
    width: 200px;
    top: 110%;
    right: 0%;
    z-index: 9;
  }
`;
const RadioButton = styled.div`
  cursor: pointer;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 20px;
  border: 1px solid var(--text-200);
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    display: none;
  }
`;
const CheckedRadio = styled.div`
  background-color: var(--primary-100);
  width: 50%;
  height: 50%;
  border-radius: 20px;
`;
const Option = styled.div`
  padding: var(--spacing-sm) var(--spacing-xs);
  text-transform: capitalize;
  white-space: nowrap;
  background-color: var(--bg-200);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  @media (min-width: 768px) {
    border-bottom: ${(props) =>
      props.selected ? "2px solid var(--primary-100)" : "none"};
    box-shadow: var(--shadow-large);
    &:hover {
      background-color: var(--primary-100);
      color: var(--bg-100);
    }
  }
`;
const OpenSortTitle = styled.div`
  text-transform: uppercase;
  padding: var(--spacing-md) var(--spacing-xs);
  border-bottom: 1px solid var(--bg-300);
  @media (min-width: 768px) {
    display: none;
  }
`;
const MaskContainer = styled.div`
  background-color: #0000003a;
  position: fixed;
  display: ${(props) => (props.$isOpen ? "block" : "none")};
  right: 0;
  top: 0;
  z-index: 1;
  min-width: 100%;
  height: 100vh;
  z-index: 9999;
  @media (min-width: 768px) {
    display: none;
  }
`;

const ShopSort = () => {
  const { t } = useTranslation();
  const items = [
    // "date",
    // "strength",
    // "strength_desc",
    "name",
    "price_asc",
    "price_desc",
    //"best_sellers",
  ];
  const [selectedSort, setSelectedSort] = useState("");
  let { sortProducts, products } = useContext(ProductContext);
  const [isOpen, setIsOpen] = useState(false);

  // useEffect hook to handle sorting
  useEffect(() => {
    // Check if 'price_desc' is selected and set 'isAscending' accordingly
    if (selectedSort === "price_desc") {
      sortProducts(products, "price", false); // Sort by price, descending
    } else if (selectedSort === "price_asc") {
      sortProducts(products, "price", true); // Sort by price, ascending
    } else if (selectedSort === "strength_desc") {
      sortProducts(products, "strength", false); // Sort by price, ascending
    } else {
      sortProducts(products, selectedSort); // Sort by other criteria
    }
  }, [selectedSort]);

  const toggleSort = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Component>
      <SelectedSort onClick={toggleSort}>{t("SHOP_SORT.SORT")}</SelectedSort>

      <OptionsContainer $isOpen={isOpen}>
        <OpenSortTitle>{t("SHOP_SORT.SORT")}</OpenSortTitle>
        {items.map((item, index) => (
          <Option
            key={index}
            selected={item === selectedSort}
            onClick={() => {
              setSelectedSort(item);
              setIsOpen(false);
            }}
          >
            {t(`SHOP_SORT.${item.toUpperCase()}`)}
            <RadioButton>
              {item === selectedSort && <CheckedRadio />}
            </RadioButton>
          </Option>
        ))}
      </OptionsContainer>
      <MaskContainer $isOpen={isOpen} onClick={toggleSort} />
    </Component>
  );
};

export default ShopSort;
