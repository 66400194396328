// src/routes/ProtectedRoute.js
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { useNavigation } from "./utils/navigation";
import { AuthUserContext } from "./context/AuthUserContext";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const { goToLogin } = useNavigation();
  const { authTokens } = useContext(AuthUserContext);

  if (!authTokens) {
    return <Navigate to={goToLogin()} replace />;
  }
  return children;
};

const ProtectedRouteSignUpIn = ({ children }) => {
  const { goToAccount } = useNavigation();
  const { authTokens } = useContext(AuthUserContext);

  if (authTokens) {
    return <Navigate to={goToAccount()} replace />;
  }
  return children;
};

export default ProtectedRoute; // Podrazumevani eksport
export { ProtectedRouteSignUpIn }; // Imenovani eksport
