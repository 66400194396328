import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AuthUserContext } from "../../context/AuthUserContext";
import CheckoutAddresses from "../popup/CheckoutAddresses";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Wrapper = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
  }
`;
const InnerWrapper = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    flex: 1;
  }
`;

const Input = styled.input`
  width: calc(100% - 18px);
  padding: 14px 8px;
  font-size: 14px;
  border: 1px solid
    ${({ isValid }) => (isValid === false ? "var(--error-color)" : "#ccc")};
  border-radius: 8px;

  &:focus {
    border-color: ${({ isValid }) =>
      isValid === false ? "var(--error-color)" : "var(--success-color)"};
    outline: none;
  }
  //color: #cf66792c;
  background-color: ${({ isValid }) =>
    isValid === false ? "#cf66792c" : "var(--bg-200)"};
`;

const ErrorMessage = styled.span`
  color: var(--error-color);
  font-size: 12px;
  //margin-top: -12px;
  display: block;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }

  label {
    font-size: 14px;
  }
`;
const Label = styled.label`
  font-size: var(--font-size-small);
`;

const Button = styled.button`
  margin: 14px 0;
  width: 100%;
  padding: 10px 20px;
  background-color: ${({ disabled }) =>
    disabled ? "#d3d3d3" : "var(--primary-200)"};
  color: ${({ disabled }) => (disabled ? "#9e9e9e" : "var(--bg-200)")};
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? "#d3d3d3" : "var(--primary-100)"};
  }
`;
const GridRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 8px;
  justify-content: space-between;
`;
const GridRowReversed = styled(GridRow)`
  grid-template-columns: 1fr 2fr;
`;

const Step1Exists = ({ onNext }) => {
  const { t } = useTranslation();
  const { userProfile, updateAddressBook, createAddressBook } =
    useContext(AuthUserContext);
  const [showConfirm, setShowConfirm] = useState(null);

  const primaryAddress =
    userProfile.addresses?.find((address) => address.is_primary) || {};

  const [data, setData] = useState({
    user: {
      email: userProfile?.email || "",
      firstName: userProfile?.first_name || "",
      lastName: userProfile?.last_name || "",
    },
    address: {
      country: primaryAddress?.country || "",
      city: primaryAddress?.city || "",
      postal_code: primaryAddress?.postal_code || "",
      street: primaryAddress?.street || "",
      street_number: primaryAddress?.street_number || "",
      secondary_street: primaryAddress?.secondary_street || "",
      building_number: primaryAddress?.building_number || "",
      latitude: primaryAddress?.latitude || null,
      longitude: primaryAddress?.longitude || null,
      phone_number:
        primaryAddress?.phone_number || userProfile?.phone_number || "",
      is_primary: true,
    },
  });

  const handleChange = (e, section) => {
    const { name, value, type, checked } = e.target;

    setData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [name]: type === "checkbox" ? checked : value,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      let updatedOrCreatedAddress;

      // Provera da li su podaci o adresi promenjeni
      const isAddressUnchanged =
        primaryAddress?.id &&
        primaryAddress.country === data.address.country &&
        primaryAddress.city === data.address.city &&
        primaryAddress.postal_code === data.address.postal_code &&
        primaryAddress.street === data.address.street &&
        primaryAddress.street_number === data.address.street_number &&
        primaryAddress.secondary_street === data.address.secondary_street &&
        primaryAddress.building_number === data.address.building_number &&
        primaryAddress.phone_number === data.address.phone_number;

      if (isAddressUnchanged) {
        // Ako se podaci nisu promenili, preskoči API poziv
        console.log("Address unchanged. No API call needed.");
        updatedOrCreatedAddress = primaryAddress;
      } else if (primaryAddress?.id) {
        // Ako postoji adresa, ažuriraj je
        updatedOrCreatedAddress = await updateAddressBook(
          primaryAddress.id,
          data.address
        );
        console.log("Address updated successfully.");
      } else {
        // Ako adresa ne postoji, kreiraj je
        updatedOrCreatedAddress = await createAddressBook(data.address);
        console.log("Address created successfully.");
      }

      // Prosledi ID adrese na `onNext`
      if (userProfile.addresses && userProfile.addresses.length > 1) {
        setShowConfirm(updatedOrCreatedAddress);
      } else {
        onNext(updatedOrCreatedAddress);
      }
    } catch (error) {
      console.error("Error handling address:", error);
      alert("There was an error processing your address. Please try again.");
    }
  };

  return (
    <Container>
      {showConfirm && (
        <CheckoutAddresses
          chosenAddress={showConfirm}
          setShowConfirm={setShowConfirm}
          onNext={onNext}
        />
      )}
      <Wrapper>
        <InnerWrapper>
          <Row>
            <div>
              <Label>{t("LABELS.NAME")} *</Label>
              <div>{userProfile.first_name}</div>
            </div>
            <div>
              <Label>{t("LABELS.SURNAME")} *</Label>
              <div style={{ minHeight: "24px" }}>{userProfile.last_name}</div>
            </div>
          </Row>
          <div>
            <Label>{t("LABELS.PHONE_NUMBER")} *</Label>
            <Input
              type="text"
              name="phone_number"
              value={data.address.phone_number}
              onChange={(e) => handleChange(e, "address")}
              placeholder={t("PLACEHOLDERS.PHONE_NUMBER")}
              required
            />
          </div>
        </InnerWrapper>
        <InnerWrapper>
          <div>
            <Label>{t("LABELS.COUNTRY")} *</Label>
            <Input
              type="text"
              name="country"
              value={data.address.country}
              onChange={(e) => handleChange(e, "address")}
              placeholder={t("PLACEHOLDERS.COUNTRY")}
              required
            />
          </div>
          <GridRowReversed>
            <div>
              <Label>{t("LABELS.POSTAL_CODE")} *</Label>
              <Input
                type="text"
                name="postal_code"
                value={data.address.postal_code}
                onChange={(e) => handleChange(e, "address")}
                placeholder="Postal Code"
                required
              />
            </div>
            <div>
              <Label>{t("LABELS.CITY")} *</Label>
              <Input
                type="text"
                name="city"
                value={data.address.city}
                onChange={(e) => handleChange(e, "address")}
                placeholder={t("PLACEHOLDERS.CITY")}
                required
              />
            </div>
          </GridRowReversed>
          <GridRow>
            <div>
              <Label>{t("LABELS.STREET")} *</Label>
              <Input
                type="text"
                name="street"
                value={data.address.street}
                onChange={(e) => handleChange(e, "address")}
                placeholder={t("PLACEHOLDERS.STREET")}
                required
              />
            </div>
            <div>
              <Label>{t("LABELS.STREET_NUMBER")}</Label>
              <Input
                type="text"
                name="street_number"
                value={data.address.street_number}
                onChange={(e) => handleChange(e, "address")}
                placeholder={t("PLACEHOLDERS.STREET_NUMBER")}
              />
            </div>
          </GridRow>
          <GridRow>
            <div>
              <Label>{t("LABELS.SECONDARY_STREET")}</Label>
              <Input
                type="text"
                name="secondary_street"
                value={data.address.secondary_street}
                onChange={(e) => handleChange(e, "address")}
                placeholder={t("PLACEHOLDERS.SECONDARY_STREET")}
              />
            </div>
            <div>
              <Label>{t("LABELS.BUILDING_NUMBER")}</Label>
              <Input
                type="text"
                name="building_number"
                value={data.address.building_number}
                onChange={(e) => handleChange(e, "address")}
                placeholder={t("PLACEHOLDERS.BUILDING_NUMBER")}
              />
            </div>
          </GridRow>

          <Button
            onClick={handleSubmit}
            disabled={
              !data.address.phone_number ||
              !data.address.street ||
              !data.address.postal_code ||
              !data.address.city ||
              !data.address.country
            }
          >
            {t("BUTTONS.CONTINUE")}
          </Button>
        </InnerWrapper>
      </Wrapper>
    </Container>
  );
};

export default Step1Exists;
