import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import APIService from "../../services/APIService";
import { AuthUserContext } from "../../context/AuthUserContext";

import CreditCard from "./CreditCard";
import { ProductContext } from "../../context/ProductContext";
import { useSelector } from "react-redux";
import { DOMAIN } from "../../utils/global_const";
import Spinner from "../animations/Spinner";
import CreditCardPaymentForm from "./CreditCardPaymentForm";
import { useTranslation } from "react-i18next";

const SavedCards = ({ address, setClientSecret }) => {
  const { t } = useTranslation();
  const [cards, setCards] = useState([]);
  const { userProfile, authTokens, selectedCard, handleSelectCard } =
    useContext(AuthUserContext);
  const [isLoading, setIsLoading] = useState(true);

  const { shippingMethod } = useContext(ProductContext);
  const cartItems = useSelector((state) => state.cart.itemsList);

  // Calculate subtotal and grand total
  const subtotal = cartItems.reduce(
    (total, item) => total + item.product.discount_price * item.quantity,
    0
  );

  const grandTotal = Math.round((subtotal + shippingMethod.price) * 100);

  useEffect(() => {
    const billingDetails = {
      name: `${userProfile.first_name} ${userProfile.last_name}`,
      address: address.street,
      city: address.city,
      postal_code: address.postal_code,
      country: address.country,
    };

    APIService.CreatePaymentIntent(
      {
        amount: grandTotal, // amount in cents
        email: userProfile.email,
        billing_details: billingDetails,
        description: `Purchase for order ${DOMAIN}`, // Add relevant description
        phone_number: address.phone_number,
      },
      authTokens.access
    )
      .then((data) => {
        setClientSecret(data.clientSecret);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching client secret:", error);
      });
  }, [grandTotal, address, authTokens]);

  useEffect(() => {
    const fetchSavedCards = async () => {
      try {
        const data = await APIService.getSavedPaymentMethods(authTokens.access);
        setCards(data);
        if (data.length > 0) {
          handleSelectCard(data[0].id); // Automatski postavi prvu karticu
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchSavedCards();
  }, [authTokens]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <TopDiv>
        <Header>{t("CHECKOUT.CREDIT_CARDS_HEADER")}</Header>
        {cards.length > 0 && (
          <Button
            onClick={() => {
              if (selectedCard) {
                handleSelectCard(null);
              } else {
                handleSelectCard(cards[0].id);
              }
            }}
          >
            {selectedCard
              ? t("STRIPE.NEW_CARD_BUTTON")
              : t("STRIPE.CARDS_BUTTON")}
          </Button>
        )}
      </TopDiv>
      {cards.length === 0 ? (
        <CreditCardPaymentForm />
      ) : (
        <>
          {selectedCard ? (
            <CardList>
              {cards.map((card) => (
                <CreditCard card={card} setCards={setCards} key={card.id} />
              ))}
            </CardList>
          ) : (
            <CreditCardPaymentForm />
          )}
        </>
      )}
      <FlexDiv>
        <svg
          width="32px"
          height="32px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.75 8.25C9.75 7.00736 10.7574 6 12 6C13.2426 6 14.25 7.00736 14.25 8.25V9.75H9.75V8.25ZM8.25 9.75V8.25C8.25 6.17893 9.92893 4.5 12 4.5C14.0711 4.5 15.75 6.17893 15.75 8.25V9.75H17.25L18 10.5V18.75L17.25 19.5H6.75L6 18.75V10.5L6.75 9.75H8.25ZM7.5 18V11.25H16.5V18H7.5Z"
            fill="var(--accent-100)"
          />
        </svg>
        <p>{t("CHECKOUT.CARD_SECURITY_MESSAGE")}</p>
      </FlexDiv>
    </Container>
  );
};

export default SavedCards;

// Styled Components
const Container = styled.div`
  width: 100%;
`;
const TopDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-200);
`;
const Header = styled.h2`
  padding: var(--spacing-md) 2%;
  text-transform: uppercase;
  margin-bottom: 8px;
  width: 96%;
`;

const CardList = styled.div`
  margin-top: 10px;
  @media (min-width: 1025px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    max-width: 810px;
    gap: 10px;
  }
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1025px) {
    display: block;
  }
  margin-bottom: 20px;
`;
const Button = styled.button`
  min-width: 140px;
  margin-right: 2%;
`;
