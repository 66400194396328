import styled from "styled-components";
import UserMenu from "../../../components/account/UserMenu";
import { useContext, useEffect, useState } from "react";
import PersonalInfoCard from "../../../components/account/PersonalInfoCard";
import { AuthUserContext } from "../../../context/AuthUserContext";
import AddressCards from "../../../components/account/AddressCards";
import PersonalInformation from "./PersonalInformation";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
import AddressBookForm from "../../../components/account/AddressBookForm";
import SavedCards from "../../../components/account/SavedCards";
import OrderHistory from "./OrderHistory";
import { useTranslation } from "react-i18next";

const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: var(--bg-100);
  padding-top: calc(var(--navbar-height) + var(--navbar-mini));
  @media (min-width: 1040px) {
    min-height: 90vh;
    padding-top: calc(var(--navbar-height-desktop) + var(--navbar-mini));
  }
`;
const Wrapper = styled.div`
  width: 94%;

  @media (min-width: 1040px) {
    width: 100%;
    display: flex;
    position: relative; /* Ovo je potrebno za relativno pozicioniranje unutar roditelja */
    justify-content: flex-start;
  }
  @media (min-width: 1025px) {
    width: var(--max-width-container);
    padding: var(--spacing-xxl) 0;
  }
`;

const LeftContainer = styled.div`
  z-index: 9;
  @media (min-width: 1040px) {
    width: 300px;
    position: sticky;
    height: 60vh;
  }
  @media (min-width: 768px) and (max-width: 1040px) {
    width: 100%;
  }
  @media (min-width: 1025px) {
    right: calc(
      (100vw - var(--max-width-container)) / 2 + var(--max-width-container) -
        700px
    );
    top: calc(var(--navbar-height-desktop) + var(--font-size-xxlarge) + 40px);
  }
`;

const RightContainer = styled.div`
  @media (min-width: 1040px) {
    width: calc(100% - 300px);
  }
`;
const RightWrapper = styled.div`
  @media (min-width: 1040px) {
    padding: 0 var(--spacing-xxl);
  }
`;
const AccountMain = () => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState("account");
  const { userProfile } = useContext(AuthUserContext);
  const location = useLocation();
  const language = i18next.language;

  const renderContent = () => {
    if (location.pathname === `/${language}/account/profile`) {
      return <PersonalInformation />;
    } else if (location.pathname === `/${language}/account/address-book`) {
      return <AddressCards />;
    } else if (location.pathname === `/${language}/account/orders`) {
      return <OrderHistory />;
    } else if (location.pathname === `/${language}/account/saved-cards`) {
      return <SavedCards />;
    } else if (location.pathname === `/${language}/account/address-book/new`) {
      return <AddressBookForm />;
    } else if (
      location.pathname.includes(`/${language}/account/address-book/edit`)
    ) {
      return <AddressBookForm />;
    } else {
      return (
        <>
          <h1>{t("PROFILE.TITLE")}</h1>
          <PersonalInfoCard />
          <AddressCards />
        </>
      );
    }
  };

  return (
    <Container>
      <Wrapper>
        <LeftContainer>
          <UserMenu preSelection={selectedItem} />
        </LeftContainer>
        {userProfile && (
          <RightContainer>
            <RightWrapper>{renderContent()}</RightWrapper>
          </RightContainer>
        )}
      </Wrapper>
    </Container>
  );
};

export default AccountMain;
