import { useContext, useState } from "react";
import styled from "styled-components";
import { AuthUserContext } from "../../context/AuthUserContext";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  width: 100vw;
  min-height: calc(100vh - var(--navbar-height));
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #00000027;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: calc(var(--zindex-fixed) + 2);
  @media (min-width: 768px) {
    min-height: 100vh;
    z-index: 99999;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 90%;
  background-color: var(--bg-100);
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 60px 0;
  @media (min-width: 768px) {
    width: 900px;
    min-height: auto;
  }
`;

const FormContainer = styled.div`
  width: 90%;
`;

const AddressList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const AddressItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 12px;
  background-color: var(--bg-200);
`;

const RadioOuter = styled.div`
  width: 14px;
  height: 14px;
  border: 2px solid var(--primary-200);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RadioInner = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${(props) =>
    props.selected ? "var(--primary-200)" : "transparent"};
  border-radius: 50%;
`;

const AddressText = styled.h4`
  flex: 1;
  font-family: "Gudea-Regural";
  font-weight: bold;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;
const Title = styled.h3`
  font-family: "Gudea-Regural";
  text-align: center;
`;
const Subtitle = styled.h5`
  font-family: "Gudea-Regural";
  text-align: center;

  @media (min-width: 768px) {
    width: 50%;
  }
`;
const Button = styled.button`
  width: 100%;
  margin: 8px 0;
  font-size: var(--font-size-base);
  @media (min-width: 768px) {
    font-size: var(--font-size-large);
  }
`;
const CancelButton = styled.div`
  text-decoration: underline;
  font-size: var(--font-size-base);
  text-align: center;
  cursor: pointer;
  color: var(--primary-200);
  @media (min-width: 768px) {
    font-size: var(--font-size-large);
  }
`;
const ExitContainer = styled.div`
  display: block;
  position: absolute;
  top: 20px;
  cursor: pointer;
  right: 20px;
`;
const CheckoutAddresses = ({ chosenAddress, setShowConfirm, onNext }) => {
  const { t } = useTranslation();
  const { userProfile } = useContext(AuthUserContext);
  const [selectedAddress, setSelectedAddress] = useState(chosenAddress);

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
  };

  return (
    <Container>
      <Wrapper>
        <FormContainer>
          <ExitContainer
            onClick={() => {
              setShowConfirm(null);
            }}
          >
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#ccc"
                d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
              />
            </svg>
          </ExitContainer>
          <FlexDiv>
            <Title>{t("CHECKOUT_ADDRESSES.TITLE")} </Title>
            <Subtitle>{t("CHECKOUT_ADDRESSES.SUBTITLE")}</Subtitle>
          </FlexDiv>
          <AddressList>
            {userProfile?.addresses?.map((address) => (
              <AddressItem
                key={address.id}
                selected={selectedAddress === address}
                onClick={() => handleSelectAddress(address)}
              >
                <RadioOuter>
                  <RadioInner selected={selectedAddress === address} />
                </RadioOuter>
                <div>
                  {address.is_primary ? (
                    <p>{t("CHECKOUT_ADDRESSES.ORIGINAL_ADDRESS")}</p>
                  ) : (
                    <p>{t("CHECKOUT_ADDRESSES.SUGGESTED_ADDRESS")}</p>
                  )}
                  <AddressText>
                    {`${address.street} ${address.street_number}`}
                  </AddressText>
                  <AddressText>
                    {`${address.city}, ${address.postal_code}`}
                  </AddressText>
                  <AddressText>{` ${address.country}`}</AddressText>
                </div>
              </AddressItem>
            ))}
          </AddressList>
          <Button
            onClick={() => {
              onNext(selectedAddress);
            }}
          >
            {t("BUTTONS.CONTINUE")}
          </Button>
          <CancelButton
            onClick={() => {
              setShowConfirm(null);
            }}
          >
            {t("BUTTONS.CANCEL")}
          </CancelButton>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default CheckoutAddresses;
