import styled from "styled-components";
import Header from "../layouts/header/Header";
import Footer from "../layouts/footer/Footer";
import PrivacyPolicyMain from "../layouts/main/PrivacyPolicyMain";
const Container = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <PrivacyPolicyMain />
      <Footer />
    </Container>
  );
};

export default PrivacyPolicy;
