import React, { useContext, useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { AuthUserContext } from "../../context/AuthUserContext";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useNavigation } from "../../utils/navigation";
import i18next from "i18next";
const fadeInTop = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    
  }
`;
const fadeOutBottom = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;
const Container = styled.div`
  //display: ${(props) => (props.$section === "" ? "block" : "none")};
  @media (min-width: 768px) {
    display: block;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  @media (min-width: 768px) {
    //max-width: var(--max-width-container);
  }
`;
const DropDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-200);
  @media (min-width: 768px) {
    ${({ $isOpen }) =>
      $isOpen
        ? css`
            display: none; /* Sakrij ako je otvoreno */
          `
        : css`
            display: flex; /* Prikaži ako nije otvoreno */
            align-items: center;
            justify-content: center;
            background-color: var(--primary-200);
          `}
  }
`;

const Box = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    ${({ $isOpen }) =>
      $isOpen
        ? css`
            animation: ${fadeOutBottom} 0.2s ease-in-out both;
          `
        : css`
            animation: ${fadeInTop} 0.3s ease-in-out both;
          `}
  }
`;
const GridContainer = styled.div`
  min-width: 100%;
  overflow-x: hidden;
`;
const GridItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  border: 1px solid #e5e5e5;
  margin: var(--spacing-xs) 0;
`;
const ButtonWrapper = styled.div`
  box-shadow: var(--shadow-small);
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Button = styled.button`
  background-color: ${(props) =>
    props.$active ? "rgba(86, 147, 191, 0.2 )" : "var(--bg-200)"};

  color: ${(props) => (props.$active ? "var(--bg-100)" : "var(--text-200)")};
  border: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-md);
  width: 100%;
  font-size: var(--font-size-base);
  font-weight: 400;

  text-transform: uppercase;
  padding: 12px;
  &:hover {
    background-color: var(--primary-100);
    color: var(--bg-100);
  }
`;
const SVGIcon = styled.svg`
  background-color: var(--accent-200);
  padding: 4px;
  border-radius: 50%;
`;
const Arrow = styled.svg`
  justify-self: right;
  @media (min-width: 768px) {
    //display: none;
  }
`;
const SmallButton = styled(Button)`
  text-transform: capitalize;
  &:hover {
    background-color: var(--primary-100);
    color: var(--bg-100);
  }
  background-color: ${(props) =>
    props.$active ? "var(--bg-300)" : "var(--bg-200)"};
  color: ${(props) => (props.$active ? "var(--text-100)" : "var(--text-200)")};
`;

const ConfirmContainer = styled.div`
  margin-top: var(--spacing-xs);
  background-color: #00000031;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const ConfirmWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  background-color: #1a2c38;
  background-color: var(--bg-200);
  position: relative;
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;

  padding: 60px 0;
  width: 94%;
  border-radius: 8px;
  @media (min-width: 768px) {
    width: 500px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-xl);
`;

const ButtonTransparent = styled.button`
  padding: var(--spacing-sm) var(--spacing-lg);

  cursor: pointer;
  color: var(--primary-200);
  background-color: var(--bg-100);
  &:hover {
    color: var(--primary-100);
    background-color: var(--bg-200);
  }
`;
const ButtonConfirm = styled(ButtonTransparent)`
  color: var(--error-color);
  background-color: var(--bg-100);
  border-color: var(--error-color);
  &:hover {
    color: var(--bg-100);
    background-color: var(--error-color);
  }
`;
const UserMenu = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { userProfile, logoutUser } = useContext(AuthUserContext);

  const [selectedItem, setSelectedItem] = useState("account");

  const navigate = useNavigate();
  const {
    goToProfile,
    goToAccount,
    goToAddressBook,
    goToSavedCards,
    goToOrderHistory,
  } = useNavigation();
  const location = useLocation();

  const [showConfirm, setShowConfirm] = useState(false);
  const [isOpenBox, setIsOpenBox] = useState([false, true, true, true, true]);
  const [isOpenContainer, setIsOpenContainer] = useState([
    false,
    true,
    true,
    true,
    true,
  ]);
  const [isBoxAnimating, setIsBoxAnimating] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const toggleBox = (index) => {
    if (isBoxAnimating[index]) {
      return;
    }

    const updatedIsOpenBox = [...isOpenBox];
    updatedIsOpenBox[index] = !updatedIsOpenBox[index];
    setIsOpenBox(updatedIsOpenBox);

    const updatedIsBoxAnimating = [...isBoxAnimating];
    updatedIsBoxAnimating[index] = true;
    setIsBoxAnimating(updatedIsBoxAnimating);

    setTimeout(
      () => {
        const updatedIsOpenContainer = [...isOpenContainer];
        updatedIsOpenContainer[index] = !updatedIsOpenContainer[index];
        setIsOpenContainer(updatedIsOpenContainer);

        updatedIsBoxAnimating[index] = false;
        setIsBoxAnimating(updatedIsBoxAnimating);
      },
      !isOpenContainer[index] ? 200 : 10
    );
  };
  const changeSection = (item) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    // Ažuriranje `selectedItem` na osnovu trenutne putanje
    if (location.pathname === `/${i18next.language}/account/profile`) {
      setSelectedItem("profile");
    } else if (location.pathname === `/${i18next.language}/account/cards`) {
      setSelectedItem("cards");
    } else if (
      location.pathname.includes(`/${i18next.language}/account/address-book`)
    ) {
      setSelectedItem("addresses");
    } else if (
      location.pathname.includes(`/${i18next.language}/account/orders`)
    ) {
      setSelectedItem("order_history");
    } else {
      setSelectedItem("account");
    }
  }, [location.pathname, i18next.language]);
  return (
    <>
      <Container $section={selectedItem}>
        <Wrapper>
          <GridContainer>
            <GridItems>
              <ButtonWrapper>
                <Button
                  onClick={() => {
                    toggleBox(0);
                    changeSection("profile");
                  }}
                >
                  <SVGIcon
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.7712 25.9858C19.5234 26.1023 19.2477 26.1663 18.9572 26.1663H5.46145C4.39478 26.1663 3.52832 25.3015 3.52832 24.2332C3.52832 22.0211 4.42596 20.019 5.87499 18.5683C7.32401 17.1177 9.3277 16.2217 11.5398 16.2217H12.8789C13.6863 16.2217 14.4674 16.3415 15.2026 16.563L15.196 16.5745C14.9663 16.9733 14.9056 17.4377 15.0237 17.8824C15.1435 18.3271 15.429 18.6996 15.8278 18.9294L16.0116 19.036C15.9903 19.2494 15.9788 19.4643 15.9788 19.6793C15.9788 19.8943 15.9903 20.1093 16.0116 20.3226L15.8278 20.4293C15.429 20.659 15.1435 21.0315 15.0237 21.4762C14.9056 21.921 14.9663 22.3854 15.196 22.7841L16.0969 24.3431C16.4038 24.8748 16.9765 25.2063 17.5903 25.2063C17.8922 25.2063 18.1892 25.1259 18.4518 24.9749L18.6356 24.8682C18.9851 25.1193 19.3593 25.3359 19.7515 25.5115V25.7249C19.7515 25.8135 19.7581 25.9005 19.7712 25.9858Z"
                      fill="var(--accent-100)"
                    />
                    <path
                      d="M12.2097 14.515C14.6069 14.515 16.5502 12.5717 16.5502 10.1745C16.5502 7.7773 14.6069 5.83398 12.2097 5.83398C9.81245 5.83398 7.86914 7.7773 7.86914 10.1745C7.86914 12.5717 9.81245 14.515 12.2097 14.515Z"
                      fill="var(--accent-100)"
                    />
                    <path
                      d="M28.2671 21.5666L27.2842 20.9988C27.3974 20.5771 27.4581 20.1356 27.4581 19.6794C27.4581 19.2232 27.3974 18.7801 27.2842 18.36L28.2671 17.7922C28.464 17.679 28.5313 17.4279 28.4181 17.231L27.5172 15.672C27.4039 15.4751 27.1529 15.4078 26.9559 15.5211L25.973 16.0889C25.3494 15.4653 24.565 15.0025 23.687 14.7678V13.6339C23.687 13.4074 23.5032 13.2236 23.2768 13.2236H21.4766C21.2501 13.2236 21.0663 13.4074 21.0663 13.6339V14.7678C20.1884 15.0009 19.4039 15.4636 18.7804 16.0889L17.7974 15.5211C17.6005 15.4078 17.3494 15.4751 17.2361 15.672L16.3352 17.231C16.222 17.4279 16.2893 17.679 16.4862 17.7922L17.4692 18.36C17.3559 18.7818 17.2952 19.2232 17.2952 19.6794C17.2952 20.1356 17.3559 20.5787 17.4692 20.9988L16.4862 21.5666C16.2893 21.6798 16.222 21.9309 16.3352 22.1278L17.2361 23.6868C17.3494 23.8837 17.6005 23.951 17.7974 23.8378L18.7804 23.27C19.4039 23.8936 20.1884 24.3564 21.0663 24.591V25.725C21.0663 25.9514 21.2501 26.1352 21.4766 26.1352H23.2768C23.5032 26.1352 23.687 25.9514 23.687 25.725V24.591C24.565 24.358 25.3494 23.8952 25.973 23.27L26.9559 23.8378C27.1529 23.951 27.4039 23.8837 27.5172 23.6868L28.4181 22.1278C28.5313 21.9309 28.464 21.6798 28.2671 21.5666ZM22.3758 21.752C21.2304 21.752 20.3032 20.8232 20.3032 19.6794C20.3032 18.534 21.232 17.6068 22.3758 17.6068C23.5196 17.6068 24.4485 18.5356 24.4485 19.6794C24.4485 20.8232 23.5213 21.752 22.3758 21.752Z"
                      fill="var(--bg-100)"
                    />
                  </SVGIcon>
                  {t("PROFILE.ACCOUNT_SETTINGS")}
                </Button>
              </ButtonWrapper>
              <DropDown $isOpen={isOpenContainer[0]}>
                <Box $isOpen={isOpenBox[0]}>
                  <SmallButton
                    $active={selectedItem === "account"}
                    onClick={() => {
                      changeSection("account");
                      navigate(goToAccount());
                    }}
                  >
                    {t("PROFILE.ACCOUNT_OVERVIEW")}
                    <Arrow
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 9L5 5L1 1"
                        stroke="black"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </Arrow>
                  </SmallButton>
                  <SmallButton
                    $active={selectedItem === "profile"}
                    onClick={() => {
                      changeSection("profile");
                      navigate(goToProfile());
                    }}
                  >
                    {t("PROFILE.PROFILE_INFO")}
                    <Arrow
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 9L5 5L1 1"
                        stroke="black"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </Arrow>
                  </SmallButton>
                  <SmallButton
                    $active={selectedItem === "addresses"}
                    onClick={() => {
                      changeSection("addresses");
                      navigate(goToAddressBook());
                    }}
                  >
                    {t("PROFILE.MANAGE_ADDRESSES")}
                    <Arrow
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 9L5 5L1 1"
                        stroke="black"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </Arrow>
                  </SmallButton>
                </Box>
              </DropDown>
            </GridItems>

            <GridItems>
              <ButtonWrapper>
                <Button
                  onClick={() => {
                    toggleBox(1);
                    changeSection("order_history");
                    navigate(goToOrderHistory());
                  }}
                  $active={selectedItem === "order_history"}
                >
                  <SVGIcon
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.71 2.78706L13.9161 4.03374L4.34398 7.7558L0.990438 6.45179L10.4155 2.78706C10.5139 2.74872 10.6115 2.74872 10.71 2.78706ZM20.6938 13.4925C23.386 13.4925 25.5684 15.675 25.5684 18.3673C25.5684 21.0595 23.3859 23.2419 20.6938 23.2419C18.0016 23.2419 15.819 21.0595 15.819 18.3673C15.819 15.675 18.0015 13.4925 20.6938 13.4925ZM22.5395 16.1443L19.6518 19.0319L18.6665 17.7917C18.4241 17.4869 17.9803 17.4362 17.6754 17.6786C17.3704 17.9209 17.3198 18.3647 17.5622 18.6696L19.0124 20.4945C19.2632 20.8537 19.7795 20.9029 20.092 20.5904L23.5387 17.1435C23.8148 16.8676 23.8148 16.4202 23.5387 16.1443C23.2628 15.8683 22.8155 15.8683 22.5395 16.1443ZM17.3398 5.36497C14.1491 6.60576 10.9584 7.84649 7.76755 9.08698L10.5627 10.1738L20.135 6.45179L17.3398 5.36497ZM20.6938 7.10341L10.969 10.8847V22.1486L15.392 20.4288C15.1433 19.7896 15.0066 19.0945 15.0066 18.3673C15.0066 15.2263 17.5528 12.6801 20.6938 12.6801V7.10341ZM10.1565 22.1485V10.8847L7.20896 9.7387V12.1172C7.20896 12.2322 7.07464 12.2942 6.9872 12.2197L5.42288 10.8874L3.95992 11.3423C3.87283 11.3694 3.78534 11.3051 3.78534 11.2139V8.40747L0.431641 7.10346V18.0893C0.431641 18.2603 0.531426 18.406 0.690828 18.468L10.1565 22.1485Z"
                      fill="var(--accent-100)"
                    />
                  </SVGIcon>
                  {t("PROFILE.ORDERS")}
                  <Arrow
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 9L5 5L1 1"
                      stroke="black"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </Arrow>
                </Button>
              </ButtonWrapper>
            </GridItems>

            <GridItems>
              <ButtonWrapper>
                <Button
                  onClick={() => {
                    toggleBox(2);
                    changeSection("cards");
                    navigate(goToSavedCards());
                  }}
                >
                  <SVGIcon
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_303_6647)">
                      <path
                        d="M19.5679 17.1818H16.227C14.1217 17.1818 12.4089 15.469 12.4089 13.3637C12.4089 11.2585 14.1217 9.54564 16.227 9.54564H19.5679C19.8317 9.54564 20.0452 9.33217 20.0452 9.06836V7.63656C20.0452 6.63574 19.2689 5.82181 18.288 5.74288L15.5471 0.955317C15.293 0.512535 14.8829 0.196064 14.3921 0.0646516C13.9036 -0.0658655 13.3928 0.00268171 12.9556 0.257138L3.55935 5.72749H1.90907C0.856213 5.72749 0 6.58366 0 7.63656V19.0909C0 20.1438 0.856169 21 1.90907 21H18.1361C19.189 21 20.0452 20.1438 20.0452 19.0909V17.6591C20.0452 17.3953 19.8317 17.1818 19.5679 17.1818ZM16.139 3.91108L17.1789 5.72749H13.019L16.139 3.91108ZM5.45648 5.72749L13.4361 1.08208C13.6519 0.955765 13.9041 0.922207 14.1451 0.986548C14.3888 1.05178 14.592 1.20933 14.7184 1.42978L14.7193 1.43152L7.34068 5.72749H5.45648Z"
                        fill="var(--accent-100)"
                      />
                      <path
                        d="M19.5677 10.5H16.2269C14.6478 10.5 13.3633 11.7845 13.3633 13.3636C13.3633 14.9427 14.6478 16.2272 16.2269 16.2272H19.5677C20.3573 16.2272 20.9995 15.5849 20.9995 14.7954V11.9318C20.9995 11.1423 20.3573 10.5 19.5677 10.5ZM16.2269 14.3181C15.7007 14.3181 15.2724 13.8898 15.2724 13.3636C15.2724 12.8374 15.7007 12.4091 16.2269 12.4091C16.7531 12.4091 17.1814 12.8374 17.1814 13.3636C17.1814 13.8898 16.7531 14.3181 16.2269 14.3181Z"
                        fill="var(--bg-100)"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_303_6647">
                        <rect width="21" height="21" fill="white" />
                      </clipPath>
                    </defs>
                  </SVGIcon>
                  {t("PROFILE.PAYMENTS")}
                </Button>
              </ButtonWrapper>
              <DropDown $isOpen={isOpenContainer[2]}>
                <Box $isOpen={isOpenBox[2]}>
                  <SmallButton
                    $active={selectedItem === "cards"}
                    onClick={() => {
                      changeSection("cards");
                      navigate(goToSavedCards());
                    }}
                  >
                    {t("PROFILE.SAVED_CARDS")}
                    <Arrow
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 9L5 5L1 1"
                        stroke="black"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </Arrow>
                  </SmallButton>
                  <SmallButton
                    $active={selectedItem === "points"}
                    onClick={() => changeSection("points")}
                  >
                    {t("PROFILE.SNUSCO_COIN")}
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3594 26.7188C20.7376 26.7188 26.7188 20.7376 26.7188 13.3594C26.7188 5.9812 20.7376 0 13.3594 0C5.9812 0 0 5.9812 0 13.3594C0 20.7376 5.9812 26.7188 13.3594 26.7188Z"
                        fill="#FEDA2C"
                      />
                      <path
                        d="M13.359 23.6574C19.0467 23.6574 23.6574 19.0467 23.6574 13.359C23.6574 7.67132 19.0467 3.06055 13.359 3.06055C7.67132 3.06055 3.06055 7.67132 3.06055 13.359C3.06055 19.0467 7.67132 23.6574 13.359 23.6574Z"
                        fill="#FCAA17"
                      />
                      <path
                        d="M13.3595 5.44482L15.7016 10.1904L20.9385 10.9515L17.1491 14.6454L18.0437 19.8612L13.3595 17.3987L8.67527 19.8612L9.56988 14.6454L5.78027 10.9515L11.0174 10.1904L13.3595 5.44482Z"
                        fill="#FEDA2C"
                      />
                    </svg>
                    <Arrow
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 9L5 5L1 1"
                        stroke="black"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </Arrow>
                  </SmallButton>
                </Box>
              </DropDown>
            </GridItems>
            <GridItems>
              <ButtonWrapper>
                <Button onClick={() => changeSection("referrals")}>
                  <SVGIcon
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_440_6274)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.34961 17.2778L5.94877 17.3096L8.18535 15.075L9.95847 16.8482C10.422 16.2521 10.9919 15.7526 11.6541 15.3697L11.7704 15.3035L11.8616 15.3993L11.4691 13.7384C11.1486 13.8201 10.8156 13.8622 10.4756 13.8622C9.37019 13.8622 8.33905 13.418 7.57195 12.6139L7.48073 12.518L7.36438 12.5841C5.74179 13.5223 4.67466 15.1615 4.36116 17.2037L4.34961 17.2778ZM10.4756 13.0416C8.4651 13.0416 6.82939 11.4057 6.82939 9.39495C6.82939 7.38279 8.4651 5.74707 10.4756 5.74707C12.216 5.74707 13.6754 6.97265 14.0362 8.606C12.5111 9.13214 11.2188 10.9233 11.205 12.968C10.9692 13.0161 10.7253 13.0416 10.4756 13.0416Z"
                        fill="var(--bg-100)"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.0607 24.475H24.4876C24.6259 21.4277 23.5674 18.8202 20.7238 17.1778L20.5786 17.0938L20.4605 17.2155C19.4873 18.2356 18.1791 18.7993 16.7764 18.7993C15.374 18.7993 14.0659 18.2356 13.0926 17.2155L12.9768 17.0938L12.8293 17.1778C12.2272 17.5259 11.7053 17.9175 11.2574 18.3473L13.1652 20.2551H10.1797C10.3147 21.7048 10.8537 22.8994 12.1355 23.8145L13.0607 24.475ZM16.7764 17.7581C14.2257 17.7581 12.1505 15.6828 12.1505 13.1318C12.1505 10.579 14.2257 8.50391 16.7764 8.50391C19.3274 8.50391 21.4027 10.579 21.4027 13.1296C21.4027 15.6806 19.3274 17.7581 16.7764 17.7581Z"
                        fill="var(--accent-100)"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.92128 16.606L4.71875 19.6648H6.54753C7.0055 22.7265 9.29225 24.0369 11.579 24.4747C9.97773 23.3816 9.24768 21.8509 9.24768 19.6648H11.121L7.92128 16.606Z"
                        fill="var(--accent-100)"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_440_6274">
                        <rect width="28" height="28" fill="white" />
                      </clipPath>
                    </defs>
                  </SVGIcon>
                  {t("PROFILE.REFERRALS")}
                </Button>
              </ButtonWrapper>
            </GridItems>
            <GridItems>
              <ButtonWrapper>
                <Button onClick={(e) => setShowConfirm(true)}>
                  <SVGIcon
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.6495 0.78125C7.87074 0.78125 5.61816 3.03381 5.61816 5.8125C5.61816 8.59118 7.87074 10.8437 10.6495 10.8437C13.4281 10.8437 15.6807 8.59118 15.6807 5.8125C15.6807 3.03381 13.4281 0.78125 10.6495 0.78125Z"
                      fill="var(--accent-100)"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.8331 11.5863C18.3673 11.0587 17.6836 10.7241 16.9227 10.7241H15.0941C13.6873 10.7241 12.5469 11.8646 12.5469 13.2715V19.6714C12.5469 21.0783 13.6873 22.2188 15.0941 22.2188H16.9227C17.6836 22.2188 18.3673 21.8842 18.8331 21.3565C19.0957 21.059 19.0674 20.6047 18.7698 20.3421C18.4722 20.0794 18.018 20.1077 17.7553 20.4053C17.5509 20.6369 17.2541 20.7813 16.9227 20.7813H15.0941C14.4813 20.7813 13.9844 20.2844 13.9844 19.6714V13.2715C13.9844 12.6585 14.4813 12.1616 15.0941 12.1616H16.9227C17.2541 12.1616 17.5509 12.3059 17.7553 12.5376C18.018 12.8351 18.4722 12.8635 18.7698 12.6008C19.0674 12.3381 19.0957 11.8839 18.8331 11.5863Z"
                      fill="var(--bg-100)"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.5597 15.963C21.8404 16.2437 21.8404 16.6987 21.5597 16.9794L19.643 18.896C19.3623 19.1767 18.9072 19.1767 18.6265 18.896C18.3458 18.6153 18.3458 18.1603 18.6265 17.8796L20.5432 15.963C20.8239 15.6823 21.279 15.6823 21.5597 15.963Z"
                      fill="var(--bg-100)"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.5597 16.9795C21.8404 16.6988 21.8404 16.2438 21.5597 15.9631L19.643 14.0465C19.3623 13.7658 18.9072 13.7658 18.6265 14.0465C18.3458 14.3272 18.3458 14.7822 18.6265 15.0629L20.5432 16.9795C20.8239 17.2602 21.279 17.2602 21.5597 16.9795Z"
                      fill="var(--bg-100)"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.0625 16.4712C15.0625 16.0742 15.3842 15.7524 15.7812 15.7524H20.5729C20.9699 15.7524 21.2917 16.0742 21.2917 16.4712C21.2917 16.8681 20.9699 17.1899 20.5729 17.1899H15.7812C15.3842 17.1899 15.0625 16.8681 15.0625 16.4712Z"
                      fill="var(--bg-100)"
                    />
                    <path
                      d="M11.1246 12.9281C11.1149 13.0412 11.11 13.1556 11.11 13.2712V19.6711C11.11 20.3859 11.2981 21.0566 11.6276 21.6366H2.89681C1.97059 21.6366 1.21973 20.8857 1.21973 19.9595V16.4406C1.21973 15.853 1.54402 15.3135 2.06285 15.0378C4.89057 13.5351 8.01711 12.8319 11.1246 12.9281Z"
                      fill="var(--accent-100)"
                    />
                  </SVGIcon>
                  {t("PROFILE.LOGOUT")}
                </Button>
              </ButtonWrapper>
            </GridItems>
          </GridContainer>
        </Wrapper>
      </Container>
      {showConfirm && (
        <ConfirmContainer>
          <ConfirmWrapper>
            {t("PROFILE.CONFIRM_LOGOUT")}

            <ButtonContainer>
              <ButtonConfirm
                className="button-transparent"
                onClick={logoutUser}
              >
                {t("BUTTONS.LOGOUT")}
              </ButtonConfirm>
              <ButtonTransparent
                className="button-transparent"
                onClick={(e) => setShowConfirm(false)}
              >
                {t("BUTTONS.CANCEL")}
              </ButtonTransparent>
            </ButtonContainer>
          </ConfirmWrapper>
        </ConfirmContainer>
      )}
    </>
  );
};

export default UserMenu;
