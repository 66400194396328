import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Footer from "../layouts/footer/Footer";
import ShopMain from "../layouts/main/ShopMain";
import PRODUCT_CHUNK, { DEFAULT_LANGUAGE } from "../utils/global_const";
import { useParams } from "react-router-dom";
import { ProductContext } from "../context/ProductContext";
import Header from "../layouts/header/Header";

const SEOConfig = {
  en: {
    title: "Shop | SnusCo",
    description:
      "Explore our wide range of nicotine pouches and find your perfect match at SnusCo. Shop now and enjoy premium quality and variety.",
    keywords:
      "nicotine pouches, snus, buy snus online, best nicotine pouches, SnusCo shop, nicotine pouches shop",
    url: "https://www.snusco.com/en/nicotine-pouches/category",
    images: ["https://www.snusco.com/assets/default-category-image.jpg"],
  },
  de: {
    title: "Shop | SnusCo",
    description:
      "Willkommen bei SnusCo – Ihrem Fachgeschäft für hochwertige nikotinfreie und nikotinhaltige Beutel. Entdecken Sie unser breites Sortiment an Geschmacksrichtungen und Stärken. Jetzt bestellen und den besten Snus genießen!",
    keywords:
      "Nikotinbeutel, SnusCo, Snus kaufen, Nikotin Pouches, hochwertiger Snus",
    url: "https://www.snusco.com/de/nicotine-pouches/category",
    images: ["https://www.snusco.com/assets/default-category-image.jpg"],
  },
};

const Shop = () => {
  const { t, i18n } = useTranslation();
  const { loadProducts, loadProductsByCategorySlug, setCategory, category } =
    useContext(ProductContext);

  const { slug } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!slug) {
      loadProducts();
      setCategory(null);
      return;
    }
    loadProductsByCategorySlug(slug);
  }, [slug, i18n.language]);

  const [seo, setSeo] = useState(SEOConfig[DEFAULT_LANGUAGE]);

  useEffect(() => {
    if (category) {
      const seoTitle = `${category.name} | SnusCo`;
      const seoDescription = category.short_description;
      const defaultKeywords =
        SEOConfig[i18n.language]?.keywords ||
        SEOConfig[DEFAULT_LANGUAGE].keywords;
      const seoKeywords = `${category.name}, ${defaultKeywords}`;
      const categoryUrl = `https://www.snusco.com/${
        i18n.language
      }/nicotine-pouches/${category.name.toLowerCase().replace(/ /g, "-")}`;

      // Pronalazimo primarnu sliku za kategoriju, ako postoji
      const primaryImage =
        category.images?.desktop ||
        "https://www.snusco.com/assets/default-category-image.jpg";

      setSeo({
        title: seoTitle,
        description: seoDescription,
        keywords: seoKeywords,
        url: categoryUrl,
        images: [primaryImage],
      });
    } else {
      setSeo(SEOConfig[i18n.language] || SEOConfig[DEFAULT_LANGUAGE]);
    }
  }, [category, i18n.language]);

  return (
    <div>
      <Helmet>
        <html lang={i18n.language} />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />

        {/* Open Graph slike - Primarna slika na prvom mestu */}
        {seo.images?.map((image, index) => (
          <meta key={index} property="og:image" content={image} />
        ))}

        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={seo.url} />
      </Helmet>
      <Header />
      <ShopMain />
      <Footer />
    </div>
  );
};

export default Shop;
