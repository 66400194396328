import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../layouts/header/Header";
import { useTranslation } from "react-i18next";
import { ProductContext } from "../../context/ProductContext";
import ProductSlider from "../../components/product/ProductSlider";
import BannerHorizontal from "../../components/hero/BannerHorizontal";
import Footer from "../footer/Footer";
//import Subscription from "../footer/Subscription";
import ProductSliderHorizontal from "../../components/product/ProductSliderHorizontal";
import ProductCard from "../../components/product/ProductCard";
import { Link, useNavigate } from "react-router-dom";
import AboutUsSection from "../../components/section/AboutUsSection";
import { useNavigation } from "../../utils/navigation";
import APIService from "../../services/APIService";

import veloImage from "../../assets/images/banner/velo_same.webp";
import veloImageM from "../../assets/images/banner/velo_same_m.webp";
import zynImage from "../../assets/images/banner/zyn.webp";
import zynImageM from "../../assets/images/banner/zyn_m.webp";
import thunderImage from "../../assets/images/banner/thunder.webp";
import thunderImageM from "../../assets/images/banner/thunder_m.webp";
import voltImage from "../../assets/images/banner/volt.webp";
import voltImageM from "../../assets/images/banner/volt_m.webp";

const Container = styled.article`
  color: ${(props) => props.theme.textColor};
  padding-top: 72px;
`;
const Section = styled.section`
  @media (min-width: 765px) {
    margin-top: 80px;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  @media (min-width: 765px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const CustomLink = styled.button`
  text-align: center;
  //width: 100%;
  display: block;
  color: var(--text-color);
  font-size: var(--font-size-base);
  margin: 14px auto;
  background-color: var(--bg-200);
  &:hover {
    background-color: var(--bg-200);
  }

  border-radius: 20px;
  border-color: var(--bg-300);
  text-transform: capitalize;
`;
const FeaturedHomeMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { goToCategory } = useNavigation();
  const {
    products,
    loadProducts,
    loadBestSellers,
    loadNewArrivals,
    setCategory,
    category,
  } = useContext(ProductContext);

  // Dynamic state for brands
  const [brandData, setBrandData] = useState({
    velo: [],
    zyn: [],
    thunder: [],
    volt: [],
  });

  // List of brands to fetch
  const brands = ["velo", "zyn", "thunder", "volt"];

  // Fetch products for all brands
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const updatedBrandData = { ...brandData };
        for (const brand of brands) {
          const response = await APIService.GetProductsByCategory(brand);
          updatedBrandData[brand] = response.products;
        }
        setBrandData(updatedBrandData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Handle ProductContext updates
  useEffect(() => {
    if (category) {
      loadProducts();
      setCategory(null);
    } else if (products.length) {
      loadBestSellers();
      loadNewArrivals();
    } else {
      loadProducts();
    }
  }, [products]);

  // Scroll to top when products are loaded
  useEffect(() => {
    window.scrollTo({ behavior: "smooth" });
  }, [products]);

  const renderBrandSection = (brand) => (
    <>
      <CustomLink onClick={() => navigate(goToCategory(brand))}>
        {`View All ${brand} Nicotine Pouches`}
      </CustomLink>
      <Grid>
        {brandData[brand]?.slice(0, 5).map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </Grid>
    </>
  );

  return (
    <>
      <Header />
      <Container>
        <Section>
          <BannerHorizontal
            desktopImage={veloImage}
            mobileImage={veloImageM}
            subtitle={t("BANNER.VELO.SUBTITLE")}
            title={t("BANNER.VELO.TITLE")}
            text={t("BANNER.VELO.TEXT")}
            buttonText={t("BUTTONS.SHOP_NOW")}
            onClickAction={() => navigate(goToCategory("velo"))}
          />
          {renderBrandSection("velo")}

          <BannerHorizontal
            desktopImage={zynImage}
            mobileImage={zynImageM}
            subtitle={t("BANNER.ZYN.SUBTITLE")}
            title={t("BANNER.ZYN.TITLE")}
            text={t("BANNER.ZYN.TEXT")}
            buttonText={t("BUTTONS.SHOP_NOW")}
            onClickAction={() => navigate(goToCategory("zyn"))}
          />
          {renderBrandSection("zyn")}

          <BannerHorizontal
            desktopImage={thunderImage}
            mobileImage={thunderImageM}
            subtitle={t("BANNER.THUNDER.SUBTITLE")}
            title={t("BANNER.THUNDER.TITLE")}
            text={t("BANNER.THUNDER.TEXT")}
            buttonText={t("BUTTONS.SHOP_NOW")}
            onClickAction={() => navigate(goToCategory("thunder"))}
          />
          {renderBrandSection("thunder")}

          <BannerHorizontal
            desktopImage={voltImage}
            mobileImage={voltImageM}
            subtitle={t("BANNER.VOLT.SUBTITLE")}
            title={t("BANNER.VOLT.TITLE")}
            text={t("BANNER.VOLT.TEXT")}
            buttonText={t("BUTTONS.SHOP_NOW")}
            onClickAction={() => navigate(goToCategory("volt"))}
          />
          {renderBrandSection("volt")}
        </Section>
      </Container>
      <Footer />
    </>
  );
};

export default FeaturedHomeMain;
