import styled from "styled-components";
import backgroundDesktopImage from "../../assets/images/home/home_desktop_2x.webp";
import bannerMobileImage from "../../assets/images/home/home-mobile_2x.webp";

const Container = styled.main``;
const SupportContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const MaxWidthWrapper = styled.div`
  margin: var(--spacing-md) 0;
  width: calc(100% - var(--spacing-xl) * 2);
  @media (min-width: 1025px) {
    width: var(--max-width-container);
    width: 800px;
  }
`;
const Text = styled.p`
  color: var(--text-200);
`;
const Title = styled.h3`
  font-size: var(--font-size-xxlarge);
  margin: 0;
  margin-top: var(--spacing-lg);
  font-weight: 400;
`;

//HERO
const HeroContainer = styled.div`
  color: var(--text-100);
  overflow: hidden;
  background-image: url(${bannerMobileImage});
  background-size: cover;
  background-position: center;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    background-image: url(${backgroundDesktopImage});
    text-align: left;
  }
  height: 50vh;
`;

const InnerHeroContainer = styled.div`
  z-index: 9;
  @media (min-width: 768px) and (max-width: 1040px) {
  }
  @media (min-width: 768px) {
    width: var(--max-width-container);
    width: 800px;
    height: auto;
  }
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 12px;
  height: 90%;
`;

const HeroTitle = styled.h1`
  //font-family: Larken;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: var(--text-100);
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
  max-width: 800px;
`;

const HeroText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  //line-height: 28px;
  color: var(--text-100);
  text-align: center;
`;
const HeroDate = styled(HeroText)`
  font-weight: 700;
  text-transform: uppercase;
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <HeroContainer className="header-top-padding">
        <InnerHeroContainer>
          <TextContainer>
            <HeroDate>Aktualisiert am 20. Januar 2025</HeroDate>
            <HeroTitle>Datenschutzrichtlinie</HeroTitle>
            <HeroText>
              Der Schutz Ihrer Daten ist uns bei Untitled sehr wichtig. Wir
              respektieren Ihre Privatsphäre in Bezug auf alle Informationen,
              die wir über unsere Website von Ihnen sammeln könnten.
            </HeroText>
          </TextContainer>
        </InnerHeroContainer>
      </HeroContainer>
      <SupportContainer>
        <MaxWidthWrapper>
          <Text>
            Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
            suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum
            quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris
            posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At
            feugiat sapien varius id. Eget quis mi enim, leo lacinia pharetra,
            semper. Eget in volutpat mollis at volutpat lectus velit, sed
            auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant
            diam at. Suscipit tristique risus, at donec. In turpis vel et quam
            imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.{" "}
          </Text>
          <Title>Welche Informationen erfassen wir?</Title>
          <Text>
            Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla
            odio nisl vitae. In aliquet pellentesque aenean hac vestibulum
            turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada
            fringilla. Elit nisi in eleifend sed nisi.
          </Text>
          <Text>
            {" "}
            Pulvinar at orci, proin imperdiet commodo consectetur convallis
            risus. Sed condimentum enim dignissim adipiscing faucibus consequat,
            urna. Viverra purus et erat auctor aliquam. Risus, volutpat
            vulputate posuere purus sit congue convallis aliquet. Arcu id augue
            ut feugiat donec porttitor neque. Mauris, neque ultricies eu
            vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in
            tellus, pharetra, porttitor.{" "}
          </Text>
        </MaxWidthWrapper>
      </SupportContainer>
    </Container>
  );
};

export default PrivacyPolicy;
