import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthUserContext } from "../../context/AuthUserContext";
import AddressBookForm from "./AddressBookForm";
import { useNavigation } from "../../utils/navigation";
import { useTranslation } from "react-i18next";

const GridContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
  }
`;
const Container = styled.figure`
  position: relative;
  background-color: var(--bg-200);
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding: var(--spacing-md) 0;
  box-shadow: var(--shadow-medium);
  border-radius: 4px;
  cursor: pointer;
  border-bottom: 1px solid var(--bg-300);
  border-right: 1px solid var(--bg-300);

  @media (min-width: 768px) {
    //width: 400px;
  }
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const WrapperTop = styled.div`
  width: 96%;
  font-size: var(--font-size-large);
  border-bottom: 1px solid var(--bg-300);
  padding-bottom: var(--spacing-xs);
`;
const WrapperMid = styled.div`
  width: 96%;
`;
const WrapperBottom = styled.div`
  width: 96%;
  font-size: var(--font-size-large);
  border-top: 1px solid var(--bg-300);
  padding-top: var(--spacing-xs);
  display: flex;
  justify-content: space-between;
`;
const WarningContainer = styled.div`
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
  padding: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 20px;
`;
const WarningIcon = styled.span`
  margin-right: 10px;
  font-size: 24px;
  font-weight: bold;
`;

const ConfirmContainer = styled.div`
  margin-top: var(--spacing-xs);
  background-color: #00000031;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const ConfirmWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  background-color: #1a2c38;
  background-color: var(--bg-200);
  position: relative;
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;

  padding: 60px 0;
  width: 94%;
  border-radius: 8px;
  @media (min-width: 768px) {
    width: 500px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-xl);
`;

const ButtonTransparent = styled.button`
  padding: var(--spacing-sm) var(--spacing-lg);

  cursor: pointer;
  color: var(--primary-200);
  background-color: var(--bg-100);
  &:hover {
    color: var(--primary-100);
    background-color: var(--bg-200);
  }
`;
const ButtonConfirm = styled(ButtonTransparent)`
  color: var(--error-color);
  background-color: var(--bg-100);
  border-color: var(--error-color);
  &:hover {
    color: var(--bg-100);
    background-color: var(--error-color);
  }
`;
const AddressCards = () => {
  const { t } = useTranslation();
  const { userProfile } = useContext(AuthUserContext);
  const navigate = useNavigate();
  const { goToAddAddressBook, goToEditAddressBook, deleteAddressBook } =
    useNavigation();
  const [deleteAddress, setDeleteAddress] = useState(false);

  const handleDelete = async (e, addressId) => {
    e.preventDefault();

    try {
      await deleteAddressBook(addressId);
    } catch (error) {
      console.error("Greška prilikom brisanja adrese:", error); // Obradite grešku
    }
    setDeleteAddress(false);
  };

  return (
    <>
      <FlexDiv>
        <h2
          style={{ color: "var(--text-100)", padding: "var(--spacing-md) 0" }}
        >
          {t("ADDRESS_BOOK.TITLE")}
        </h2>

        <button
          onClick={() => {
            navigate(goToAddAddressBook());
          }}
        >
          {t("ADDRESS_BOOK.ADD_NEW_ADDRESS")}
        </button>
      </FlexDiv>

      {userProfile?.addresses.length === 0 ? (
        <WarningContainer>
          <WarningIcon>⚠️</WarningIcon> {t("ADDRESS_BOOK.NO_ADDRESSES")}
        </WarningContainer>
      ) : (
        <GridContainer>
          {userProfile?.addresses.map((address, index) => (
            <Container key={index}>
              <WrapperTop>{t("ADDRESS_BOOK.SHIPPING_ADDRESS")}</WrapperTop>
              <WrapperMid>
                <p>
                  {userProfile.first_name} {userProfile.last_name} -{" "}
                  {address.type}
                </p>
                <p>
                  {address.country}, {address.id} {address.city},{" "}
                  {address.postal_code}
                </p>

                <p>
                  {address.street} {address.street_number}
                </p>
                <p>Tel:{address.phone_number}</p>
              </WrapperMid>
              <WrapperBottom>
                <button
                  onClick={() => navigate(goToEditAddressBook(address.id))}
                >
                  {t("BUTTONS.EDIT")}
                </button>
                {userProfile?.addresses.length !== 1 && !address.is_primary && (
                  <button
                    onClick={() => {
                      setDeleteAddress(address);
                    }}
                  >
                    {t("BUTTONS.DELETE")}
                  </button>
                )}
              </WrapperBottom>
            </Container>
          ))}
        </GridContainer>
      )}
      {deleteAddress && (
        <ConfirmContainer>
          <ConfirmWrapper>
            {t("ADDRESS_BOOK.CONFIRM_DELETE")}
            <ButtonContainer>
              <ButtonConfirm onClick={(e) => handleDelete(e, deleteAddress.id)}>
                {t("BUTTONS.CONFIRM")}
              </ButtonConfirm>
              <ButtonTransparent
                onClick={(e) => {
                  setDeleteAddress(false);
                }}
              >
                {t("BUTTONS.CANCEL")}
              </ButtonTransparent>
            </ButtonContainer>
          </ConfirmWrapper>
        </ConfirmContainer>
      )}
    </>
  );
};

export default AddressCards;
