import { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled, { css } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import hoverImg from "../../assets/images/product_bg_hover.svg";
import { useMediaQuery } from "react-responsive";

const ImageContainer = styled(motion.div)`
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
  position: relative;

  background-color: var(--bg-200);
  @media (min-width: 768px) {
    height: auto;
  }
`;
const Image = styled.img`
  //width:300px;
  width: auto;
  height: 200px;
  margin: 0 auto;

  @media only screen and (min-width: 769px) {
    height: 340px;
  }
`;
const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  pointer-events: all;

  ${(props) =>
    props.direction === "left" &&
    css`
      left: 5%;
    `}

  ${(props) =>
    props.direction === "right" &&
    css`
      right: 5%;
    `}

  &:hover {
    background-color: transparent;
  }
`;

// Korišćenje Arrow komponente za oba strelice
// Za prethodnu strelicu
const PrevArrow = (props) => <Arrow {...props} direction="left" />;

// Za sledeću strelicu
const NextArrow = (props) => <Arrow {...props} direction="right" />;

const GridContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const GridImageDiv = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  cursor: grab;

  display: none;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  width: 100%;
`;
const NavItem = styled.picture`
  min-width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  background-image: url(${hoverImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`;

const FullScreenModal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--transparent-color);
  z-index: 9999999999;

  background-color: #00000032;
`;

const ModalImage = styled(motion.img)`
  width: 50%;
  height: auto;
  max-height: 80%;
  cursor: grab;
`;
const FullScreenSlider = styled(Slider)`
  overflow: visible;
  width: 94%;
  cursor: grab;
  background-color: #0000003c;

  .slick-slide {
    overflow: visible;
  }
`;
const StyledSvgIcon = styled.svg`
  position: fixed;
  right: 40px;
  top: 40px;
  width: 40px;
  height: 40px;
  z-index: 5;

  & path {
    stroke: var(--background-color); // Koristi boju pozadine za liniju SVG-a
    stroke-width: 4; // Čini liniju debljom
  }
`;
const StyledSvgText = styled.span`
  position: fixed;
  top: 50px;
  left: 40px;
  z-index: 5;
  font-weight: 600;
  color: var(--background-color);
`;

const ImageSlider = ({ images }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [indexImg, setIndexImg] = useState(0);
  const sliderRef = useRef(null);
  const modalSliderRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBounds, setImageBounds] = useState({ top: 0, left: 0 });
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleImageClick = (index) => {
    setIndexImg(index);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(indexImg);
    }
  }, [indexImg]);

  const settings = {
    //dots: isMobile,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    swipe: true,
  };
  const settingsBig = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipe: true,
    afterChange: (current) => setCurrentSlide(current),
    initialSlide: currentSlide,
  };

  const handleMainImageClick = (index, bounds) => {
    if (!isMobile) {
      return;
    }

    setIsModalOpen(true);
    setSelectedImage(images[index]);
    setCurrentSlide(index);
    setIndexImg(index);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
    setImageBounds(bounds);
  };

  return (
    <div>
      {images.length > 1 ? (
        <Slider ref={sliderRef} {...settings}>
          {images.map((image, index) => (
            <ImageContainer
              key={index}
              onClick={(e) => {
                const bounds = e.target.getBoundingClientRect();
                handleMainImageClick(index, bounds);
              }}
            >
              <Image
                src={image.thumbnail} // Koristi 'thumbnail' verziju primarne slike
                alt="Product Image"
                srcSet={`${image.thumbnail} 320w, ${image.large} 480w, ${image.large} 800w`}
                sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
                loading="lazy"
              />
            </ImageContainer>
          ))}
        </Slider>
      ) : (
        images.length === 1 && (
          <ImageContainer
            onClick={(e) => {
              const bounds = e.target.getBoundingClientRect();
              handleMainImageClick(0, bounds);
            }}
          >
            <Image
              src={images[0].thumbnail} // Koristi 'thumbnail' verziju primarne slike
              alt="Product Image"
              srcSet={`${images[0].thumbnail} 320w, ${images[0].large} 480w, ${images[0].large} 800w`}
              sizes="(max-width: 320px) 300px, (max-width: 480px) 440px, 800px"
              loading="lazy"
            />
          </ImageContainer>
        )
      )}

      <AnimatePresence>
        {isModalOpen && (
          <FullScreenModal
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsModalOpen(false)}
          >
            <StyledSvgText>
              {currentSlide + 1}/{images.length}
            </StyledSvgText>
            <StyledSvgIcon
              focusable="false"
              role="presentation"
              viewBox="0 0 64 64"
              className="svg-icon"
              onClick={() => setIsModalOpen(false)}
            >
              <path d="M19 17.61l27.12 27.13m0-27.12L19 44.74"></path>
            </StyledSvgIcon>
            {images.length > 1 ? (
              <FullScreenSlider
                ref={modalSliderRef}
                {...settingsBig}
                onClick={(event) => event.stopPropagation()}
              >
                {images.map((image, index) => (
                  <ModalImage
                    onClick={(event) => event.stopPropagation()}
                    src={image.original}
                    key={index}
                    alt={image.original}
                    initial={{
                      y:
                        imageBounds.top -
                        window.scrollY / 2 -
                        window.innerHeight / 2 +
                        imageBounds.height / 2, // Centriranje slike
                      x: "50%", // tkada nema full screen slidera je visak ovaj deo
                      width: imageBounds.width / 2,
                      height: imageBounds.height / 2,
                      opacity: 1,
                    }}
                    animate={{
                      y: 0,
                      x: 0,
                      width: "100%",
                      height: "auto",
                      opacity: 1,
                      ease: "easeIn",
                    }}
                    exit={{
                      y:
                        imageBounds.top +
                        window.scrollY -
                        window.innerHeight / 2 +
                        imageBounds.height / 2,
                      x: "50%",
                      width: imageBounds.width,
                      height: imageBounds.height,
                      opacity: 1,
                    }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                  />
                ))}
              </FullScreenSlider>
            ) : (
              images.length === 1 && (
                <ModalImage
                  onClick={(event) => event.stopPropagation()}
                  src={images[0].original}
                  alt="Povećana slika"
                  initial={{
                    y:
                      imageBounds.top -
                      window.scrollY / 2 -
                      window.innerHeight / 2 +
                      imageBounds.height / 2, // Centriranje slike
                    x: "50%", // tkada nema full screen slidera je visak ovaj deo
                    width: imageBounds.width / 2,
                    height: imageBounds.height / 2,
                    opacity: 1,
                  }}
                  animate={{
                    y: 0,
                    x: 0,
                    width: "100%",
                    height: "auto",
                    opacity: 1,
                    ease: "easeIn",
                  }}
                  exit={{
                    y:
                      imageBounds.top +
                      window.scrollY -
                      window.innerHeight / 2 +
                      imageBounds.height / 2,
                    x: "50%",
                    width: imageBounds.width,
                    height: imageBounds.height,
                    opacity: 1,
                  }}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                />
              )
            )}
          </FullScreenModal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ImageSlider;
