import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { paymentMethods } from "../../utils/global_const";
import CreditCardsCheckout from "../../components/stripe/CreditCardsCheckout";
import { useState } from "react";
import CreditCardButton from "../stripe/CreditCardButton";

const stripePromise = loadStripe(
  "pk_test_51QI78aHyD45BjVAFFnBI17A25VDrrBQ94NJ23DJarIxnG5YPjwJGKiG2iKKIymERIFvJVxMUCjn3giAUHT7uIbtf00DsMkBivd"
);
const appearance = {
  theme: "stripe",
};

const Step3 = ({ onSubmit, address }) => {
  const [clientSecret, setClientSecret] = useState("");
  return (
    <>
      <CreditCardsCheckout
        address={address}
        setClientSecret={setClientSecret}
      />
      <CreditCardButton
        clientSecret={clientSecret}
        handleOrderSubmission={onSubmit}
      />
    </>
  );
};

export default Step3;
