import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductCardHorizontal from "./ProductCardHorizontal";
import SliderLoading from "../animations/SliderLoading";

const SlideWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: #f0f0f0;
  color: #000;
  font-size: 1.5rem;
  font-weight: bold;
`;

const ArrowButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: calc(100% + 20px);
  transform: translateY(-50%);
  color: ${(props) => (props.disabled ? "var(--bg-300)" : "var(--text-200)")};
`;

const NextArrow = ({ onClick, currentSlide, slideCount, slidesToShow }) => {
  const isDisabled = currentSlide >= slideCount - slidesToShow;
  return (
    <ArrowButton
      style={{ right: "10px" }}
      onClick={isDisabled ? null : onClick}
      disabled={isDisabled}
    >
      <svg
        width="40px"
        height="40px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 12H18M18 12L13 7M18 12L13 17"
          stroke={isDisabled ? "var(--bg-300)" : "var(--text-200)"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ArrowButton>
  );
};

const PrevArrow = ({ onClick, currentSlide }) => {
  const isDisabled = currentSlide === 0;
  return (
    <ArrowButton
      style={{ left: "10px" }}
      onClick={isDisabled ? null : onClick}
      disabled={isDisabled}
    >
      <svg
        width="40px"
        height="40px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 12H18M6 12L11 7M6 12L11 17"
          stroke={isDisabled ? "var(--bg-300)" : "var(--text-200)"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ArrowButton>
  );
};

const StyledDots = styled.ul`
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  list-style: none;

  li {
    width: 16px;
    height: 16px;
    margin: 0 5px;
    background-color: #565656;
    border-radius: 50%;
    cursor: pointer;

    &.slick-active {
      background-color: var(--accent-100);
    }
  }
`;

const ProductSliderHorizontal = ({ products }) => {
  const sliderRef = useRef(null);
  const [slidesToShow, setSlidesToShow] = useState(1); // Default for mobile
  const [slidesToScroll, setSlidesToScroll] = useState(1); // Default for mobile
  const [productsToShow, setProductsToShow] = useState(6);

  // Function to update slider settings based on screen width
  const updateSliderSettings = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1024) {
      setSlidesToShow(3);
      setSlidesToScroll(3);
      setProductsToShow(12);
    } else if (screenWidth >= 768) {
      setSlidesToShow(2);
      setSlidesToScroll(2);
      setProductsToShow(8); // Možete prilagoditi broj proizvoda prema potrebi
    } else {
      setSlidesToShow(1);
      setSlidesToScroll(1);
      setProductsToShow(6);
    }
  };

  useEffect(() => {
    // Update slider settings on initial load
    updateSliderSettings();

    // Update settings when window resizes
    window.addEventListener("resize", updateSliderSettings);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSliderSettings);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    nextArrow: <NextArrow slidesToShow={slidesToShow} />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => <StyledDots>{dots}</StyledDots>, // Custom styled dots
  };

  return (
    <div style={{ margin: "0 0 40px 0" }}>
      {products && products.length ? (
        <Slider {...settings} ref={sliderRef}>
          {products.slice(0, productsToShow).map((product, index) => (
            <ProductCardHorizontal key={index} product={product} />
          ))}
        </Slider>
      ) : (
        <SliderLoading />
      )}
    </div>
  );
};

export default ProductSliderHorizontal;
