import React, { useContext, useEffect, useState } from "react";
import { ProductContext } from "../../context/ProductContext";
import styled from "styled-components";
import ToggleSwitch from "../animations/ToggleSwitch";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  position: fixed;
  height: 100vh;
  max-height: 100vh;
  max-height: 100%;
  overflow: hidden;
  z-index: 99999;
  right: 0px;
  bottom: 0px;
  width: ${(props) => (props.$showFilters ? "100%" : "0%")};

  @media (min-width: 768px) {
    position: absolute;
    box-shadow: var(--shadow-large);
  }

  background-color: var(--bg-100); /* Promenite boju po želji */
  transition: width 0.3s ease;
`;
const Wrapper = styled.div`
  width: 94%;
  margin: 0 auto;
`;
const IconTitle = styled.span`
  border: none;

  font-size: var(--font-size-base);
  font-weight: 100;
  text-transform: capitalize;
  color: var(--text-100);
  display: flex;
  gap: 14px;
  cursor: pointer;

  background-color: var(--bg-200);
  border: 1px solid var(--bg-300);
  padding: var(--spacing-xs) var(--spacing-md);
  border: ${({ $totalfilters }) =>
    $totalfilters > 0
      ? "1px solid var(--primary-100)"
      : "1px solid var(--bg-300)"};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MenuTitle = styled(IconTitle)`
  border: none;
  background-color: transparent;
  text-transform: uppercase;
`;
const XDiv = styled.div`
  padding: 0 0 0 var(--spacing-md);
  cursor: pointer;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--bg-200);

  /* Prvi element centriran */
  & > :first-child {
    margin-right: auto;
  }

  /* Drugi element na kraju */
  & > :last-child {
    margin-right: auto;
  }
`;
const Checkbox = styled.div`
  border-radius: 4px;
  border: 1px solid var(--bg-300);
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &:hover {
    border: 1px solid var(--text-100);
  }
`;
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 12px;
`;
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
`;
const Label = styled.label`
  font-size: var(--font-size-base);
  font-weight: 100;
  color: #000;
  font-family: "Gudea-Regural";
  cursor: pointer;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-base);
`;

const MidContainer = styled.div`
  padding: 20px 0;
  width: 94%;
  margin: 0 auto;

  max-height: calc(100vh - var(--navbar-height) * 3 - 30px);
  @media (min-width: 768px) {
    max-height: 80vh;
  }
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--text-200);
    border-radius: 5px;
  }
`;

const CustomFilter = styled.div`
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid var(--bg-300);
  border-bottom: 1px solid var(--bg-300);
`;

const CustomWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: var(--text-100);
  padding: 14px 0;
`;

const FilterContainer = styled.div`
  padding: 20px 0;
  width: 94%;
  margin: 0 auto;
`;

const FilterSection = ({ showFilters, setShowFilters, toggleMenu }) => {
  const { t } = useTranslation();
  const { products, setFilteredProducts, filteredProducts } =
    useContext(ProductContext);

  // Stanje za selektovane kategorije i formate
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [selectedFormats, setSelectedFormats] = useState(new Set());
  const [selectedNicotineRanges, setSelectedNicotineRanges] = useState(
    new Set()
  );
  const [filterOutOfStock, setFilterOutOfStock] = useState(true);

  // Grupisanje proizvoda po kategoriji i formatu
  const groupedCategories = Array.from(
    new Set(products.map((product) => product.category_name))
  );
  const groupedFormats = Array.from(
    new Set(products.map((product) => product.format))
  );

  const nicotineRanges = [
    { label: "0-5 mg", range: [0, 5] },
    { label: "5-9 mg", range: [5, 9] },
    { label: "9-12 mg", range: [9, 12] },
    { label: "12-15 mg", range: [12, 15] },
    { label: "15-17 mg", range: [15, 18] },
  ];

  // Funkcija za promenu selektovanih kategorija
  const handleCategoryChange = (category) => {
    const updatedCategories = new Set(selectedCategories);
    if (updatedCategories.has(category)) {
      updatedCategories.delete(category);
    } else {
      updatedCategories.add(category);
    }

    setSelectedCategories(updatedCategories);
    applyFilters(updatedCategories, selectedFormats, nicotineRanges);
  };

  // Funkcija za promenu selektovanih formata
  const handleFormatChange = (format) => {
    const updatedFormats = new Set(selectedFormats);
    if (updatedFormats.has(format)) {
      updatedFormats.delete(format);
    } else {
      updatedFormats.add(format);
    }

    setSelectedFormats(updatedFormats);
    applyFilters(selectedCategories, updatedFormats, nicotineRanges);
  };

  const handleNicotineRangeChange = (range) => {
    const updatedRanges = new Set(selectedNicotineRanges);

    // Dodajemo samo labelu opsega, a ne ceo objekat
    if (updatedRanges.has(range.label)) {
      updatedRanges.delete(range.label); // Ako je selektovan, uklonimo ga
    } else {
      updatedRanges.add(range.label); // Ako nije selektovan, dodajemo samo labelu
    }

    console.log(updatedRanges);
    setSelectedNicotineRanges(updatedRanges);
    applyFilters(selectedCategories, selectedFormats, updatedRanges); // Primeni filtere
  };

  const handleFilterOutOfStock = (newState) => {
    setFilterOutOfStock(newState);
  };
  useEffect(() => {
    applyFilters(selectedCategories, selectedFormats, selectedNicotineRanges);
  }, [filterOutOfStock]);

  // Funkcija za primenu svih filtera zajedno
  const applyFilters = (categories, formats, nicotineRange) => {
    const nicotineRangesArray = Array.from(nicotineRange); // Pretvaranje Set-a u niz

    const filtered = products.filter((product) => {
      const isInCategory =
        categories.size === 0 || categories.has(product.category_name);
      const isInFormat = formats.size === 0 || formats.has(product.format);
      const isInNicotineRange =
        nicotineRange.size === 0 ||
        nicotineRangesArray.some((label) => {
          // Traženje odgovarajućeg range objekta u nicotineRanges
          const range = nicotineRanges.find((range) => range.label === label);
          const [min, max] = range.range; // Uzmi minimum i maksimum iz opsega
          return product.nicotine >= min && product.nicotine <= max; // Proveri da li proizvod spada u opseg
        });
      console.log("filter", filterOutOfStock);
      const isInStock = !filterOutOfStock || product.is_in_stock === true;

      return isInCategory && isInFormat && isInNicotineRange && isInStock;
    });

    setFilteredProducts(filtered);
  };

  const clearFilters = () => {
    // Resetujemo sve filtere na početne vrednosti
    setFilterOutOfStock(false);
    setSelectedCategories(new Set());
    setSelectedFormats(new Set());
    setSelectedNicotineRanges(new Set());

    applyFilters(new Set(), new Set(), new Set());
  };

  // Funkcija za brojanje proizvoda u određenoj kategoriji
  const countProductsInCategory = (category) => {
    return products.filter((product) => product.category_name === category)
      .length;
  };

  // Funkcija za brojanje proizvoda u određenom formatu
  const countProductsInFormat = (format) => {
    return products.filter((product) => product.format === format).length;
  };

  const countProductsInNicotineRange = (label) => {
    const range = nicotineRanges.find((range) => range.label === label);
    return products.filter((product) => {
      const [min, max] = range.range; // Uzmi minimum i maksimum iz opsega
      return product.nicotine >= min && product.nicotine <= max; // Proveri da li proizvod spada u opseg
    }).length;
  };

  return (
    <>
      <Container $showFilters={showFilters}>
        <Wrapper>
          <XDiv>
            <FlexDiv onClick={() => setShowFilters(false)}>
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
              >
                <path
                  d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z"
                  fill="var(--text-100)"
                />
              </svg>
              Back
            </FlexDiv>
            <MenuTitle>{showFilters}</MenuTitle>
          </XDiv>
          <MidContainer>
            {/* Prikaz sekcije u zavisnosti od selekcije */}
            {showFilters === "brand" && (
              <div>
                {groupedCategories.map((category) => (
                  <CheckboxContainer
                    key={category}
                    onClick={() => handleCategoryChange(category)}
                  >
                    <CheckboxWrapper>
                      <Checkbox>
                        {selectedCategories.has(category) && (
                          <svg
                            fill="var(--bg-100)"
                            width="20px"
                            height="20px"
                            viewBox="0 0 1024 1024"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ backgroundColor: "var(--success-color)" }}
                          >
                            <path d="M760 380.4l-61.6-61.6-263.2 263.1-109.6-109.5L264 534l171.2 171.2L760 380.4z" />
                          </svg>
                        )}
                      </Checkbox>
                      <Label htmlFor={category}>{category}</Label>
                    </CheckboxWrapper>
                    <div>{countProductsInCategory(category)}</div>
                  </CheckboxContainer>
                ))}
              </div>
            )}

            {showFilters === "format" && (
              <div>
                {groupedFormats.map((format) => (
                  <CheckboxContainer
                    key={format}
                    onClick={() => handleFormatChange(format)}
                  >
                    <CheckboxWrapper>
                      <Checkbox>
                        {selectedFormats.has(format) && (
                          <svg
                            fill="var(--bg-100)"
                            width="20px"
                            height="20px"
                            viewBox="0 0 1024 1024"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ backgroundColor: "var(--success-color)" }}
                          >
                            <path d="M760 380.4l-61.6-61.6-263.2 263.1-109.6-109.5L264 534l171.2 171.2L760 380.4z" />
                          </svg>
                        )}
                      </Checkbox>
                      <Label htmlFor={format}>{format}</Label>
                    </CheckboxWrapper>
                    <div>{countProductsInFormat(format)}</div>
                  </CheckboxContainer>
                ))}
              </div>
            )}

            {showFilters === "nicotine" && (
              <div>
                {nicotineRanges.map((range) => (
                  <CheckboxContainer
                    key={range.label} // Koristimo range.label kao jedinstveni ključ
                    onClick={() => handleNicotineRangeChange(range)} // Prosljeđujemo ceo range
                  >
                    <CheckboxWrapper>
                      <Checkbox>
                        {selectedNicotineRanges.has(range.label) && (
                          <svg
                            fill="var(--bg-100)"
                            width="20px"
                            height="20px"
                            viewBox="0 0 1024 1024"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ backgroundColor: "var(--success-color)" }}
                          >
                            <path d="M760 380.4l-61.6-61.6-263.2 263.1-109.6-109.5L264 534l171.2 171.2L760 380.4z" />
                          </svg>
                        )}
                      </Checkbox>
                      <Label htmlFor={range.label}>{range.label}</Label>{" "}
                    </CheckboxWrapper>
                    <div>{countProductsInNicotineRange(range.label)}</div>
                  </CheckboxContainer>
                ))}
              </div>
            )}
          </MidContainer>
        </Wrapper>
      </Container>
      <CustomFilter>
        <FilterContainer>
          <CustomWrapper>
            {t("FILTER.HIDE_OUT_OF_STOCK")}
            <ToggleSwitch onChange={handleFilterOutOfStock} />
          </CustomWrapper>
        </FilterContainer>
      </CustomFilter>

      <BottomDiv>
        <BottomWrapper>
          <TransparentButton onClick={clearFilters}>
            {t("FILTER.CLEAR_FILTER")}
          </TransparentButton>
          <button onClick={toggleMenu}>
            {t("FILTER.VIEW_ALL_PRODUCTS", {
              filteredProducts: filteredProducts.length,
            })}
          </button>
        </BottomWrapper>
      </BottomDiv>
    </>
  );
};

export default FilterSection;

const BottomDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(var(--navbar-mini) * 2);
  background-color: var(--bg-100);
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--bg-300);
  z-index: 99999;
`;
const BottomWrapper = styled.div`
  width: 90%;
  height: 60%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 14px;
`;
const TransparentButton = styled.button`
  background-color: transparent;
  color: var(--text-100);
  border-color: var(--bg-300);
  &:hover {
    background-color: transparent;
    border-color: var(--text-100);
  }
`;
