import { useContext, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ProductContext } from "../context/ProductContext";

import { Helmet } from "react-helmet-async";
import FeaturedHomeMain from "../layouts/main/FeatureHomeMain";

const Container = styled.article`
  color: ${(props) => props.theme.textColor};
`;
const Section = styled.section`
  height: 100vh;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const FeaturedHome = () => {
  const { t, i18n } = useTranslation();
  const { loadProducts } = useContext(ProductContext);

  useEffect(() => {
    loadProducts();
  }, [i18n.language]);

  const seoData = {
    en: {
      title: "Explore Top Nicotine Pouch Brands – Featured Selection | SnusCo",
      description:
        "Browse our curated selection of top nicotine pouch brands with diverse flavors and strengths. Enjoy premium quality nicotine pouches and fast delivery with SnusCo!",
      keywords:
        "nicotine pouches, snus brands, featured nicotine pouches, best snus brands, top nicotine products, nicotine pouches shop, Austria, Germany, SnusCo",
      ogTitle:
        "Explore Top Nicotine Pouch Brands – Featured Selection | SnusCo",
      ogDescription:
        "Discover the best nicotine pouch brands in our featured collection. Choose from various flavors and strengths with fast delivery across Austria and Germany with SnusCo!",
      twitterTitle:
        "Explore Top Nicotine Pouch Brands – Featured Selection | SnusCo",
      twitterDescription:
        "Find premium nicotine pouch brands in our exclusive featured section. Fast delivery in Austria and Germany guaranteed with SnusCo!",
      url: "https://www.snusco.com/en/featured",
      image: "https://www.snusco.com/assets/featured-brands.jpg",
      locale: "en",
    },
    de: {
      title:
        "Entdecken Sie Top-Marken für Nikotinsäcke – Unsere Highlights | SnusCo",
      description:
        "Durchstöbern Sie unsere handverlesene Auswahl an Top-Marken für Nikotinsäcke mit vielfältigen Aromen und Stärken. Premium-Qualität Nikotinsäcke und schnelle Lieferung erwarten Sie bei SnusCo!",
      keywords:
        "Nikotinsäcke, Snus-Marken, vorgestellte Nikotinbeutel, beste Snus-Marken, Top-Nikotinprodukte, Nikotinbeutel Shop, Österreich, Deutschland, SnusCo",
      ogTitle:
        "Entdecken Sie Top-Marken für Nikotinsäcke – Unsere Highlights | SnusCo",
      ogDescription:
        "Entdecken Sie die besten Marken für Nikotinsäcke in unserer vorgestellten Kollektion. Wählen Sie aus verschiedenen Aromen und Stärken mit schneller Lieferung in Österreich und Deutschland – alles bei SnusCo!",
      twitterTitle:
        "Entdecken Sie Top-Marken für Nikotinsäcke – Unsere Highlights | SnusCo",
      twitterDescription:
        "Finden Sie Premium-Marken für Nikotinsäcke in unserer exklusiven vorgestellten Rubrik. Schnelle Lieferung in Österreich und Deutschland garantiert mit SnusCo!",
      url: "https://www.snusco.com/de/featured",
      image: "https://www.snusco.com/assets/featured-brands.jpg",
      locale: "de",
    },
  };

  const currentSeo = seoData[i18n.language] || seoData.en;

  return (
    <>
      <Helmet>
        {/* Meta Title */}
        <title>{currentSeo.title}</title>

        {/* Meta Description */}
        <meta name="description" content={currentSeo.description} />

        {/* Meta Keywords */}
        <meta name="keywords" content={currentSeo.keywords} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={currentSeo.ogTitle} />
        <meta property="og:description" content={currentSeo.ogDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentSeo.url} />
        <meta property="og:image" content={currentSeo.image} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={currentSeo.twitterTitle} />
        <meta
          name="twitter:description"
          content={currentSeo.twitterDescription}
        />
        <meta name="twitter:image" content={currentSeo.image} />

        {/* Canonical Link */}
        <link rel="canonical" href={currentSeo.url} />

        {/* Additional Meta Tags */}
        <meta property="og:locale" content={currentSeo.locale} />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <FeaturedHomeMain />
    </>
  );
};

export default FeaturedHome;
