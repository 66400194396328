import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Footer from "../../layouts/footer/Footer";
import PRODUCT_CHUNK, { DEFAULT_LANGUAGE } from "../../utils/global_const";
import { ProductContext } from "../../context/ProductContext";
import Header from "../../layouts/header/Header";
import NewArrivalsMain from "../../layouts/main/product/NewArrivalsMain";

const SEOConfig = {
  en: {
    title: "New Arrivals – Fresh Nicotine Pouches | SnusCo",
    description:
      "Check out the latest nicotine pouches with fresh flavors and varying nicotine strengths. Shop new arrivals now and enjoy fast delivery with SnusCo!",
    keywords:
      "new arrivals, nicotine pouches, fresh nicotine pouches, new snus, nicotine pouches shop, SnusCo, nicotine products, Austria, Germany",
    ogTitle: "New Arrivals – Fresh Nicotine Pouches | SnusCo",
    ogDescription:
      "Discover the newest nicotine pouches with unique flavors and strengths. Fast delivery available in Austria, Germany, and beyond with SnusCo!",
    twitterTitle: "New Arrivals – Fresh Nicotine Pouches | SnusCo",
    twitterDescription:
      "Browse the latest nicotine pouches at SnusCo. Fresh flavors and quick delivery in Austria and Germany!",
    url: "https://www.snusco.com/en/new-arrivals",
    image: "https://www.snusco.com/assets/new-arrivals.jpg",
    locale: "en",
  },
  de: {
    title: "Neuheiten – Frische Nikotinsäcke | SnusCo",
    description:
      "Entdecken Sie die neuesten Nikotinsäcke mit frischen Aromen und unterschiedlichen Nikotinstärken. Bestellen Sie jetzt die Neuheiten bei SnusCo und genießen Sie schnelle Lieferung!",
    keywords:
      "neuheiten, Nikotinsäcke, frische Nikotinsäcke, neuer Snus, Nikotinsäcke Shop, SnusCo, Nikotinprodukte, Österreich, Deutschland",
    ogTitle: "Neuheiten – Frische Nikotinsäcke | SnusCo",
    ogDescription:
      "Entdecken Sie die neuesten Nikotinsäcke mit einzigartigen Aromen und Stärken. Schnelle Lieferung in Österreich, Deutschland und weltweit mit SnusCo!",
    twitterTitle: "Neuheiten – Frische Nikotinsäcke | SnusCo",
    twitterDescription:
      "Durchstöbern Sie die neuesten Nikotinsäcke bei SnusCo. Frische Aromen und schnelle Lieferung in Österreich und Deutschland!",
    url: "https://www.snusco.com/de/new-arrivals",
    image: "https://www.snusco.com/assets/new-arrivals.jpg",
    locale: "de",
  },
};

const NewArrivals = () => {
  const { t, i18n } = useTranslation();
  const { loadNewArrivalsBackend, products } = useContext(ProductContext);

  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    if (!initialLoad) {
      // Proverava da li je ovo prvi put da se učitava
      loadNewArrivalsBackend();
      setInitialLoad(true); // Postavlja da je inicijalno učitavanje završeno
    }
  }, [initialLoad]);

  const [seo, setSeo] = useState(SEOConfig[DEFAULT_LANGUAGE]);

  useEffect(() => {
    setSeo(SEOConfig[i18n.language] || SEOConfig[DEFAULT_LANGUAGE]);
  }, [i18n.language]);

  return (
    <div>
      <Helmet>
        <html lang={i18n.language} />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />

        {/* Open Graph slike - Primarna slika na prvom mestu */}
        {seo.images?.map((image, index) => (
          <meta key={index} property="og:image" content={image} />
        ))}

        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="SnusCo" />
        <link rel="canonical" href={seo.url} />
      </Helmet>
      <Header />
      <NewArrivalsMain />
      <Footer />
    </div>
  );
};

export default NewArrivals;
