import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ProductContext } from "../../../context/ProductContext";
import { useTranslation } from "react-i18next";
import ProductCard from "../../../components/product/ProductCard";
import ShopSort from "../../../components/product/ShopSort";
import Breadcrumbs from "../../../components/section/BreadCrumbs";

const Container = styled.article`
  color: ${(props) => props.theme.textColor};
`;
const Section = styled.section`
  width: 100%;
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
`;
const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }

  @media (min-width: 1025px) {
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
  }
`;
const FlexDiv = styled.div`
  height: var(--navbar-height);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: var(--shadow-large);

  @media (min-width: 768px) {
    //max-width: 90%;
    box-shadow: none;
    flex-direction: row;
    position: static;
    margin-bottom: 20px;
  }
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
`;
const MarginDiv = styled.div`
  width: 96%;
  margin: 0 auto;
`;
const TopWrapper = styled.div`
  width: 50%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px;
  height: 36px;

  @media (min-width: 768px) {
    width: 200px;
    margin-left: auto;
  }
`;
const FlexContainer = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const FilterContainer = styled.div`
  width: 100%;
`;

const Title = styled.h1`
  font-size: var(--font-size-xxlarge);
`;
const NewArrivalsMain = () => {
  const { t } = useTranslation();
  const { newArrivals, setProducts, products } = useContext(ProductContext);

  const breadcrumbItems = [
    { name: "SnusCo", url: "/" },
    { name: "New Arrivals", url: "/" },
  ];

  useEffect(() => {
    setProducts(newArrivals);
  }, [newArrivals]);

  return (
    <Container>
      <Section className="header-top-margin">
        <MarginDiv>
          <Breadcrumbs breadcrumbs={breadcrumbItems} />
          <Title>{t("DESCRIPTION.NEW_ARRIVALS.TITLE")}</Title>
          <p>{t("DESCRIPTION.NEW_ARRIVALS.TEXT")}</p>
        </MarginDiv>

        <FlexDiv>
          <TopWrapper>
            <FilterContainer>
              <ShopSort />
            </FilterContainer>
          </TopWrapper>
        </FlexDiv>
        <ProductGrid>
          {products.map((product, index) => (
            <ProductCard key={index} product={product} />
          ))}
        </ProductGrid>
      </Section>
    </Container>
  );
};

export default NewArrivalsMain;
