export const nonWorkingDays = ["01-01", "12-25"]; // New Year's Day, Christmas

export const workingHoursCutoff = 16; // 4 PM cutoff

// Helper to check if a given date is a weekend
export const isWeekend = (date) => {
  const day = date.getDay();
  return day === 0 || day === 6; // Sunday or Saturday
};

// Helper to check if a given date is a non-working day
export const isNonWorkingDay = (date) => {
  const monthDay = date.toISOString().slice(5, 10); // Extract MM-DD format
  return nonWorkingDays.includes(monthDay);
};

// Calculate the delivery date
export const calculateDeliveryDate = (days) => {
  const today = new Date();
  const now = new Date();

  // Adjust for cutoff time
  if (now.getHours() >= workingHoursCutoff) {
    today.setDate(today.getDate() + 1); // Start from the next day
  }

  let deliveryDate = new Date(today);

  while (days > 0) {
    deliveryDate.setDate(deliveryDate.getDate() + 1);

    // Only decrement days if it's a working day
    if (!isWeekend(deliveryDate) && !isNonWorkingDay(deliveryDate)) {
      days--;
    }
  }

  return deliveryDate.toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
};
