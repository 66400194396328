import React, { useContext, useState } from "react";
import Step1Exists from "./Step1Exists";
import Step2 from "./Step2";
import Step3 from "./Step3";
import ProgressBar from "./ProgressBar";
import styled from "styled-components";
import Invoice from "./Invoice";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { paymentMethods } from "../../utils/global_const";
import CreditCardsCheckout from "../../components/stripe/CreditCardsCheckout";
import { AuthUserContext } from "../../context/AuthUserContext";
import { jwtDecode } from "jwt-decode";

import Login from "../popup/Login";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProductContext } from "../../context/ProductContext";
import { useNavigation } from "../../utils/navigation";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(
  "pk_test_51QI78aHyD45BjVAFFnBI17A25VDrrBQ94NJ23DJarIxnG5YPjwJGKiG2iKKIymERIFvJVxMUCjn3giAUHT7uIbtf00DsMkBivd"
);
const appearance = {
  theme: "stripe",
};

const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: var(--bg-100);
  padding-top: calc(var(--navbar-height));
  @media (min-width: 1040px) {
    min-height: 90vh;
    padding-top: calc(var(--navbar-height-desktop));
  }
`;
const Wrapper = styled.div`
  width: 94%;

  @media (min-width: 1040px) {
    padding: 0;
    width: 100%;
    display: flex;
    position: relative; /* Ovo je potrebno za relativno pozicioniranje unutar roditelja */
    justify-content: space-between;
    width: var(--max-width-container);
  }
`;
const RightContainer = styled.div`
  @media (max-width: 768px) {
    margin-bottom: 120px;
  }
  @media (min-width: 1040px) {
    height: 60vh;
    max-height: 90vh;
    width: 460px;
    position: sticky;
  }
  @media (min-width: 768px) and (max-width: 1040px) {
    width: 100%;
  }
  @media (min-width: 1025px) {
    left: calc(
      (100vw - var(--max-width-container)) / 2 + var(--max-width-container) -
        700px
    );
    top: calc(var(--navbar-height-desktop));
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  @media (min-width: 1040px) {
    width: calc(100% - 530px);
  }
`;
const CheckoutMain = () => {
  const { t } = useTranslation();
  const { authTokens, userProfile } = useContext(AuthUserContext);
  const { createOrder, setCreatedOrder } = useContext(ProductContext);
  const [currentStep, setCurrentStep] = useState(1);

  const [formData, setFormData] = useState({
    addressId: {},
    shippingInfo: {},
    paymentInfo: {},
  });

  const handleNext = (data) => {
    if (currentStep === 1) setFormData({ ...formData, address: data });
    if (currentStep === 2) setFormData({ ...formData, shippingInfo: data });
    setCurrentStep(currentStep + 1);
  };

  const goToStep = (step) => {
    if (step < currentStep) {
      setCurrentStep(step);
    }
  };

  const navigate = useNavigate();
  const { goToThankYou } = useNavigation();
  const cartItems = useSelector((state) => state.cart.itemsList);

  const handleSubmit = async () => {
    if (!userProfile) {
      return;
    }

    const orderData = {
      address: formData.address.id,
      order_items: cartItems.map((item) => ({
        product: item.product.id,
        quantity: item.quantity,
      })),
      payment_method: "Credit Card",
      transport_method: formData.shippingInfo.name,
      note: "",
    };
    try {
      // Poziv funkcije za kreiranje narudžbine (pretpostavljamo da funkcija `createAddressBook` postoji)
      const createdOrderCopy = await createOrder(orderData, authTokens.access);
      console.log("Order created successfully", createdOrderCopy);
      setCreatedOrder(createdOrderCopy);

      navigate(goToThankYou());
    } catch (error) {
      console.error("Greška prilikom kreiranja narudžbine:", error); // Obradite grešku
    }
  };

  return (
    <Elements options={{ appearance }} stripe={stripePromise}>
      <Container>
        <h1>{t("CHECKOUT.TITLE")} </h1>
        <ProgressBar
          currentStep={currentStep}
          totalSteps={3}
          goToStep={goToStep}
        />
        <Wrapper>
          <LeftContainer>
            {currentStep === 1 && (
              <Step1Exists
                onNext={handleNext}
                savedData={formData.personalInfo}
              />
            )}
            {currentStep === 2 && (
              <Step2 onNext={handleNext} address={formData.address} />
            )}
            {currentStep === 3 && (
              <Step3 onSubmit={handleSubmit} address={formData.address} />
            )}
          </LeftContainer>
          <RightContainer>
            <Invoice paymentMethod={formData.shippingInfo} />
          </RightContainer>
        </Wrapper>
      </Container>
    </Elements>
  );
};

export default CheckoutMain;
