import React, { useContext } from "react";
import styled from "styled-components";
import { ProductContext } from "../context/ProductContext";
import Header from "../layouts/header/Header";
import { useNavigation } from "../utils/navigation";
import { useNavigate } from "react-router-dom";
import Footer from "../layouts/footer/Footer";
import { useTranslation } from "react-i18next";

const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  background-color: var(--bg-100);
  min-height: 100vh;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: var(--text-100);
  margin-bottom: 10px;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: var(--text-200);
  margin-bottom: 20px;
`;

const OrderDetails = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: var(--bg-200);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  margin-top: 20px;
  padding: 20px 0;
  @media (min-width: 1040px) {
    padding: 34px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const OrderItem = styled.div`
  width: 94%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--bg-100);
  padding: 10px 0;

  &:last-child {
    border-bottom: none;
  }
`;

const OrderInfo = styled.div`
  font-size: 1rem;
  color: var(--text-200);
`;

const BoldText = styled.span`
  font-weight: bold;
`;
const Button = styled.button`
  width: 100%;
  margin: 14px 0;
`;

const ThankYouPage = () => {
  const { t } = useTranslation();
  const { createdOrder } = useContext(ProductContext);
  const { goToHome } = useNavigation();
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <ThankYouContainer>
        <OrderDetails>
          <Title>{t("THANK_YOU.TITLE")}</Title>
          <Subtitle>{t("THANK_YOU.SUBTITLE")}</Subtitle>
          <h3>{t("THANK_YOU.ORDER_DETAILS")}</h3>

          <OrderItem>
            <OrderInfo>
              <BoldText>{t("THANK_YOU.PAYMENT_METHOD")}</BoldText>
            </OrderInfo>
            <OrderInfo>{createdOrder.payment_method}</OrderInfo>
          </OrderItem>
          <OrderItem>
            <OrderInfo>
              <BoldText>{t("THANK_YOU.TRANSPORT_METHOD")}</BoldText>
            </OrderInfo>
            <OrderInfo>{createdOrder.transport_method}</OrderInfo>
          </OrderItem>
          <Button onClick={() => navigate(goToHome())}>
            {t("THANK_YOU.BUTTON")}
          </Button>
        </OrderDetails>
      </ThankYouContainer>
      <Footer />
    </>
  );
};

export default ThankYouPage;
