import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import { useNavigation } from "../../utils/navigation";

const fadeInTop = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    
  }
`;
const fadeOutBottom = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;
const DropDown = styled.div`
  /* Samo za telefone, primenjuje se display na osnovu $isOpen */
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          display: none; /* Sakrij ako je otvoreno */
        `
      : css`
          display: flex; /* Prikaži ako nije otvoreno */
          align-items: center;
          justify-content: center;
          background-color: var(--bg-100);
        `}
`;
const GridContainer = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;

  overflow-x: hidden;
  position: relative;
  background-color: red;
`;
const GridItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
`;
const Box = styled.div`
  width: 100%;
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${fadeOutBottom} 0.2s ease-in-out both;
        `
      : css`
          animation: ${fadeInTop} 0.3s ease-in-out both;
        `}
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const ButtonWrapper = styled.div`
  box-shadow: var(--shadow-small);
  border-bottom: 2px solid var(--bg-200);
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--bg-100);
`;
const Button = styled.button`
  text-transform: uppercase;
  font-weight: 600;

  color: var(--text-200);
  border: none;
  border-radius: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
  font-weight: 200;
  padding: 12px 0;
  background-color: var(--bg-100);
  &:hover {
    background-color: var(--bg-100);
  }
  padding: var(--spacing-md) 0;
  font-size: var(--font-size-large);
  text-transform: uppercase;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: "Gudea-Regural";
  margin: 0 0 var(--spacing-xl) 0;
`;

const TableCell = styled.td`
  padding: var(--spacing-md);
  border: 1px solid #ddd;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (min-width: 1025px) {
    grid-template-columns: 1fr 2fr;
  }
`;
const TableTitle = styled.div`
  color: var(--p-color);
  text-transform: uppercase;
`;
const TableText = styled.div`
  font-family: "Oswald-Medium";
  font-size: var(--font-size-large);
`;
const TableRow = styled.tr`
  background-color: var(--bg-200);
  &:nth-child(even) {
    background-color: var(--bg-100);
  }
`;
const CustomLink = styled(Link)`
  font-size: var(--font-size-large);
`;
const SectionContainer = styled.div`
  width: 100%;
  background-color: var(--bg-200);
  //padding: 14px;
  border-radius: 6px;
  padding: var(--spacing-md);

  @media (min-width: 768px) {
    display: flex;
    position: relative; /* Ovo je potrebno za relativno pozicioniranje unutar roditelja */
    justify-content: flex-end;
  }
`;
const ProductDetails = ({ product }) => {
  const { t, i18n } = useTranslation();
  const { goToCategory, goToShop } = useNavigation();
  const [isOpenBox, setIsOpenBox] = useState([false, true, true]);
  const [isOpenContainer, setIsOpenContainer] = useState([false, true, true]);
  const [isBoxAnimating, setIsBoxAnimating] = useState([false, false, false]);

  const toggleBox = (index) => {
    if (isBoxAnimating[index]) {
      return;
    }

    const updatedIsOpenBox = [...isOpenBox];
    updatedIsOpenBox[index] = !updatedIsOpenBox[index];
    setIsOpenBox(updatedIsOpenBox);

    const updatedIsBoxAnimating = [...isBoxAnimating];
    updatedIsBoxAnimating[index] = true;
    setIsBoxAnimating(updatedIsBoxAnimating);

    setTimeout(
      () => {
        const updatedIsOpenContainer = [...isOpenContainer];
        updatedIsOpenContainer[index] = !updatedIsOpenContainer[index];
        setIsOpenContainer(updatedIsOpenContainer);

        updatedIsBoxAnimating[index] = false;
        setIsBoxAnimating(updatedIsBoxAnimating);
      },
      !isOpenContainer[index] ? 200 : 10
    );
  };
  return (
    <div>
      <GridContainer>
        <GridItems>
          <ButtonWrapper onClick={() => toggleBox(0)}>
            <Button>
              {t("PRODUCT.DESCRIPTION")}
              {isOpenBox[0] ? (
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 6V18"
                    stroke="var(--text-200)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12L18 12"
                    stroke="var(--text-200)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </Button>
          </ButtonWrapper>
        </GridItems>
        <DropDown $isOpen={isOpenContainer[0]}>
          <Box $isOpen={isOpenBox[0]}>
            <FlexDiv>
              <SectionContainer>
                {product.seo_data?.description && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: product.seo_data.description,
                    }}
                  />
                )}
              </SectionContainer>
            </FlexDiv>
          </Box>
        </DropDown>
        <GridItems>
          <ButtonWrapper onClick={() => toggleBox(1)}>
            <Button>
              {t("PRODUCT.SPECIFICATION")}
              {isOpenBox[1] ? (
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 6V18"
                    stroke="var(--text-200)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12L18 12"
                    stroke="var(--text-200)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </Button>
          </ButtonWrapper>
        </GridItems>
        <DropDown $isOpen={isOpenContainer[1]}>
          <Box $isOpen={isOpenBox[1]}>
            {" "}
            {/* Prikažemo sadržaj sekcije */}
            <Table>
              <tbody>
                <TableRow>
                  <TableCell>
                    <TableTitle>{t("PRODUCT.BRAND")}</TableTitle>
                    <TableText>
                      <CustomLink to={goToCategory(product.category_name)}>
                        {product.category_name}
                      </CustomLink>
                    </TableText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TableTitle>{t("PRODUCT.FLAVOR")}</TableTitle>
                    <TableText>{product.flavor}</TableText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TableTitle>{t("PRODUCT.FORMAT")}</TableTitle>
                    <TableText>{product.format}</TableText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TableTitle>{t("PRODUCT.POUCHES_PER_CAN")}</TableTitle>
                    <TableText>{product.pouches_per_can}</TableText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TableTitle>{t("PRODUCT.NET_WEIGHT")}</TableTitle>
                    <TableText>{product.net_weight}g</TableText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TableTitle>{t("PRODUCT.NICOTINE")}</TableTitle>
                    <TableText>{product.nicotine}MG</TableText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TableTitle>{t("PRODUCT.MANUFACTURER")}</TableTitle>
                    <TableText>{product.manufacturer}</TableText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TableTitle>{t("PRODUCT.STOCK")}</TableTitle>
                    <TableText>
                      {product.state_display.replace("_", " ")}
                    </TableText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TableTitle>{t("PRODUCT.TYPE")}</TableTitle>
                    <TableText>
                      <CustomLink to={goToShop()}>Nicotine pouches</CustomLink>
                    </TableText>
                  </TableCell>
                </TableRow>
              </tbody>
            </Table>
          </Box>
        </DropDown>
        <GridItems>
          <ButtonWrapper onClick={() => toggleBox(2)}>
            <Button>
              {t("PRODUCT.TAXES")}
              {isOpenBox[2] ? (
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 6V18"
                    stroke="var(--text-200)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12L18 12"
                    stroke="var(--text-200)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </Button>
          </ButtonWrapper>
        </GridItems>
        <DropDown $isOpen={isOpenContainer[2]}>
          <Box $isOpen={isOpenBox[2]}>
            <FlexDiv>
              <SectionTaxesContainer>
                <SectionTitle>{t("PRODUCT.TAXES_TITLE")}</SectionTitle>
                <Paragraph>{t("PRODUCT.TAXES_DESCRIPTION")}</Paragraph>

                <SubTitle>{t("PRODUCT.TAXES_CALCULATION_TITLE")}</SubTitle>
                <Paragraph>
                  {t("PRODUCT.TAXES_CALCULATION_DESCRIPTION")}
                </Paragraph>
                <List>
                  <ListItem>{t("PRODUCT.TAXES_CALCULATION_METHOD_1")}</ListItem>
                  <ListItem>{t("PRODUCT.TAXES_CALCULATION_METHOD_2")}</ListItem>
                  <ListItem>{t("PRODUCT.TAXES_CALCULATION_METHOD_3")}</ListItem>
                </List>

                <SubTitle>{t("PRODUCT.VAT_CALCULATION_TITLE")}</SubTitle>
                <Paragraph>
                  {t("PRODUCT.VAT_CALCULATION_DESCRIPTION")}
                </Paragraph>

                <SubTitle>{t("PRODUCT.EXAMPLE_TITLE")}</SubTitle>
                <ExampleContainer>
                  <ExampleTitle>{t("PRODUCT.EXAMPLE_SUBTITLE")}</ExampleTitle>
                  <ExampleText>
                    {t("PRODUCT.EXAMPLE_TNET_RATE")}= <b>50%</b>
                  </ExampleText>
                  <ExampleText>
                    {t("PRODUCT.EXAMPLE_VAT_RATE")}= <b>20%</b>
                  </ExampleText>
                  <ExampleText>
                    {t("PRODUCT.EXAMPLE_WHOLESALE_COST")} = <b>€3,00</b>
                  </ExampleText>
                  <ExampleText>
                    {t("PRODUCT.EXAMPLE_SELLING_PRICE")} = <b>€4.50</b>
                  </ExampleText>
                  <ExampleText>
                    {t("PRODUCT.EXAMPLE_TNET_PER_CAN")} ={" "}
                    <b>€3,00 × 0,50 = €1,50</b>
                  </ExampleText>
                  <ExampleText>
                    {t("PRODUCT.EXAMPLE_VAT_PER_CAN")}={" "}
                    <b>(€4.50 + €1.50) × 0.20 = €1.20</b>
                  </ExampleText>
                  <ExampleText>
                    {t("PRODUCT.EXAMPLE_FINAL_PRICE")}
                    <b>€4,50 + €1,50 + €1,20 = €7,20</b>
                  </ExampleText>
                </ExampleContainer>
              </SectionTaxesContainer>
            </FlexDiv>
          </Box>
        </DropDown>
      </GridContainer>
    </div>
  );
};

export default ProductDetails;

const SectionTaxesContainer = styled.div`
  width: 100%;
  background-color: var(--bg-100);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
  color: var(--text-200);
`;
const SectionTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--text-100);
`;

const SubTitle = styled.h2`
  font-size: 20px;
  margin: 20px 0 10px;
  color: var(--text-200);
`;

const Paragraph = styled.p`
  margin-bottom: 16px;
`;

const List = styled.ul`
  margin-left: 20px;
  margin-bottom: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const ExampleContainer = styled.div`
  background-color: var(--bg-200);
  padding: 15px;
  border-left: 4px solid var(--accent-200);
  border-radius: 5px;
`;

const ExampleTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`;

const ExampleText = styled.p`
  margin-bottom: 6px;
`;
